import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, CanActivateChild} from '@angular/router';
import {UserService} from './user.service';
import {CmsNotificationsService} from './notifications.service';
import {CmsTranslateService} from '../shared/translate.service';
import {isEmpty} from 'lodash';

@Injectable()
export class ModuleAccessGuard implements CanActivate, CanActivateChild {
    constructor(
        private userService: UserService,
        private router: Router,
        private notificationService: CmsNotificationsService,
        private translateService: CmsTranslateService
    ) {}


    _guard(next: ActivatedRouteSnapshot, state) {
        if (this.userService.data && this.userService.data.modules) {
            if (!!(
                    this.userService.data.modules[next.data.module] &&
                    this.userService.data.modules[next.data.module].submodules[next.data.submodule] &&
                    (next.data.useEditPermission ?
                            this.userService.data.modules[next.data.module].submodules[next.data.submodule].edit :
                            this.userService.data.modules[next.data.module].submodules[next.data.submodule].view
                    )
                )) {
                return true;
            } else {
                // If user came refreshed page or came from login and it's not allowed to access route redirect him to dashboard,
                // and show notification, in other cases just show notification to prevent unneeded and disruptive redirect to dashboard
                if (['/', '/login'].indexOf(this.router.url) !== -1) {
                    this.router.navigate(['/dashboard']);
                }
                this.notificationService.show({
                    type: 'error',
                    content: this.translateService.translate('common_no_permission_to_access')
                });
                return false;
            }
        }

        return false;
    }

    canActivate (next: ActivatedRouteSnapshot, state) {
        return this._guard(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state) {
        return this._guard(next, state);
    }
}

import {
    Inject,
    Directive,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnDestroy,
    OnChanges
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({ selector: '[cmsClickOutside]' })
export class CmsClickOutsideDirective implements OnDestroy, OnChanges {

    @Input() attachOutsideOnClick = false;
    @Input() exclude = '';

    @Output()
    public cmsClickOutside = new EventEmitter<MouseEvent>();

    constructor(
        private el: ElementRef,
        @Inject(DOCUMENT) private _document /*: HTMLDocument*/,
    ) {
        this._initOnClickBody = this._initOnClickBody.bind(this);
        this._onClickBody = this._onClickBody.bind(this);
    }

    getExcludedElements() {
        const result = [];

        if (this.exclude) {
            this.exclude.split(',').forEach((selector) => {
                if (selector) {
                    try {
                        const node = this._document.querySelector(selector.trim());
                        if (node) {
                            result.push(node);
                        }
                    } catch (err) {
                        if (window.console) {
                            window.console.error('CMS: [cms-click-outside] Check your exclude selector syntax.', err);
                        }
                    }
                }
            });
        }

        return result;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['attachOutsideOnClick']) {
            if (changes['attachOutsideOnClick'].currentValue === true) {
                this._initOnClickBody();
            }
        }
    }

    ngOnDestroy() {
        this._document.body.removeEventListener('click', this._onClickBody);
    }

    private _initOnClickBody() {
        this._document.body.addEventListener('click', this._onClickBody);
    }

    private _onClickBody(e: MouseEvent) {
        if (!this.el.nativeElement.contains(e.target)
            && !this._shouldExclude(e.target)) {
            this.cmsClickOutside.emit(e);
            this._document.body.removeEventListener('click', this._onClickBody);
        }
    }

    private _shouldExclude(target): boolean {
        const excluded = this.getExcludedElements();

        for (let i = 0; i < excluded.length; i++) {
            if (excluded[i].contains(target)) {
                return true;
            }
        }

        return false;
    }
}

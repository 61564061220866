var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { CmsObjectHelper } from '../shared/object-helper.service';
export var MODULE_ID = 'lists';
export var LIST_ITEM_TYPE = {
    article: 'article',
    resource: 'resource'
};
var CmsListsService = /** @class */ (function () {
    function CmsListsService(http, config) {
        this.http = http;
        this.config = config;
    }
    CmsListsService.prototype.getList = function (id, params) {
        if (params === void 0) { params = {}; }
        var queryParams = __assign({}, params, { extend: 'app' });
        return this.http.get(this.config.gravityCmsApi + "/cms/lists/" + id, { params: CmsObjectHelper.objToSearchParams(queryParams) });
    };
    CmsListsService.prototype.getLists = function (params) {
        var queryParams = __assign({}, params, { extend: 'company,app' });
        return this.http.get(this.config.gravityCmsApi + "/cms/lists", { params: CmsObjectHelper.objToSearchParams(queryParams) });
    };
    CmsListsService.prototype.createList = function (data) {
        return this.http.post(this.config.gravityCmsApi + "/cms/lists", data);
    };
    CmsListsService.prototype.updateList = function (listId, data) {
        return this.http.put(this.config.gravityCmsApi + "/cms/lists/" + listId, data);
    };
    CmsListsService.prototype.createOrUpdateListTranslation = function (listId, localeId, data) {
        return this.http.put(this.config.gravityCmsApi + "/cms/lists/" + listId + "/translations/" + localeId, data);
    };
    return CmsListsService;
}());
export { CmsListsService };

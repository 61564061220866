import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SITE_CONFIG } from '../core/site-config.service';
import { CmsObjectHelper } from '../shared/object-helper.service';
import { Observable } from 'rxjs';
import { AppTemplate } from '../app-templates/shared/app-templates.service';
import { App } from '../apps/shared/apps.service';
import { Pagination } from '../../types';
import { ISiteConfig } from '../core/site-config.interface';

export const MODULE_ID = 'applicationForms';

export interface Form {
    id: number,
    appFormTemplate: object | number,
    app: App | number,
    name: string,
    title: string,
    data: object | string,
    active: boolean,
    createdAt: string,
    updatedAt: string
}

export interface FormTemplate {
    id: number,
    appTemplate: AppTemplate | number,
    name: string,
    title: string,
    data: object | string,
    active: boolean,
    createdAt: string,
    updatedAt: string
}

export interface FormsResponse {
    forms: Array<Form>,
    pagination: Pagination
}

export interface FormTemplatesResponse {
    formTemplates: Array<FormTemplate>,
    pagination: Pagination
}

/**
 *
 * @param form
 * @return {Form | FormTemplate}
 */
export function parseForm (form: Form | FormTemplate) {
    form.data = form.data instanceof Object ?
        JSON.stringify(form.data) : form.data;

    return form;
}

@Injectable()
export class CmsFormsService {

    constructor(private http: HttpClient,
                @Inject(SITE_CONFIG) private config: ISiteConfig) {}

    /**
    * @param {Object} params
    * @param {Number} params.appId
    * @param {Number} params.appFormTemplateId
    * @param {String} params.name
    * @param {String} params.title
    * @param {Object} params.data
    * @param {Boolean} params.active
    * @return {Observable<Response>}
    */
    createForm(params) {
        return this.http.post(`${this.config.gravityCmsApi}/cms/app-forms`,
            {
                appId: params.appId,
                appFormTemplateId: params.appFormTemplateId,
                name: params.name,
                title: params.title,
                data: params.data,
                active: params.active
            }
        );
    }

    /**
     * @param {Object} params
     * @param {String} params.formId
     * @param {String} params.title
     * @param {Object} params.data
     * @param {Boolean} params.active
     * @return {Observable<Response>}
     */
    updateForm(params) {
        return this.http.put(`${this.config.gravityCmsApi}/cms/app-forms/${params.formId}`,
            {
                title: params.title,
                data: params.data,
                active: params.active
            }
        );
    }

    /**
     * @param {Object} params
     * @param {String} params.formId
     * @param {Object} params.search
     * @param {String} params.search.extend - Extend data with related resource information (app, appFormTemplate)
     * @return {Observable<Response>}
     */
    getForm(params: any):
    Observable<{appForm: Form}> {
        return this.http.get<{appForm: Form}>(`${this.config.gravityCmsApi}/cms/app-forms/${params.formId}`, {
            params: CmsObjectHelper.objToSearchParams(params.search)
        });
    }

    /**
     * @param {Object} params
     * @param {String} params.search
     * @param {String} params.appId
     * @param {String} params.extend - Extend data with related resource information (app, appFormTemplate)
     * @param {Boolean} params.showInactive
     * @return {Observable<Response>}
     */
    getForms(params?: Object):
    Observable<{forms: Array<Form>, pagination: Pagination}> {
        return this.http.get<{forms: Array<Form>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/app-forms`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    /**
     * @param {Object} params
     * @param {Number} params.appTemplateId
     * @param {String} params.name
     * @param {String} params.title
     * @param {Object} params.data
     * @param {Boolean} params.active
     * @return {Observable<Response>}
     */
    createFormTemplate(params) {
        return this.http.post(`${this.config.gravityCmsApi}/cms/app-form-templates`,
            {
                appTemplateId: params.appTemplateId,
                name: params.name,
                title: params.title,
                data: params.data,
                active: params.active
            }
        );
    }

    /**
     * @param {Object} params
     * @param {String} params.formTemplateId
     * @param {String} params.title
     * @param {Object} params.data
     * @param {Boolean} params.active
     * @return {Observable<Response>}
     */
    updateFormTemplate(params) {
        return this.http.put(`${this.config.gravityCmsApi}/cms/app-form-templates/${params.formTemplateId}`,
            {
                title: params.title,
                data: params.data,
                active: params.active
            }
        );
    }

    /**
     * @param {Object} params
     * @param {String} params.formTemplateId
     * @param {Object} params.search
     * @param {String} params.search.extend - Extend data with related resource information (appTemplate)
     * @return {Observable<Response>}
     */
    getFormTemplate(params):
    Observable<{appFormTemplate: FormTemplate}> {
        return this.http.get<{appFormTemplate: FormTemplate}>
        (`${this.config.gravityCmsApi}/cms/app-form-templates/${params.formTemplateId}`, {
            params: CmsObjectHelper.objToSearchParams(params.search)
        });
    }

    /**
     * @param {Object} params
     * @param {String} params.search
     * @param {String} params.appTemplateId
     * @param {String} params.extend - Extend data with related resource information (appTemplate)
     * @param {Boolean} params.showInactive
     * @return {Observable<Response>}
     */
    getFormTemplates(params?: Object):
    Observable<{formTemplates: Array<FormTemplate>, pagination: Pagination}> {
        return this.http.get<{formTemplates: Array<FormTemplate>, pagination: Pagination}>
        (`${this.config.gravityCmsApi}/cms/app-form-templates`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }
}

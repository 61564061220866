import { Component } from '@angular/core';
import { CmsModalService } from '../../core/modal.service';
import { CmsNotifyService } from '../../core/notify.service';

@Component({
    selector: 'cms-delete-menu-item',
    templateUrl: 'delete-menu-item.component.html'
})
export class CmsDeleteMenuItemComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService
    ) {}

    deleteItem() {
        this.notifyService.notify({
            type: 'deleteItem',
            data: {
                index: this.data.index
            }
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('delete-menu-item');
    }
}

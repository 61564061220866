import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Article} from '../../articles/shared/articles.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsModalService} from '../../core/modal.service';
import {UserService} from '../../core/user.service';
import {map} from 'lodash';
import {Resource, RESOURCE_FILE_TYPES} from '../../resources/shared/resources.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {LIST_ITEM_TYPE} from '../lists.service';

@Component({
    selector: 'cms-add-list-item',
    templateUrl: './add-list-item.component.html'
})
export class CmsAddListItemComponent implements OnInit {
    public form: FormGroup;
    public imageUrl = '';
    public listItemType = LIST_ITEM_TYPE;
    public listItemTypes;
    public resourceFileTypes = RESOURCE_FILE_TYPES;
    public resourcePickerOptions;
    public resourceSelected: boolean = false;
    public titleValidationInterpolationParams;
    constructor(
        private fb: FormBuilder,
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService,
        private userService: UserService,
        private translateService: CmsTranslateService,
    ) {
        this.listItemTypes = [{
            id: LIST_ITEM_TYPE.article,
            title: this.translateService.translate('articles_article')
        }, {
            id: LIST_ITEM_TYPE.resource,
            title: this.translateService.translate('resources_resource')
        }];

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.resourcePickerOptions = {
            mimeType: map(this.userService.data.modules.resources.allowedFileFormats, format => (format.mimeType))
        };
    }

    ngOnInit() {
        this.form = this.fb.group({
            listItemType: ['', [Validators.required]],
            title: {value: '', disabled: true},
            resource: [''],
            article: [''],
            active: [{value: true, disabled: true}]
        });

        this.createFormValueChangesSubscriptions();
    }

    openResourcePicker() {
        this.resourceSelected = false;
    }

    onArticlesSelect({article}: {article: Article}) {
        this.form.patchValue({
            article: article,
            title: article.title,
        });
        this.form.get('active').enable();
        this.resourceSelected = false;
    }

    onResourceSelect({resource}: {resource: Resource}) {
        this.form.patchValue({
            resource: resource,
            title: resource.title
        });
        this.form.get('active').enable();
        this.imageUrl = resource['thumb-large-url'] || resource.url || '';
        this.resourceSelected = true;
    }

    addListItem() {
        const listItemType = this.form.get('listItemType').value.id;

        this.notifyService.notify({
            type: 'add-list-item',
            data: {
                active: this.form.get('active').value,
                [listItemType]: this.form.get(listItemType).value,
                title: this.form.get('title').value,
                listItemType: listItemType,
            },
        });
        this.closePopup();
    }

    closePopup() {
        this.modalService.close('add-list-item');
    }

    private createFormValueChangesSubscriptions() {
        this.form.controls['listItemType'].valueChanges.subscribe(selectedListItemType => {
            if (selectedListItemType) {
                this.listItemTypes.forEach(({id: listItemType}) => {
                    if (selectedListItemType.id !== listItemType) {
                        this.form.controls[listItemType].clearValidators();
                    } else {
                        this.form.controls[listItemType].setValidators([Validators.required]);
                    }
                    this.form.controls[listItemType].updateValueAndValidity();
                });
            }
        })
    }
}


import {throttleTime, filter, switchMap, distinctUntilChanged} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {assign} from 'lodash';

import {Subject} from 'rxjs';

@Component({
    selector: 'cms-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})

export class CmsPaginationComponent implements OnInit, OnChanges {
    @Input() pagination: Object;
    @Input() pageSizes: Array<number>;
    @Input() config = {
        disableNavigation: false
    };
    @Output() paginationChanged = new EventEmitter<Object>();
    currentPage: number = 1;
    paginationCountOptionsShown = false;
    pages: Array<number>;
    paginate$ = new Subject<string>();
    private selectedPageSize: number = 10;
    private lastPage: number;

    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute
    ) {
        // Subscribe to queryParams to update local values on change
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            switchMap(() => this.currentRoute.queryParams),
            distinctUntilChanged(), )
            .subscribe((queryParams) => {
                if (queryParams['pageSize']) {
                    this.selectedPageSize = parseInt(queryParams['pageSize']);
                }

                if (queryParams['currentPage']) {
                    this.currentPage = parseInt(queryParams['currentPage']);
                }

            });

        this.paginate$.pipe(
            throttleTime(400))
            .subscribe((pageType: string) => {
                this.changePage(pageType);
            });
    }

    ngOnInit() {
        this.pages = this.pageSizes || [10, 15, 20];
        this.setInialValues();
    }

    ngOnChanges(changes) {
        const queryParams = this.currentRoute.snapshot.queryParams;
        let page;

        if (changes.pagination && changes.pagination.currentValue) {
            const pagination = changes.pagination.currentValue;
            const pageParams: Object = {
                pageSize: parseInt(pagination.pageSize),
                currentPage: parseInt(pagination.currentPage)
            };

            // Update current and last page
            this.currentPage = pagination.totalCount ? pagination.currentPage : 1;
            this.lastPage = pagination.totalCount ? Math.ceil(pagination.totalCount / pagination.pageSize) : 1;
            // Copy existing URL params and add pagination params
            assign(queryParams, this.currentRoute.snapshot.queryParams, pageParams);

            if (!this.isNavigationDisabled()) {
                this.router.navigate(['./'], {
                    relativeTo: this.currentRoute,
                    queryParams: queryParams
                });
            }
        }

        if (queryParams['pageSize'] && !this.isNavigationDisabled()) {
            page = parseInt(queryParams['pageSize']);
        }

        if (changes.pageSizes && changes.pageSizes.currentValue && changes.pageSizes.currentValue.indexOf(page) === -1) {
            const pageSize = changes.pageSizes.currentValue[0];
            this.pages = changes.pageSizes.currentValue;
            this.selectedPageSize = parseInt(pageSize);
            this.paginationCountOptionsShown = false;
            this.applyChange({
                pageSize: pageSize,
                currentPage: 1
            });
        }
    }

    setInialValues() {
        const queryParams = this.currentRoute.snapshot.queryParams;

        if (!this.isNavigationDisabled()) {
            if (queryParams['pageSize']) {
                this.selectedPageSize = parseInt(queryParams['pageSize']);
            }

            if (queryParams['currentPage']) {
                this.currentPage = parseInt(queryParams['currentPage']);
            }
        }
    }

    changePageSize(pageSize: string) {
        // Toggle page size menu
        this.paginationCountOptionsShown = !this.paginationCountOptionsShown;
        // Reset to first page
        this.currentPage = 1;

        const pageParams: Object = {
            pageSize: pageSize,
            currentPage: 1
        };

        this.applyChange(pageParams);
    }

    changePage(type: string) {
        switch (type) {
            case 'first':
                this.currentPage = 1;
                break;
            case 'prev':
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
                break;
            case 'next':
                this.currentPage++;
                break;
            case 'last':
                this.currentPage = this.lastPage;
                break;
        }

        const pageParams = {
            pageSize: this.selectedPageSize,
            currentPage: this.currentPage
        };

        this.applyChange(pageParams);
    }

    applyChange(pageParams: Object) {
        // Copy and override current URL params
        const queryParams = assign({}, this.currentRoute.snapshot.queryParams, pageParams);
        // Emit params to parent component
        this.paginationChanged.emit(queryParams);

        if (!this.isNavigationDisabled()) {
            // Set URL params
            this.router.navigate(['./'], {relativeTo: this.currentRoute, queryParams: queryParams});
        } else {
            // set new selected page size because router won't emit change needed to
            // update selectedPageSize value
            this.selectedPageSize = pageParams['pageSize'];
        }

    };

    private isNavigationDisabled() {
        return this.config && this.config.disableNavigation;
    }
}

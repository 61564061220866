/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/pipes/translate.pipe";
import * as i2 from "../../shared/translate.service";
import * as i3 from "../../shared/escape-key/escape-key.directive";
import * as i4 from "./delete-menu-item.component";
import * as i5 from "../../core/modal.service";
import * as i6 from "../../core/notify.service";
var styles_CmsDeleteMenuItemComponent = [];
var RenderType_CmsDeleteMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsDeleteMenuItemComponent, data: {} });
export { RenderType_CmsDeleteMenuItemComponent as RenderType_CmsDeleteMenuItemComponent };
export function View_CmsDeleteMenuItemComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.CmsTranslatePipe, [i2.CmsTranslateService]), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "popup g-popup"]], null, [[null, "cmsEscapeKey"], ["window", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown.escape" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).handleEscapeKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("cmsEscapeKey" === en)) {
        var pd_1 = (_co.closePopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i3.CmsEscapeKeyDirective, [], null, { cmsEscapeKey: "cmsEscapeKey" }), (_l()(), i0.ɵeld(3, 0, null, null, 4, "header", [["class", "g-popup-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "g-popup-header-close g-icon g-icon-close-a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "g-popup-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 13, "div", [["class", "g-popup-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), i0.ɵppd(11, 1), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "form-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "form-footer-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["class", "form-footer-button g-button g-button-earth"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "span", [["class", "g-button-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵppd(17, 1), (_l()(), i0.ɵeld(18, 0, null, null, 3, "button", [["class", "form-footer-button g-button g-button-earth"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "span", [["class", "g-button-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(20, null, ["", ""])), i0.ɵppd(21, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), "menus_confirm_item_delete_action")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "menus_confirm_item_delete")); _ck(_v, 10, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v, 0), "common_cancel")); _ck(_v, 16, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i0.ɵnov(_v, 0), "common_delete")); _ck(_v, 20, 0, currVal_3); }); }
export function View_CmsDeleteMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-delete-menu-item", [], null, null, null, View_CmsDeleteMenuItemComponent_0, RenderType_CmsDeleteMenuItemComponent)), i0.ɵdid(1, 49152, null, 0, i4.CmsDeleteMenuItemComponent, [i5.CmsModalService, i6.CmsNotifyService], null, null)], null, null); }
var CmsDeleteMenuItemComponentNgFactory = i0.ɵccf("cms-delete-menu-item", i4.CmsDeleteMenuItemComponent, View_CmsDeleteMenuItemComponent_Host_0, {}, {}, []);
export { CmsDeleteMenuItemComponentNgFactory as CmsDeleteMenuItemComponentNgFactory };

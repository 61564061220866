
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { UserService } from './user.service';
import {Subscription} from 'rxjs';

@Injectable()
export class LoggedInGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        // let's just pickup first requested url by user and do un-subscribe
        const subscription: Subscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe((val: NavigationCancel | NavigationEnd) => {
            if (!val.url.match(/\?a=/)) {
                localStorage.setItem('cms.requested-url', val.url);
            }

            subscription.unsubscribe();
        });
    }

    canActivate (route, state) {
        // cache check
        if (!this.userService.isLoggedIn()) {
            if (!this.router.isActive('/login', true)) {
                // if not authenticated
                this.router.navigate(['/login']);
            }
            return false;
        }

        return true;
    }
}

import {FormControl } from '@angular/forms';

export function validateCheckboxRequired(c: FormControl): { [key: string]: any } {
    for (let i = 0; i < c.value.length; i++) {
        if (c.value[i]) {
            return null;
        }
    }

    return {
        checkBoxRequired: {
            valid: false,
        }
    };
}

import {Component, ViewChild, OnInit, HostBinding} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JsonEditorComponent} from '../../shared/json-editor/json-editor.component';
import {cloneDeep} from 'lodash';
import { CmsAppTemplateSettingsService, SETTINGS_TYPES } from 'app/app-template-settings/shared/app-template-settings.service';

@Component({
    selector: 'cms-view-app-template-setting',
    templateUrl: 'view-app-template-setting-archive.component.html',
    providers: [CmsAppTemplateSettingsService]
})
export class CmsAppTemplateSettingsArchiveViewComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    settingTypes = SETTINGS_TYPES;
    originalData: any;
    private valueValidators = [Validators.required];

    @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private appTemplateSettingsService: CmsAppTemplateSettingsService,
        private fb: FormBuilder) {
        this.form = this.fb.group({
            appTemplate: this.fb.group(
                {
                    title: [
                        {
                            value: '',
                            disabled: true
                        }
                    ]
                }
            ),
            key: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            value: [
                {
                value: '',
                disabled: true
                }, this.valueValidators],
            type: [{id: ''}, [Validators.required]]
        });

        this.activeRoute.params.subscribe((params: {settingId: string, key: string}) => {
            this.appTemplateSettingsService.getAppTemplateSetting({
                settingId: params.settingId,
                key: params.key,
                search: {extend: 'appTemplate'}
            }).subscribe(res => {
                this.originalData = cloneDeep(res.appTemplateSetting);
                
                res.appTemplateSetting.type = this.settingTypes.find(type => {
                    return this.originalData.type === type.id;
                });
                this.form.patchValue(res.appTemplateSetting);
            });
        });
    }

    ngOnInit() {
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'merge'}
        );
    }
}

import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ISiteConfig } from './site-config.interface';
import { SITE_CONFIG } from './site-config.service';

@Injectable()
export class CmsInterceptor implements HttpInterceptor {
  constructor(
    @Inject(SITE_CONFIG) private config: ISiteConfig,
    @Inject(DOCUMENT) private document: any
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginUrl = 'login';
    const logoutUrl = 'logout';
    let clonedRequest;
    let params = request.params;
    const token = localStorage.getItem('cms.auth_token')
    const dc = this.getQuery('dc') || (this.config.hosts && this.config.hosts[this.document.location.host]);

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (token) {
      httpHeaders = httpHeaders.append('Authorization', token);
    }

    if (dc) {
      httpHeaders = httpHeaders.append('x-nsft-scd-dc', dc);
    }

    if (request.url.indexOf(loginUrl) < 0 && request.url.indexOf(logoutUrl) < 0) {
      httpHeaders = httpHeaders.append('X-Nsft-SCD-Merge', 'true');
    }

    // quick fix
    // https://github.com/angular/angular/issues/20564#issuecomment-587558261
    for (const key of request.params.keys()) {
      if (params.get(key) === undefined) {
          params = params.delete(key, undefined);
      }

      if (params.get(key) === null) {
        params = params.delete(key, null);
      }
    }

    clonedRequest = request.clone({
      headers: httpHeaders,
      params
    });

    return next.handle(clonedRequest);
  }

  private getQuery(name) {
    const query = this.document.location.search.substring(1),
      keys = query.split('&'),
      len = keys.length;

    for (let i = 0; i < len; i++) {
      const key = keys[i].split('=');
      if (decodeURIComponent(key[0]) === name) {
        return decodeURIComponent(key[1]);
      }
    }
  }
}

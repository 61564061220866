
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SITE_CONFIG} from '../../core/site-config.service';
import { CmsObjectHelper } from 'app/shared/object-helper.service';
import { Company } from '../../shared/common.service';
import {Pagination} from '../../../types';
import {ISiteConfig} from '../../core/site-config.interface';

export interface AppTemplate {
    id: number;
    name: string;
    title: string;
}

export interface App {
    active: boolean;
    appTemplate?: AppTemplate;
    company?: Company;
    createdAt: string;
    id: number;
    name: string;
    title: string;
    updatedAt: string;
}

export interface AppsResponse {
    apps?: Array<App>,
    pagination?: Pagination
}

export const MODULE_ID = 'applications';

@Injectable()
export class CmsAppsService {

    constructor(
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    _toJson = (res: Response) => {
        const data = res.json();
        return data || {};
    }

    /**
     * Get list of seven applications
     *
     * @param {Object} params
     * @param {number} params.appTemplate - Filter by base apps
     * @param {number} params.company - Filter by company
     * @param {string} params.search - Search apps by name or title
     * @param {string} params.extend - Extend data with related resource information (company,appTemplate)
     * @param {number} params.currentPage - Displayed page number
     * @param {number} params.pageSize - Number of items per page
     * @return {Observable<R>}
     */
    getApps (params?: Object): Observable<{apps: Array<App>, pagination: Pagination}> {
        return this.http.get<{apps: Array<App>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/apps`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    getAllApps() {
        return this.getApps({pageSize: Number.MAX_SAFE_INTEGER});
    }

    /**
     * Get list of application templates
     *
     * @param {Object} params
     * @param {number} params.currentPage - Displayed page number
     * @param {number} params.pageSize - Number of items per page
     * @return {Observable<R>}
     */
    getAppsTemplates (params?: Object): Observable<{appTemplates: Array<AppTemplate>, pagination: Pagination}> {
        return this.http.get<{appTemplates: Array<AppTemplate>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/app-templates`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    /**
     * 
     * @param id 
     * @param {Object} params
     * @param {string} params.extend - Extend data with related resource information (company,appTemplate)
     */
    getApp (id: number, params: Object): Observable<{app: App}> {
        return this.http.get<{app: App}>(
            `${this.config.gravityCmsApi}/cms/apps/${id}`, {
                params: CmsObjectHelper.objToSearchParams(params)
            }
        );
    }

    createApp (app: App): Observable<{app: App}> {
        return this.http.post<{app: App}>(
            `${this.config.gravityCmsApi}/cms/apps`,
            app
        );
    }

    editApp (app: App, appId: number): Observable<{app: App}> {
        return this.http.put<{app: App}>(
            `${this.config.gravityCmsApi}/cms/apps/${appId}`,
            app
        );
    }
}

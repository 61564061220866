/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource-picker-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/translate.pipe";
import * as i3 from "../../translate.service";
import * as i4 from "../../escape-key/escape-key.directive";
import * as i5 from "../resource-picker.component.ngfactory";
import * as i6 from "../resource-picker.component";
import * as i7 from "../../../resources/shared/resources.service";
import * as i8 from "./resource-picker-modal.component";
import * as i9 from "../../../core/modal.service";
import * as i10 from "../../../core/notify.service";
var styles_CmsResourcePickerModalComponent = [i0.styles];
var RenderType_CmsResourcePickerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CmsResourcePickerModalComponent, data: {} });
export { RenderType_CmsResourcePickerModalComponent as RenderType_CmsResourcePickerModalComponent };
export function View_CmsResourcePickerModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CmsTranslatePipe, [i3.CmsTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "popup g-popup"]], null, [[null, "cmsEscapeKey"], ["window", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleEscapeKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("cmsEscapeKey" === en)) {
        var pd_1 = (_co.closePopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.CmsEscapeKeyDirective, [], null, { cmsEscapeKey: "cmsEscapeKey" }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "header", [["class", "g-popup-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "g-popup-header-close g-icon g-icon-close-a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "g-popup-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "g-popup-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "cms-resource-picker", [["class", "m-2"]], null, [[null, "onResourceSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onResourceSelect" === en)) {
        var pd_0 = (_co.onResourceSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CmsResourcePickerComponent_0, i5.RenderType_CmsResourcePickerComponent)), i1.ɵdid(10, 114688, null, 0, i6.CmsResourcePickerComponent, [i7.CmsResources], { options: [0, "options"] }, { onResourceSelect: "onResourceSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.options; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "resource_picker")); _ck(_v, 6, 0, currVal_0); }); }
export function View_CmsResourcePickerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-resource-picker-modal", [], null, null, null, View_CmsResourcePickerModalComponent_0, RenderType_CmsResourcePickerModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.CmsResourcePickerModalComponent, [i9.CmsModalService, i10.CmsNotifyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CmsResourcePickerModalComponentNgFactory = i1.ɵccf("cms-resource-picker-modal", i8.CmsResourcePickerModalComponent, View_CmsResourcePickerModalComponent_Host_0, {}, {}, []);
export { CmsResourcePickerModalComponentNgFactory as CmsResourcePickerModalComponentNgFactory };

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../core/site-config.service';
import {Observable} from 'rxjs';
import {ISiteConfig} from '../core/site-config.interface';

@Injectable()
export class CmsAppSettingsArchiveService {
    
    constructor(private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig) {}

    /**
     * @param {Number} applicationSettingId
     * @return {Observable<Response>}
     */
   
    unarchiveSetting(appSettingId) {       
        return this.http.put(`${this.config.gravityCmsApi}/cms/app-settings/${appSettingId}/restore`, {});
    }
    public deleteSettingArchive(appSettingId): Observable<Object> {        
        return this.http.delete(`${this.config.gravityCmsApi}/cms/app-settings/${appSettingId}`);
    }
}
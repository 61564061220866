import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {extend} from 'lodash';
import {CmsModalService} from '../../core/modal.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';

@Component({
    selector: 'cms-edit-menu-template-item',
    templateUrl: 'edit-menu-template-item.component.html'
})

export class CmsEditMenuTemplateItemComponent implements OnInit {
    menuTemplateItem: FormGroup;
    data: any;
    titleValidationInterpolationParams;

    constructor(
        private fb: FormBuilder,
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
    }

    ngOnInit() {
        this.menuTemplateItem = this.fb.group({
            title: ['', [Validators.required, Validators.maxLength(120)]],
            subtitle: ['', []],
            description: ['', []],
            url: ['', []],
            icon: ['', []],
            active: [false, []],
            disabled: [false, []],
            customData: [{}, [validateJson]]
        });

        this.menuTemplateItem.patchValue({
            title: this.data['title'],
            subtitle: this.data['subtitle'],
            description: this.data['description'],
            url: this.data['url'],
            icon: this.data['icon'],
            active: this.data['active'],
            disabled: this.data['disabled'],
            customData: this.data['customData']
        });
    }

    editItem() {
        this.notifyService.notify({
            target: 'menuTemplateEdit',
            type: 'editItem',
            data: {
                index: this.data.index,
                fields: extend(this.data, this.menuTemplateItem.value)
            }
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('edit-menu-template-item');
    }
}

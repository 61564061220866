<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'applications_new_application_template' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="newAppTemplate" (ngSubmit)="$submit.next($event)">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (newAppTemplate.get('name').hasError('required') || newAppTemplate.get('name').hasError('maxlength') || newAppTemplate.get('name').hasError('pattern')) && !newAppTemplate.get('name').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_name' | translate" formControlName="name">
                        <label class="g-input-label">{{ 'common_name' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="newAppTemplate.get('name').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="newAppTemplate.get('name').hasError('maxlength') && !newAppTemplate.get('name').hasError('pattern')">
                                {{'common_validation_maximum_field_length' | translate: validationInterpolation.name}}
                            </span>
                            <span *ngIf="newAppTemplate.get('name').hasError('pattern')">
                                {{'common_validation_valid_characters' | translate: validationInterpolation.name}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (newAppTemplate.get('title').hasError('required') || newAppTemplate.get('title').hasError('maxlength')) && !newAppTemplate.get('title').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_title' | translate" formControlName="title">
                        <label class="g-input-label">{{ 'common_title' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="newAppTemplate.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="newAppTemplate.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: validationInterpolation.title}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (newAppTemplate.get('nameTemplate').hasError('pattern') ||
                                               newAppTemplate.get('nameTemplate').hasError('maxlength')) &&
                                               !newAppTemplate.get('nameTemplate').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'application_templates_name_template' | translate" formControlName="nameTemplate">
                        <i class="g-input-icon g-icon g-icon-status-b" title="{{nameTemplateInterpolationGuide}}"></i>
                        <label class="g-input-label">{{ 'application_templates_name_template' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="newAppTemplate.get('nameTemplate').hasError('pattern')">
                                {{ 'application_templates_validation_name_template' | translate: validationInterpolation.nameTemplate.pattern }}
                            </span>
                            <span *ngIf="newAppTemplate.get('nameTemplate').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: validationInterpolation.nameTemplate.maxLength}}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth"
                            [disabled]="newAppTemplate.invalid">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../shared/pipes/translate.pipe";
import * as i3 from "../../shared/translate.service";
import * as i4 from "../../shared/escape-key/escape-key.directive";
import * as i5 from "./add-page-widget.component";
import * as i6 from "../../core/modal.service";
import * as i7 from "../../core/notify.service";
var styles_AddPageWidgetComponent = [];
var RenderType_AddPageWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddPageWidgetComponent, data: {} });
export { RenderType_AddPageWidgetComponent as RenderType_AddPageWidgetComponent };
function View_AddPageWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "widget"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.configurePageWidget(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { active: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "i", [["class", "widget-icon g-icon"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "widget-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "widget"; var currVal_1 = _ck(_v, 3, 0, (_co.configuredWidgetId === _v.context.$implicit.id)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "widget-icon g-icon"; var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_4); }); }
export function View_AddPageWidgetComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.CmsTranslatePipe, [i3.CmsTranslateService]), (_l()(), i0.ɵeld(1, 0, null, null, 21, "div", [["class", "popup g-popup"]], null, [[null, "cmsEscapeKey"], ["window", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown.escape" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).handleEscapeKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("cmsEscapeKey" === en)) {
        var pd_1 = (_co.closePopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i4.CmsEscapeKeyDirective, [], null, { cmsEscapeKey: "cmsEscapeKey" }), (_l()(), i0.ɵeld(3, 0, null, null, 4, "header", [["class", "g-popup-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "g-popup-header-close g-icon g-icon-close-a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "g-popup-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 14, "div", [["class", "g-popup-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 7, "div", [["class", "add-page-widget-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "g-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "input", [["class", "g-search-input"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.filterWidgets($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(12, 1), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "g-search-icon g-icon g-icon-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "widgets"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddPageWidgetComponent_1)), i0.ɵdid(16, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 5, "div", [["class", "popup-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 4, "div", [["class", "popup-footer-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "button", [["class", "popup-footer-button g-button g-button-earth"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "span", [["class", "g-button-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["", ""])), i0.ɵppd(22, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.filteredWidgets; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), "pages_widgets_add_widget")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), "common_search")); _ck(_v, 11, 0, currVal_1); var currVal_3 = !_co.configuredWidgetId; _ck(_v, 19, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 21, 0, _ck(_v, 22, 0, i0.ɵnov(_v, 0), "common_save")); _ck(_v, 21, 0, currVal_4); }); }
export function View_AddPageWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-add-page-widget", [], [[8, "className", 0]], null, null, View_AddPageWidgetComponent_0, RenderType_AddPageWidgetComponent)), i0.ɵdid(1, 245760, null, 0, i5.AddPageWidgetComponent, [i6.CmsModalService, i7.CmsNotifyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var AddPageWidgetComponentNgFactory = i0.ɵccf("cms-add-page-widget", i5.AddPageWidgetComponent, View_AddPageWidgetComponent_Host_0, {}, {}, []);
export { AddPageWidgetComponentNgFactory as AddPageWidgetComponentNgFactory };

import {Component, Input, OnInit} from '@angular/core';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsNotifyService} from 'app/core/notify.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsModalService} from '../../core/modal.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {cloneDeep, assign} from 'lodash';
import {Resource} from '../../resources/shared/resources.service';
import {CmsResourcePickerModalComponent} from '../../shared/resource-picker/reference-picker-modal/resource-picker-modal.component';
import {requireSelfOrValidator} from '../../shared/validators/requireSelfOr.validator';

@Component({
  selector: 'cms-edit-marketing-slot-item',
  templateUrl: './edit-marketing-slot-item.component.html',
})
export class CmsEditMarketingSlotItemComponent implements OnInit {
  @Input() data: {
    index: number,
    item: {
      active: boolean,
      customData: string,
      provider: object,
      reference: string,
      resource: {
        url: string,
      },
      textContent: string,
      url: string,
      startDate: string,
      endDate: string
    },
    providers: Array<object>,
  };
  contentEditorConfig = {
    toolbar: 'bold | alignleft aligncenter alignright alignjustify | customhr | forecolor | width | styleselect | code',
    plugins: [
      'colorpicker',
      'textcolor',
      'hr',
      'width',
      'customhr',
      'code'
    ],
    textcolor_cols: '1',
    textcolor_rows: '0',
  };
  form: FormGroup;
  urlValidationInterpolationParams: { field: string; maximum: string };
  constructor(
      private fb: FormBuilder,
      private modalService: CmsModalService,
      private notifyService: CmsNotifyService,
      private translateService: CmsTranslateService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      provider: [this.data.item.provider, [Validators.required]],
      reference: [this.data.item.reference, []],
      url: [this.data.item.url, [Validators.maxLength(255)]],
      textContent: [this.data.item.textContent],
      active: [this.data.item.active, []],
      customData: [this.data.item.customData, [validateJson]],
      startDate: [this.data.item.startDate || null],
      endDate: [this.data.item.endDate || null]
    });

    this.setRequiredFieldsForProvider(this.data.item.provider);

    this.urlValidationInterpolationParams = {
      field: this.translateService.translate('common_url').toLowerCase(),
      maximum: '255',
    };

    this.notifyService.change$.subscribe((item: {type: string, data: Resource}) => {
      if (item.type === 'resource-picker-modal-pick') {
        this.form.get('reference').patchValue(item.data);
      }
    });
  }

  ngOnDestroy() {
    this.modalService.close('resource-picker-modal');
  }

  updateItem() {
    this.notifyService.notify({
      type: 'edit-marketing-slot-item',
      data: {
        item: this.form.value,
        index: this['data']['index']
      }
    });

    this.closePopup();
  }

  onProviderSelect(provider) {
    if (!provider) { return; }
    this.setRequiredFieldsForProvider(provider);
    this.resetFormValuesForProvider(provider);
    this.form.updateValueAndValidity();
  }

  openResourcePicker() {
    this.modalService.open({
      id: 'resource-picker-modal',
      component: CmsResourcePickerModalComponent,
        data: {
            options: {
                mimeType: ['image/png', 'image/jpeg']
            }
        }
    });
  }

  resetFormValuesForProvider(provider) {
    this.form.get('reference').reset();

    if (provider.name === 'google_dfp') {
      // Value of tiny-mce can't be undefined or null or it will throw error
      this.form.get('textContent').reset('');
    }
  }

  setRequiredFieldsForProvider(provider) {
    if (provider.name === 'google_dfp') {
      this.setFormFieldValidatorsForGoogleDfpProvider();
    } else if (provider.name === 'seven') {
      this.setFormFieldValidatorsForSevenProvider();
    }
  }

  setFormFieldValidatorsForGoogleDfpProvider() {
    this.form.controls['reference'].setValidators([Validators.required]);
    this.form.controls['textContent'].clearValidators();
  }

  setFormFieldValidatorsForSevenProvider() {
    this.form.controls['reference'].setValidators(requireSelfOrValidator(this.form.controls['textContent']));
    this.form.controls['textContent'].setValidators(requireSelfOrValidator(this.form.controls['reference']));
  }

  removeImage() {
    this.form.controls['reference'].reset();
    this.form.controls['reference'].updateValueAndValidity();
  }

  public getImgUrl() {
    const resource = this.form.get('reference').value;
    return (resource && (resource['thumb-large-url'] || resource.url))
        || (this.data.item.resource && this.data.item.resource.url)
        || '';
  }

  closePopup() {
    this.modalService.close('edit-marketing-slot-item');
  }

  onDateRangeSelect(dates: {startDateTime: string; endDateTime: string}) {
    this.form.patchValue({
      startDate: dates.startDateTime,
      endDate: dates.endDateTime
    })
  }
}


import {map} from 'rxjs/operators';
import {Injectable, Inject} from '@angular/core';
import {Response} from '@angular/http';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../core/site-config.service';
import {CmsObjectHelper} from '../shared/object-helper.service';
import {ISiteConfig} from '../core/site-config.interface';

function decodeSetting(setting) {
    // tslint:disable-next-line
    if (setting.type === SettingType[SettingType.boolean]) {
        setting.value = setting.value === 'true';
    }
}

enum SettingType {
    number,
    string,
    boolean,
    object
}

export const SETTING_TYPES = [
    {
        id: SettingType[SettingType.number],
        title: 'Number'
    },
    {
        id: SettingType[SettingType.string],
        title: 'String'
    },
    {
        id: SettingType[SettingType.boolean],
        title: 'Boolean'
    },
    {
        id: SettingType[SettingType.object],
        title: 'Object'
    }
];

export const MODULE_ID = 'settings';

@Injectable()
export class CmsUserSettingsService {

    constructor(
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    getSettings(params?: Object): Observable<Object[]> {
        // Add 'extend' param on every request
        params['extend'] = 'company';

        return this.http.get<Object[]>(`${this.config.gravityCmsApi}/cms/user-settings`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    createOrUpdateSetting(params?: Object) {
        decodeSetting(params);

        return this.http.put(`${this.config.gravityCmsApi}/cms/user-settings/companies/${params['companyId']}/users/${params['user']}`,
            {
                key: params['key'],
                value: params['value'],
                type: params['type']
            }
        );
    }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../../core/site-config.service';
import {ISiteConfig} from '../../core/site-config.interface';
import {Observable} from 'rxjs';
import {Pagination} from '../../../types';
import {CmsObjectHelper} from '../../shared/object-helper.service';

export interface CmsAudit {
    action: string;
    createdAt: string;
    entity: string;
    id: number;
    identifier: string;
    user: {
        companyPlatformId: string,
        id: string,
        firstName?: string,
        lastName?: string,
        [propName: string]: any;
    };
}


@Injectable()
export class CmsAuditsService {

    constructor(
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    /**
     * @param {Object} params
     * @param {String} params.entity
     * @param {String} params.identifier
     * @param {Number} params.currentPage
     * @param {Number} params.pageSize
     *
     * @return {Observable<T>}
     */
    getAudits(params: {entity: string, identifier: string, currentPage?: number, pageSize?: number}):
    Observable<{audits: Array<CmsAudit>, pagination: Pagination}> {
        return this.http.get<{audits: Array<CmsAudit>, pagination: Pagination}>(
            `${this.config.gravityCmsApi}/cms/audit/${params.entity}/${params.identifier}`,
            {
                params: CmsObjectHelper.objToSearchParams({
                    currentPage: params.currentPage,
                    pageSize: params.pageSize
                })
            }
        );
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { CmsObjectHelper } from '../../shared/object-helper.service';
import { Company, Locale } from '../../shared/common.service';
var CmsArticlesService = /** @class */ (function () {
    function CmsArticlesService(http, config) {
        this.http = http;
        this.config = config;
        this._toJson = function (res) {
            var data = res.json();
            return data || {};
        };
    }
    CmsArticlesService.prototype.getArticleCategories = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/article-categories", { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsArticlesService.prototype.getAllArticleCategories = function (params) {
        var allCategoriesParams = __assign({}, params, { pageSize: Number.MAX_SAFE_INTEGER });
        return this.getArticleCategories(allCategoriesParams);
    };
    CmsArticlesService.prototype.getArticleCategory = function (id, params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/article-categories/" + id, { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsArticlesService.prototype.createCategory = function (categoryFormModel) {
        var application = categoryFormModel.application && categoryFormModel.application.id;
        var company = categoryFormModel.company && categoryFormModel.company.id;
        var category = {
            name: categoryFormModel.name,
            title: categoryFormModel.title,
            active: categoryFormModel.active,
            locale: categoryFormModel.locale.id,
            parent: categoryFormModel.parent && categoryFormModel.parent.id,
        };
        if (application) {
            category.app = application;
        }
        else {
            category.company = company;
        }
        return this.http.post(this.config.gravityCmsApi + "/cms/article-categories", category);
    };
    CmsArticlesService.prototype.editArticleCategory = function (categoryFormModel, categoryId) {
        var category = {
            name: categoryFormModel.name,
            title: categoryFormModel.title,
            active: categoryFormModel.active,
            locale: categoryFormModel.locale.id,
            parent: categoryFormModel.parent && categoryFormModel.parent.id,
            company: categoryFormModel.company,
            app: categoryFormModel.app
        };
        return this.http.put(this.config.gravityCmsApi + "/cms/article-categories/" + categoryId, category);
    };
    CmsArticlesService.prototype.deleteCategory = function (id) {
        return this.http.delete(this.config.gravityCmsApi + "/cms/article-categories/" + id);
    };
    CmsArticlesService.prototype.getArticles = function (params) {
        var queryParams = Object.assign({}, { extend: 'status,locale,company,category' }, params);
        return this.http.get(this.config.gravityCmsApi + "/cms/articles", { params: CmsObjectHelper.objToSearchParams(queryParams) });
    };
    CmsArticlesService.prototype.getAllArticles = function (params) {
        var allArticlesParams = __assign({}, params, { pageSize: Number.MAX_SAFE_INTEGER });
        return this.getArticles(allArticlesParams);
    };
    CmsArticlesService.prototype.getArticle = function (id, params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/articles/" + id, { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsArticlesService.prototype.createArticle = function (article) {
        return this.http.post(this.config.gravityCmsApi + "/cms/articles", article);
    };
    CmsArticlesService.prototype.editArticle = function (article, articleId) {
        return this.http.put(this.config.gravityCmsApi + "/cms/articles/" + articleId, article);
    };
    CmsArticlesService.prototype.getArticleProviders = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/article-providers", { params: CmsObjectHelper.objToSearchParams(params), });
    };
    CmsArticlesService.prototype.getArticleStatuses = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/article-statuses", { params: CmsObjectHelper.objToSearchParams(params), });
    };
    return CmsArticlesService;
}());
export { CmsArticlesService };
export var MODULE_ID = 'articles';
export var CATEGORY_TYPE = {
    company: 'company',
    application: 'application'
};

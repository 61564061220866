/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal.component";
import * as i2 from "../../core/modal.service";
var styles_CmsModalComponent = [];
var RenderType_CmsModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsModalComponent, data: {} });
export { RenderType_CmsModalComponent as RenderType_CmsModalComponent };
export function View_CmsModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { cmsModal: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["cmsModal", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CmsModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-modal", [], [[8, "className", 0]], null, null, View_CmsModalComponent_0, RenderType_CmsModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.CmsModalComponent, [i2.CmsModalService, i0.ComponentFactoryResolver], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).modalOpen; _ck(_v, 0, 0, currVal_0); }); }
var CmsModalComponentNgFactory = i0.ɵccf("cms-modal", i1.CmsModalComponent, View_CmsModalComponent_Host_0, {}, {}, []);
export { CmsModalComponentNgFactory as CmsModalComponentNgFactory };

import {
    Component, Input, Output, forwardRef, EventEmitter, SimpleChanges, OnChanges, ViewChild,
    ElementRef
} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {CmsIscrollDirective} from '../iscroll/iscroll.directive';

@Component({
    selector: 'cms-select',
    templateUrl: 'select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CmsSelectComponent),
            multi: true
        }
    ]
})

// Can only be used with reactive forms
export class CmsSelectComponent implements ControlValueAccessor, OnChanges {
    selected: Object = null;
    selectOpened = false;
    @Input() displayKey = 'title';
    @Input() uniqueKey = 'id';
    @Input() options: any[] = [];
    @Input() placeholder = 'Select option';
    @Input() title = '';
    @Input() disabled = false;
    @Input() nullable = false;
    @Output() selectChanged = new EventEmitter<Object>();
    @ViewChild(CmsIscrollDirective, {static: true}) cmsIscroll: CmsIscrollDirective;
    private _searchTerm = '';
    private startingItems: any[] = [];
    @ViewChild('searchTermEl', {static: true}) searchTermEl: ElementRef;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options']
            && changes['options'].currentValue !== changes['options'].previousValue) {
            // When options are changed, iscroll sometimes moves content of CmsSelectComponent's list up and the list
            // seems empty this method is called to return list to correct position
            this.cmsIscroll.moveIntoView();
            this.startingItems = this.options.concat();
        }
    }

    open() {
        if (this.disabled) {
            return;
        }

        this.selectOpened = true;
    }

    close() {
        if (this.disabled) {
            return;
        }

        this.selectOpened = false;
    }

    onSelectOption(option: Object, event: Event) {
        event.stopPropagation();
        this.setActiveOption(option);
        this.resetSearch();
        this.close();
    }

    setActiveOption(optionSelected: Object) {
        this.selected = optionSelected;
        this.propagateChange(this.selected);
    }

    clearSelectionIfNeeded(event) {
        if ((this.selected && this.selected[this.displayKey]) && this._searchTerm !== this.selected[this.displayKey]) {
            this.clearSelection(event);
        }
    }

    clearSelection(event: Event) {
        event.stopPropagation();
        this.selected = null;
        this.propagateChange(this.selected);
        this.resetSearch();
    }

    propagateChange = (data: Object) => {
        this.selectChanged.emit(data);
        this.propagateFormControlChange(data);
    };

    propagateFormControlChange(data: any) {}

    registerOnChange(fn) {
        this.propagateFormControlChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        if (value) {
            this.selected = value;
        } else {
            this.selected = null;
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    filterItems() {
        const term = this._searchTerm;

        if (!this._searchTerm) {
            this.clearSelection(event);
        }

        if (!this.selectOpened) {
            this.selectOpened = true;
        }

        if (!this.selected) {
            this.options = this.startingItems.filter((item) => {
                return item[this.displayKey].toLowerCase().indexOf(term.toLowerCase()) >= 0;
            });
        }
    }

    resetSearch() {
        this.searchTerm = '';
        this.options = this.startingItems;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
    }

    get searchTerm() {
        return this._searchTerm ?
            this._searchTerm :
            this.selected ?
                this.selected[this.displayKey] :
                '';
    }
}

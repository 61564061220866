import { Component } from '@angular/core';

import { CmsModalService } from '../../core/modal.service';
import { CmsNotifyService } from '../../core/notify.service';

@Component({
    selector: 'cms-delete-menu-translation',
    templateUrl: 'delete-menu-translation.component.html'
})
export class CmsDeleteMenuTranslationComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService
    ) {}

    deleteItem() {
        this.notifyService.notify({
            type: 'deleteMenuTranslation'
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('cms-delete-menu-translation');
    }
}

import {Component} from '@angular/core';
import {CmsTabComponent} from './tab.component';

@Component({
  selector: 'cms-tabs',
  templateUrl: './tabs.component.html',
  styles: [':host {display: block}'],
})

export class CmsTabsComponent {
  tabs: CmsTabComponent[] = [];

  addTab(tab: CmsTabComponent) {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  selectTab(value: CmsTabComponent) {
    this.tabs.forEach(tab => {
      tab.active = tab === value;
    })
  }
}

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()"
           class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{'audits_audits' | translate}}
        </div>
    </header>
    <div class="g-popup-body">
        <div class="m-1"
             *ngIf="auditsData
             && auditsData.audits
             && auditsData.audits.length">
            <cms-table
                    [data]="auditsData.audits"
                    [columns]="columns">
            </cms-table>
            <cms-pagination [pagination]="auditsData.pagination"
                            [config]="paginationConfig"
                            (paginationChanged)="paginationChanged($event)">
            </cms-pagination>
        </div>
        <div class="box-shadow-container no-table-data-box"
             *ngIf="auditsData
             && auditsData.audits
             && !auditsData.audits.length">
            <i class="no-table-data-icon g-icon g-icon-info"></i>
            <span class="no-table-data-message">
                {{ 'common_no_data' | translate }}
            </span>
        </div>
    </div>
</div>
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./tinymce.component";
import * as i4 from "./resources-plugin.service";
var styles_TinymceComponent = [];
var RenderType_TinymceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TinymceComponent, data: {} });
export { RenderType_TinymceComponent as RenderType_TinymceComponent };
function View_TinymceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "dark-body-toggle g-icon g-icon-planing pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDarkBody() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_TinymceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TinymceComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toggleableDarkBody; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.elementId, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_TinymceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "cms-tinymce", [], null, null, null, View_TinymceComponent_0, RenderType_TinymceComponent)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TinymceComponent]), i0.ɵdid(2, 4374528, null, 0, i3.TinymceComponent, [i4.ResourcesPlugin, i0.NgZone, "TINYMCE_CONFIG"], null, null)], null, null); }
var TinymceComponentNgFactory = i0.ɵccf("cms-tinymce", i3.TinymceComponent, View_TinymceComponent_Host_0, { instanceConfig: "instanceConfig", toggleableDarkBody: "toggleableDarkBody" }, {}, []);
export { TinymceComponentNgFactory as TinymceComponentNgFactory };

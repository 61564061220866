<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{ 'menus_edit_item' | translate }}
        </div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="menuTemplateItem" (ngSubmit)="editItem()">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input"
                               type="checkbox"
                               formControlName="itemActive">
                        <label class="g-toggle-label">{{ 'common_active' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth">
                        <span class="g-button-title">{{ 'common_finish_do' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
import {Injectable, EventEmitter, Component} from '@angular/core';

@Injectable()
export class CmsModalService {
    public modal: EventEmitter<Object> = new EventEmitter();

    constructor() {}

    public open(modalConfig: ModalConfig) {
        this.modal.emit({
            action: 'open',
            id: modalConfig.id,
            component: modalConfig.component,
            data: modalConfig.data
        });
    }

    public close(componentId) {
        this.modal.emit({
            action: 'close',
            id: componentId
        });
    }
}

export interface ModalConfigAction {
    action: string;
    id: string;
    component: any;
    data: any;
}

export interface ModalConfig {
    id: string;
    component: any;
    data?: any;
}

import {Inject, Injectable} from '@angular/core';
import {ISiteConfig} from '../../core/site-config.interface';

import { CmsTranslateService } from '../translate.service';
import {SITE_CONFIG, SiteConfig} from '../../core/site-config.service';

@Injectable()
export class ResourcesPlugin {
    public plugin_icon: string;
    public plugin_title: string;
    public plugin_tooltip: string;
    public plugin_close_txt: string;
    public container_dropdown_txt: string;
    public container_search_txt: string;
    public container_dropdown_clear: string;
    public btn_close: string;
    public btn_submit: string;
    public img_dimensions: string;
    public label_width: string;
    public label_height: string;
    public plugin_frame_url: any = '/tinymce-static/resources-plugin.html';
    public resource_url: string;
    public container_url: string;
    public token: string;
    public plugin_width = 600;
    public plugin_height = 400;

    constructor(
        private translateSvc: CmsTranslateService,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {
        this.resource_url = `${this.config.gravityCmsApi}/cms/resources`;
        this.container_url = `${this.config.gravityCmsApi}/cms/resource-containers`;
        this.plugin_icon = 'image';
        this.plugin_title = this.translateSvc.translate('tinymce_resources_title');
        this.plugin_tooltip = this.translateSvc.translate('tinymce_resources_tooltip');
        this.plugin_close_txt = this.translateSvc.translate('tinymce_resources_close');
        this.container_dropdown_txt = this.translateSvc.translate('tinymce_dropdown_container');
        this.container_search_txt = this.translateSvc.translate('tinymce_search');
        this.container_dropdown_clear = this.translateSvc.translate('tinymce_dropdown_clear');
        this.btn_close = this.translateSvc.translate('tinymce_btn_close');
        this.btn_submit = this.translateSvc.translate('tinymce_btn_submit');
        this.img_dimensions = this.translateSvc.translate('tinymce_img_dimensions');
        this.label_width = this.translateSvc.translate('tinymce_label_width');
        this.label_height = this.translateSvc.translate('tinymce_label_height');
        this.token = localStorage.getItem('cms.auth_token');
    }
}

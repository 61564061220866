import {Injectable} from '@angular/core';
import {isEmpty, template} from 'lodash';

@Injectable()
export class CmsTranslateService {
    private _translations = {};

    constructor() {}

    setTranslations(translations: any) {
        this._translations = translations;
    }

    hasTranslations() {
        return !isEmpty(this._translations);
    }

    translate(translationKey: string, interpolationParams?: Object): string {
        const translated = this._translations[translationKey] || translationKey;

        if (interpolationParams) {
            const compiled = template(translated, {interpolate: /{{([\s\S]+?)}}/g});

            return compiled(interpolationParams);
        } else {
            return translated;
        }

    }
}

import { ErrorHandler } from '@angular/core';
import 'local-operators';
import { CmsSiteConfigLoader, SiteConfig } from './core/site-config.service';
import { localStorageWrapper } from 'app/shared/localStorage/localStorageWrapper';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { versions } from '../environments/versions';
export function appInitFactory(config) {
    localStorageWrapper();
    return function () { return config.load(); };
}
// ng-cli won't compile without export (https://github.com/angular/angular-cli/issues/4440#issuecomment-285327136), it's resolved in newer
// versions
export var localHostCheckRegex = /.*:[ 0-9]*/g;
var isLocalDevelopment = !!location.host.match(localHostCheckRegex);
// Disabling sentry relies on local host check (webpack dev server doesn't seem to run on port 80 so it's safe to assume that host will have
// port) and env config
var shouldEnableSentry = environment.production && !isLocalDevelopment;
if (shouldEnableSentry) {
    Sentry.init({
        dsn: 'https://e486a0ada5184644a48a30b7264db644@o73276.ingest.sentry.io/190806',
        environment: environment.name,
        release: "7CMS-v" + versions.version,
        attachStacktrace: true,
        initialScope: {
            tags: { git_commit: versions.revision },
        },
    });
}
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (err) {
        Sentry.withScope(function (scope) {
            scope.setExtra('serverName', environment.gravityCmsApi.replace(/^https?:\/\//i, ''));
            Sentry.captureException(err);
        });
        console.error(err);
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
var errorHandlerProvider = shouldEnableSentry ?
    {
        provide: ErrorHandler,
        useClass: SentryErrorHandler
    } :
    {
        provide: 'DefaultErrorHandler',
        useExisting: 'ErrorHandler'
    };
var ɵ0 = SiteConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };


import {map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ISiteConfig } from '../core/site-config.interface';
import { SITE_CONFIG } from '../core/site-config.service';
import { CmsObjectHelper } from '../shared/object-helper.service';

const SELF_CLOSING_TAGS = [
    'br', 'hr', 'img', 'area', 'meta', 'col', 'embed', 'base',
    'command', 'input', 'keygen', 'link', 'param', 'source', 'track', 'wbr'
];

export const MODULE_ID = 'ticketLayouts';

@Injectable()
export class CmsTicketLayoutService {
    constructor(
        private  http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig) {
    }

    _toJson = (res: Response) => {
        const data = res.json();
        return data || {};
    };

    createTicketLayout(data: object): Observable<any> {
        return this.http.post(`${this.config.gravityCmsApi}/cms/ticket-layouts`, data);
    }

    createTicketLayoutTemplate(data: object): Observable<any> {
        return this.http.post(`${this.config.gravityCmsApi}/cms/ticket-layout-templates`, data);
    }

    editTicketLayoutTemplate(id: number, templateData: Object): Observable<Object> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/ticket-layout-templates/${id}`,
            templateData
        );
    }

    editTicketLayout(id: number, layoutData: Object): Observable<Object> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/ticket-layouts/${id}`,
            layoutData
        );
    }

    escapeSelfClosingTags(template: string): string {
        SELF_CLOSING_TAGS.forEach((tag) => {
            const pattern = `<${tag}></${tag}>`;
            const searchStr = new RegExp(pattern, 'mg');
            template = template.replace(searchStr, `<${tag}/>`);
        });
        return template;
    }

    getAppTemplates(params?: Object): Observable<{ appTemplates: Array<AppTemplate> }> {
        return this.http.get<{ appTemplates: Array<AppTemplate> }>(`${this.config.gravityCmsApi}/cms/app-templates?pageSize=100`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getTicketLayouts(params?: Object): Observable<Array<Object>> {
        return this.http.get<Array<Object>>(`${this.config.gravityCmsApi}/cms/ticket-layouts`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getTicketLayout(id: string | number, params?: Object): Observable<TicketLayoutResponse> {
        return this.http.get<TicketLayoutResponse>(`${this.config.gravityCmsApi}/cms/ticket-layouts/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getTicketLayoutTemplates(params?: Object): Observable<TicketLayoutTemplatesResponse> {
        return this.http.get<TicketLayoutTemplatesResponse>(`${this.config.gravityCmsApi}/cms/ticket-layout-templates`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getAllTicketLayoutTemplates(params: Object = {}) {
        const extendedParams = {...params, pageSize: Number.MAX_SAFE_INTEGER};
        return this.getTicketLayoutTemplates(extendedParams);
    }

    getTicketLayoutTemplate(id: string | number, params?: Object): Observable<Object> {
        return this.http.get(`${this.config.gravityCmsApi}/cms/ticket-layout-templates/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    createTicketFragment(data: Object): Observable<any> {
        return this.http.post(`${this.config.gravityCmsApi}/cms/ticket-fragments`, data);
    }

    getTicketFragments(params?: Object): Observable<TicketFragmentsResponse | TicketFragmentsAppTemplateExtendedResponse> {
        return this.http.get<TicketFragmentsResponse | TicketFragmentsAppTemplateExtendedResponse>
        (`${this.config.gravityCmsApi}/cms/ticket-fragments`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getAllTicketFragments(params: Object = {}): Observable<TicketFragmentsResponse | TicketFragmentsAppTemplateExtendedResponse> {
        const extendedParams = {...params, pageSize: Number.MAX_SAFE_INTEGER};
        return this.getTicketFragments(extendedParams);
    }

    getTicketFragment(id: string | number, params?: Object): Observable<Object> {
        return this.http.get(`${this.config.gravityCmsApi}/cms/ticket-fragments/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    editTicketFragment(id: number, fragmentData: Object): Observable<Object> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/ticket-fragments/${id}`,
            fragmentData
        );
    }

    getTicketFragmentHistory(fragmentId: number, currentPage?: number, pageSize?: number): Observable<Object> {
        return this.http.get(`${this.config.gravityCmsApi}/cms/ticket-fragments/${fragmentId}/history`,
        {
            params: CmsObjectHelper.objToSearchParams({
                currentPage: currentPage,
                pageSize: pageSize
            })
        });
    }

    rollbackTicketFragment(fragmentId: number, historyId: number): Observable<Object> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/ticket-fragments/${fragmentId}/history/${historyId}/rollback`, {});
    }
}

export interface AppTemplate {
    id: number;
    name: string;
    title: string;
}

export enum TicketBlockTypes {
    draft = 'draft',
    fragment = 'fragment',
}

export enum TicketLayoutActions {
    addBlock = 'add-ticket-block'
}

export interface TicketFragmentBlock {
    type: TicketBlockTypes.fragment,
    id: string,
}

export interface TicketDraftBlock {
    type: TicketBlockTypes.draft,
    content: string,
}

export type TicketBlock = TicketFragmentBlock | TicketDraftBlock;

export type TicketBlocks = Array<TicketBlock>;

export interface TicketLayout {
    active: 1 | 0,
    appId: number,
    blocks: TicketBlocks,
    createdAt: string,
    customData: any,
    id: number,
    name: string,
    template: string,
    ticketTemplate: number,
    title: string,
    updatedAt: string,
}

export interface TicketLayoutResponse {
    ticketLayout: TicketLayout,
}

export type TicketLayouts = Array<TicketLayout>;

export interface TicketLayoutsResponse {
    ticketLayouts: TicketLayouts,
}

export interface TicketLayoutTemplate {
    active: 1 | 0,
    appTemplate: any,
    blocks: TicketBlocks,
    createdA: string,
    customData: any,
    id: number,
    name: string,
    template: string,
    title: string,
    updatedAt: string,
}

export type TicketLayoutTemplates = Array<TicketLayoutTemplate>;

export interface TicketLayoutTemplatesResponse {
    ticketLayoutTemplates: TicketLayoutTemplates,
}

export interface TicketLayoutTemplateAppTemplateExtended extends TicketLayoutTemplate {
    appTemplate: AppTemplate,
}

export interface TicketLayoutTemplateAppTemplateExtendedResponse {
    ticketLayoutTemplate: TicketLayoutTemplateAppTemplateExtended,
}

export interface TicketFragment {
    active: 1 | 0,
    appTemplate: any,
    content: string,
    createdAt: string,
    customData: any,
    id: number,
    name: string,
    title: string,
    updatedAt: string,
}

export type TicketFragments = Array<TicketFragment>

export interface TicketFragmentAppTemplateExtended extends TicketFragment {
    appTemplate: AppTemplate,
}

export interface TicketFragmentsResponse {
    ticketFragments: Array<TicketFragment>,
}

export interface TicketFragmentsAppTemplateExtendedResponse {
    ticketFragments: Array<TicketFragmentAppTemplateExtended>,
}

export interface CmsFragmentHistory {
    id: number;
    active: boolean;
    appTemplateId: number;
    content: string;
    createdAt: string;
    updatedAt: string;
    customData: any;
    name: string;
    ticketFragmentId: number;
    title: string;
    user: any;
    collapsed: boolean;
}

import { Component } from '@angular/core';

import { CmsModalService } from '../../core/modal.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import {CmsAppSettingsArchiveService} from '../app-settings-archive.service'
import {finalize} from "rxjs/operators";

@Component({
    selector: 'cms-unarchive-setting',
    templateUrl: 'unarchive-setting.component.html',
    providers: [CmsAppSettingsArchiveService]
})
export class CmsUnarchiveSettingComponent {
    data: number;
    submitDisabled = false;

    constructor(
        private modalService: CmsModalService,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService,
        private appSettingsArchiveService: CmsAppSettingsArchiveService
    ) {}

    unarchiveSetting() {
        this.submitDisabled = true;
        this.appSettingsArchiveService.unarchiveSetting(this.data).pipe(
            finalize(() => {
                this.submitDisabled = false;
            })
        ).subscribe(
            () => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_setting_unarchive_success')
                });
                this.closePopup();
                this.notifyService.notify('applicationSettingUnarchive');
            },
            (err: any) => {
                this.notificationsService.showError(err);
            }
        );
    }

    closePopup() {
        this.modalService.close('unarchive-setting');
    }
}

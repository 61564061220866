import {Component, Input, EventEmitter, Output} from '@angular/core';
import {Locale} from '../../../common.service';

@Component({
    selector: 'cms-table-languages-cell',
    templateUrl: 'table-cell-languages.component.html'
})

export class CmsTableCellLanguagesComponent {
    @Input() languages: Array<Locale> = [];
    @Input() row: any;
    @Output() languageAction = new EventEmitter<Object>();
    opened = false;

    onLanguageSelect(language) {
        this.close();
        this.languageAction.emit({
            language: language,
            row: this.row
        });
    }

    close() {
        this.opened = false;
    }
}

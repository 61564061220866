
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SITE_CONFIG } from '../core/site-config.service';
import { Response } from '@angular/http';
import {Pagination} from '../../types';
import {ISiteConfig} from '../core/site-config.interface';
import {CmsObjectHelper} from './object-helper.service';
import {extend} from 'lodash';
import {Observable} from 'rxjs';

export interface Company {
    id: number;
    name: string;
    title: string;
}

export interface Locale {
    id: number;
    name: string;
    title: string;
}

export interface LocaleParams {
    pageSize?: number;
}

export interface LocalesResponse {
    locales: Array<Locale>;
    pagination: Pagination;
}

export const NameValidationPattern = {
    pattern: '[a-zA-Z0-9\_\-]*',
    description: 'A-Z a-z 0-9 _ -'
};

export const KeyValidationPattern = {
    pattern: '[A-Za-z0-9\.\_\-]+',
    description: 'A-Z a-z 0-9 . _ -'
};

@Injectable()
export class CmsCommonService {
    constructor(
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    getLocales(params?: LocaleParams): Observable<{locales: Array<Locale>}> {
        const queryParams = extend({}, {pageSize: 1000}, params);

        return this.http.get<{locales: Array<Locale>}>(
            `${this.config.gravityCmsApi}/cms/locales`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    getCompanies(params?: Object): Observable<{companies: Array<Company>}> {
        return this.http.get<{companies: Array<Company>}>(
            `${this.config.gravityCmsApi}/cms/companies`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }
}

<div class="g-dropdown g-dropdown-io"
     [ngClass]="{active: selectOpened, filled: selected && selected[displayKey], disabled: disabled}"
     (click)="open()"
     (focus)="open()"
     (focusout)="close();resetSearch()"
     (cmsClickOutside)="close()"
     [attachOutsideOnClick]="selectOpened">
    <div class="g-dropdown-label">{{title}}</div>
    <input class="g-dropdown-input"
           [placeholder]="placeholder"
           type="text"
           [(ngModel)]="searchTerm"
           (ngModelChange)="filterItems()"
           (keydown.backspace)="selected && clearSelectionIfNeeded($event);"
           (keyup)="!selectOpened && open()"
           #searchTermEl
           tabindex="0">
    <i class="g-dropdown-droparrow g-icon g-icon-arrow-down-d"></i>
    <div class="g-dropdown-droplist" cmsIscroll>
        <div class="droplist-scrolling">
            <ng-content></ng-content>
            <div *ngIf="nullable"
                 class="g-droplist-dropoption"
                 (click)="clearSelection($event)">
                <span>-- Not selected --</span>
                <i class="g-droplist-dropoption-icon g-icon g-icon-check-d"></i>
            </div>
            <div class="g-droplist-dropoption"
                 [ngClass]="{active: option[uniqueKey] == (selected && selected[uniqueKey])}"
                 *ngFor="let option of options; let index = index"
                 (click)="onSelectOption(option, $event)">
                <span>{{option[displayKey]}}</span>
                <i class="g-droplist-dropoption-icon g-icon g-icon-check-d"></i>
                <i *ngIf="option.icon"
                   class="g-droplist-dropoption-icon-desc"
                   [ngClass]="option.icon">
                </i>
            </div>
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';

@Injectable()
export class CmsLoaderService {
    showLoader = false;
    private requestCount = 0;

    addRequest() {
        this.requestCount++;
        this.showLoader = true;
    }

    removeRequest() {
        this.requestCount--;
        if (!this.requestCount)  {
            this.showLoader = false;
        }
    }
}

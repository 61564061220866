import {Pipe, ChangeDetectorRef, PipeTransform, OnDestroy, NgZone} from '@angular/core';
import {UserService} from '../../core/user.service';
declare var moment: any;

@Pipe({name: 'timeAgo', pure: false})
export class CmsTimeAgoPipe implements PipeTransform, OnDestroy {
    private currentTimer: number;

    private lastTime: Number;
    private lastValue;
    private lastText: string;

    constructor(
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private userService: UserService
        ) {
        if (moment.locale() !== this.userService.momentLocale) {
            moment.locale(this.userService.momentLocale);
        }

    }

    transform(value): string {
        if (this.hasChanged(value)) {
            this.lastTime = this.getTime(value);
            this.lastValue = value;
            this.removeTimer();
            this.createTimer();
            this.lastText = moment(value).from(moment());

        } else if (!this.currentTimer) {
            this.createTimer();
        }

        return this.lastText;
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }


    private createTimer() {
        if (this.currentTimer) {
            return;
        }

        const momentInstance = moment(this.lastValue);

        const timeToUpdate = this.getSecondsUntilUpdate(momentInstance) * 1000;
        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.lastText = moment(this.lastValue).from(moment());

                    this.currentTimer = null;
                    this.ngZone.run(() => this.cdRef.markForCheck());
                }, timeToUpdate);
            }
        });
    }


    private removeTimer() {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }

    private getSecondsUntilUpdate(momentInstance) {
        const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }

    private hasChanged(value) {
        return this.getTime(value) !== this.lastTime;
    }

    private getTime(value) {
        if (moment.isDate(value)) {
            return value.getTime();
        } else if (moment.isMoment(value)) {
            return value.valueOf();
        } else {
            return moment(value).valueOf();
        }
    }
}

import {Component, ComponentFactoryResolver, HostBinding, ViewChild, ViewContainerRef} from '@angular/core';
import {isEmpty} from 'lodash';
import {CmsModalService, ModalConfigAction} from '../../core/modal.service';

@Component({
    selector: 'cms-modal',
    template: '<div #cmsModal></div>'
})

export class CmsModalComponent {
    @ViewChild('cmsModal', {read: ViewContainerRef, static: false}) cmsModal: ViewContainerRef;
    @HostBinding('class') modalOpen: String = '';

    modals: Object = {};

    constructor(private modalService: CmsModalService,
                private componentFactoryResolver: ComponentFactoryResolver) {
        modalService.modal.subscribe(
            (modalConfig) => {
                this.doModalAction(modalConfig);
            }
        );
    }

    private doModalAction(modalConfig: ModalConfigAction): void {
        if (modalConfig.action === 'open') {
            this.openModal(modalConfig.id, modalConfig.component, modalConfig.data);
        }

        if (modalConfig.action === 'close') {
            this.closeModal(modalConfig.id);
        }
    }

    private openModal(id: string, component: any, data?: any) {
        if (this.modals[id]) {
            return;
        }

        const factory = this.componentFactoryResolver.resolveComponentFactory(component);

        this.modals[id] = {
            ref: this.cmsModal.createComponent(factory)
        };

        if (data) {
            this.modals[id].ref.instance.data = data;
        }

        this.modals[id].ref.changeDetectorRef.detectChanges();
        this.modalOpen = 'popup-overlay modal-view';
    }

    private closeModal(id: string) {
        if (this.modals[id]) {
            this.modals[id].ref.destroy();
            delete this.modals[id];
        }

        if (isEmpty(this.modals)) {
            this.modalOpen = '';
        }
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../click-outside/click-outside.directive";
import * as i3 from "./notification.component";
var styles_CmsNotificationComponent = [];
var RenderType_CmsNotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsNotificationComponent, data: {} });
export { RenderType_CmsNotificationComponent as RenderType_CmsNotificationComponent };
function View_CmsNotificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "g-alert"]], null, [[null, "cmsClickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cmsClickOutside" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "g-alert-earth": 0, "g-alert-mars": 1, "g-alert-uranus": 2 }), i0.ɵdid(4, 671744, null, 0, i2.CmsClickOutsideDirective, [i0.ElementRef, i1.DOCUMENT], null, { cmsClickOutside: "cmsClickOutside" }), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "g-alert-icon g-icon g-icon-close-a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "g-alert-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "g-alert"; var currVal_1 = _ck(_v, 3, 0, (_co.notification.type === "action"), (_co.notification.type === "error"), (_co.notification.type === "confirmation")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_CmsNotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsNotificationComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notification && _co.notificationShown); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CmsNotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-notification", [], null, null, null, View_CmsNotificationComponent_0, RenderType_CmsNotificationComponent)), i0.ɵdid(1, 573440, null, 0, i3.CmsNotificationComponent, [], null, null)], null, null); }
var CmsNotificationComponentNgFactory = i0.ɵccf("cms-notification", i3.CmsNotificationComponent, View_CmsNotificationComponent_Host_0, { notification: "notification" }, {}, []);
export { CmsNotificationComponentNgFactory as CmsNotificationComponentNgFactory };

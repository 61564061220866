import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {Filter} from './filter.interface';
import {each, isEqual} from 'lodash';
export interface SelectedFilterValues {
    [key: string]: any;
}

@Injectable()
export class CmsFilterService {
    private selectedFilterSource = new Subject<Object>();

    filterChanged$ = this.selectedFilterSource.asObservable();

    private _filters: Array<Object> = [];
    selectedValues: SelectedFilterValues = {};
    selectedItems: any = {};

    constructor(private currentRoute: ActivatedRoute) {}

    setFilters(filters) {
        this._filters = filters;
        this.setSelected();
    }

    setSelected() {
        this.setSelectedValues();
        this.setSelectedItems();
    }

    setSelectedValues() {
        if (!this._filters) {
            return false;
        }
        this._filters.forEach((filter: Filter) => {
            if (this.currentRoute.snapshot.queryParams[filter.id]) {
                switch (filter.selectionValueType) {
                    case 'number':
                        this.selectedValues[filter.id] = Number(this.currentRoute.snapshot.queryParams[filter.id]);
                        break;
                    default:
                        this.selectedValues[filter.id] = this.currentRoute.snapshot.queryParams[filter.id];
                }
            } else {
                this.selectedValues[filter.id] = null;
            }
        });
    }

    setSelectedItems() {
        if (!this._filters) {
            return false;
        }
        this._filters.forEach((filter: Filter) => {
            const selectedValueExist = Object.keys(this.selectedValues).find((key: string) => {
                return key === filter.id;
            });

            if (selectedValueExist) {
                this.selectedItems[filter.id] = filter.items.find((item) => {
                    return item[filter.selectionKey] === this.selectedValues[filter.id];
                });
            } else {
                this.selectedItems[filter.id] = null;
            }
        });
    }

    setSelectedValue(selectedValue: any) {
        Object.assign(this.selectedValues, selectedValue);
    }

    clearSingleFilter(filter: Filter) {
        this.selectedValues[filter.id] = null;
        this.selectedItems[filter.id] = null;
        this.selectedFilterSource.next(this.selectedValues);
    }

    clearAllFilters() {
        Object.keys(this.selectedValues).forEach(function (key) {
            this.selectedValues[key] = null;
        }, this);

        Object.keys(this.selectedItems).forEach(function (key) {
            this.selectedItems[key] = null;
        }, this);

        this.selectedFilterSource.next(this.selectedValues);
    }

    selectItem(item: any, filter: Filter) {
        if (isEqual(this.selectedItems[filter.id], item)) {
            return;
        }

        const selectedValue = {
            [filter.id]: item[filter.selectionKey]
        };

        this.selectedItems[filter.id] = item;
        this.setSelectedValue(selectedValue);

        if (filter.childFilters) {
            this.resetChildFilters(filter.childFilters);
            this.selectedFilterSource.next(this.selectedValues);
        } else {
            this.selectedFilterSource.next(selectedValue);
        }
    }

    resetChildFilters(childFilters) {
        each(childFilters, (filterKey) => {
            this.selectedValues[filterKey] = null;
            this.selectedItems[filterKey] = null;
        });
    }
}

<div class="popup g-popup"
     (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">{{'pages_template_edit' | translate}}</div>
  </header>
  <div class="g-popup-body">
    <form class="popup-form"
          novalidate
          [formGroup]="pageTemplate"
          (ngSubmit)="onSubmit(pageTemplate)">
        <div class="form-body">
          <div class="g-row">
            <div class="g-col-sm-12">
              <div class="form-control">
                <div class="g-input g-input-io"
                     [ngClass]="{invalid: (pageTemplate.get('name').hasError('required')
                                    || pageTemplate.get('name').hasError('maxlength'))
                                    && !pageTemplate.get('name').pristine}">
                  <input type="text" class="g-input-input"
                         [placeholder]="'common_name' | translate"
                         formControlName="name">
                  <label class="g-input-label">
                    {{ 'common_name' | translate }}
                  </label>
                  <label class="g-input-validation">
                          <span *ngIf="pageTemplate.get('name').hasError('required')">
                              {{ 'common_validation_field_required' | translate }}
                          </span>
                    <span *ngIf="pageTemplate.get('name').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:nameValidationInterpolationParams}}
                    </span>
                    <span *ngIf="pageTemplate.get('name').hasError('pattern')">
                        {{'common_validation_valid_characters' | translate:nameValidationInterpolationParams}}
                    </span>
                  </label>
                </div>
              </div>
              <div class="form-control">
                <div class="g-input g-input-io"
                     [ngClass]="{invalid: (pageTemplate.get('title').hasError('required')
                       || pageTemplate.get('title').hasError('maxlength'))
                       && !pageTemplate.get('title').pristine}">
                  <input type="text"
                         class="g-input-input"
                         [placeholder]="'common_title' | translate"
                         formControlName="title">
                  <label class="g-input-label">
                    {{ 'common_title' | translate }}
                  </label>
                  <label class="g-input-validation">
                        <span *ngIf="pageTemplate.get('title').hasError('required')">
                            {{ 'common_validation_field_required' | translate }}
                        </span>
                    <span *ngIf="pageTemplate.get('title').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:titleValidationInterpolationParams}}
                      </span>
                  </label>
                </div>
              </div>
              <div class="form-control">
                <div class="g-input g-input-io"
                     [ngClass]="{invalid: (pageTemplate.get('subtitle').hasError('required')
                       || pageTemplate.get('subtitle').hasError('maxlength'))
                       && !pageTemplate.get('subtitle').pristine}">
                  <input type="text"
                         class="g-input-input"
                         [placeholder]="'common_subtitle' | translate"
                         formControlName="subtitle">
                  <label class="g-input-label">
                    {{ 'common_subtitle' | translate }}
                  </label>
                  <label class="g-input-validation">
                      <span *ngIf="pageTemplate.get('subtitle').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:subtitleValidationInterpolationParams}}
                      </span>
                  </label>
                </div>
              </div>
              <div class="form-control">
                <div class="g-input g-input-io"
                     [ngClass]="{invalid: pageTemplate.get('keywords').hasError('maxlength')
                       && !pageTemplate.get('keywords').pristine}">
                  <input type="text"
                         class="g-input-input"
                         [placeholder]="'common_keywords' | translate"
                         formControlName="keywords">
                  <label class="g-input-label">
                    {{ 'common_keywords' | translate }}
                  </label>
                  <label class="g-input-validation">
                      <span *ngIf="pageTemplate.get('keywords').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:keywordsValidationInterpolationParams}}
                      </span>
                  </label>
                </div>
              </div>
              <div class="form-control">
                <div class="g-input g-input-io"
                     [ngClass]="{filled: pageTemplate.get('customData').value,
                              invalid: pageTemplate.get('customData').hasError('jsonParseError')}">
                  <cms-json-editor
                          formControlName="customData">
                  </cms-json-editor>
                  <label class="g-input-validation"
                         *ngIf="pageTemplate.get('customData').hasError('jsonParseError')">
                    {{'common_validation_parse_error' | translate}}
                  </label>
                </div>
              </div>
              <div class="form-control">
                <cms-select formControlName="appTemplate"
                            [options]="appTemplates"
                            [title]="'applications_application_template' | translate"
                            [placeholder]="'applications_application_template' | translate"
                ></cms-select>
              </div>
              <p>{{'pages_layout' | translate}}</p>
              <div class="form-control form-control-layouts"
                   *ngIf="layouts">
                <label class="mr-1"
                       [attr.for]="layoutsData[i].id"
                       *ngFor="let layout of layouts.controls; let i=index">
                  <input type="checkbox"
                         [attr.id]="layoutsData[i].id"
                         [formControl]="layout"/>
                  <span class="custom-control-indicator cms-page-layout-{{layoutsData[i].name}}"
                        [ngClass]="{'checked': layout.value}">
                </span>
                </label>
              </div>
              <div class="form-control">
                <div class="g-toggle g-toggle-io">
                  <input class="g-toggle-input"
                         type="checkbox"
                         formControlName="active">
                  <label class="g-toggle-label">
                    {{ 'common_active' | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="form-footer">
        <div class="form-footer-buttons">
          <button type="submit"
                  class="form-footer-button g-button g-button-earth"
                  [disabled]="pageTemplate.invalid || submitDisabled">
                <span class="g-button-title">
                  {{'common_save' | translate}}
                </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

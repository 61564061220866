import { AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ResourcesPlugin } from './resources-plugin.service';
import { TinymceOptions } from './tinymce.config.interface';
var noop = function () {
};
var ɵ0 = noop;
var TinymceComponent = /** @class */ (function () {
    function TinymceComponent(resourcePlugin, zone, config) {
        var _this = this;
        this.resourcePlugin = resourcePlugin;
        this.zone = zone;
        this.config = config;
        this.toggleableDarkBody = false;
        this.elementId = 'tiny-' + Math.random().toString(36).substring(2);
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.darkBody = false;
        this.options = Object.assign(new TinymceOptions(), this.config);
        this.options.generic_plugin_data = this.resourcePlugin;
        this.options.selector = '#' + this.elementId;
        this.options.setup = function (editor) {
            _this.editor = editor;
            editor.on('change keyup', function () {
                _this.value = editor.getContent();
            });
            if (typeof _this.config.setup === 'function') {
                _this.config.setup(editor);
            }
        };
        this.options.init_instance_callback = function (editor) {
            if (editor && _this.value) {
                editor.setContent(_this.value);
            }
            if (typeof _this.config.init_instance_callback === 'function') {
                _this.config.init_instance_callback(editor);
            }
        };
        if (this.config.auto_focus) {
            this.options.auto_focus = this.elementId;
        }
    }
    TinymceComponent.prototype.ngAfterViewInit = function () {
        Object.assign(this.options, this.instanceConfig);
        if (this.options.baseURL) {
            tinymce.baseURL = this.options.baseURL;
        }
        tinymce.init(this.options);
    };
    TinymceComponent.prototype.ngOnDestroy = function () {
        tinymce.remove(this.editor);
    };
    TinymceComponent.prototype.toggleDarkBody = function () {
        this.darkBody = !this.darkBody;
        this.setTinyMceBodyColor();
    };
    TinymceComponent.prototype.setTinyMceBodyColor = function () {
        this.editor.getBody().style.backgroundColor = this.darkBody ? '#607d8b' : '#fff';
    };
    Object.defineProperty(TinymceComponent.prototype, "value", {
        // get accessor
        get: function () {
            return this.innerValue;
        },
        // set accessor including call the onchange callback
        set: function (v) {
            var _this = this;
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.zone.run(function () {
                    _this.onChangeCallback(v);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    // From ControlValueAccessor interface
    TinymceComponent.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            if (this.editor) {
                this.editor.setContent(value);
            }
        }
    };
    TinymceComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TinymceComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return TinymceComponent;
}());
export { TinymceComponent };
export { ɵ0 };

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()"
           class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{ 'list_edit_item' | translate }}
        </div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form"
              novalidate [formGroup]="form">
            <div class="form-body">
                <div *ngIf="form.get('listItemType').value === listItemType.resource && resourceSelected"
                     class="mb-1">
                    <div class="form-control">
                        <div class="g-input g-input-io"
                             [ngClass]="{invalid: form.get('title').invalid
                                                    && !form.get('title').pristine}">
                            <input type="text"
                                   class="g-input-input"
                                   [placeholder]="'common_title' | translate"
                                   formControlName="title">
                            <label class="g-input-label">
                                {{ 'common_title' | translate }}
                            </label>
                            <label class="g-input-validation">
                                <span *ngIf="form.get('title').hasError('required')">
                                    {{ 'common_validation_field_required' | translate }}
                                </span>
                                <span *ngIf="form.get('title').hasError('maxlength')">
                                    {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="form-control">
                        <div class="resource-item-preview p-0 mb-1"
                             *ngIf="form.get('resource').value">
                            <div class="image-preview"
                                 *ngIf="(form.get('resource')?.value?.mimeType | resourceFileType) === 'image'"
                                 [style.background-image]="'url(' + imageUrl + ')'">
                            </div>
                            <div class="file-preview"
                                 *ngIf="(form.get('resource')?.value?.mimeType | resourceFileType) === 'file'">
                                <div class="icon">
                                    <i class="g-icon g-icon-full-box"></i>
                                </div>
                            </div>
                            <div class="video-preview"
                                 *ngIf="(form.get('resource')?.value?.mimeType | resourceFileType) === 'video'">
                                <div class="icon">
                                    <i class="g-icon g-icon-play-a"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-control">
                        <button class="g-button g-button-earth"
                                (click)="openResourcePicker()">
                            <span class="g-button-title">
                              {{ 'list_repick_resource' | translate }}
                            </span>
                        </button>
                    </div>
                </div>
                <cms-article-picker *ngIf="form.get('listItemType').value === listItemType.article"
                                    [article]="form.get(listItemType.article).value"
                                    (onArticleSelect)="onArticlesSelect($event)">
                </cms-article-picker>
                <cms-resource-picker *ngIf="form.get('listItemType').value === listItemType.resource && !resourceSelected" class="mb-2"
                                     [options]="resourcePickerOptions"
                                     (onResourceSelect)="onResourceSelect($event)">
                </cms-resource-picker>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input"
                               type="checkbox"
                               formControlName="active">
                        <label class="g-toggle-label">
                            {{ 'common_active' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="button"
                            class="form-footer-button g-button g-button-earth"
                            [disabled]="form.invalid"
                            (click)="editListItem()">
                        <span class="g-button-title">
                          {{ 'common_finish_do' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

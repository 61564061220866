<div class="popup g-popup"
     (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()"
           class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'settings_edit_application_setting' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form"
              novalidate [formGroup]="form"
              (ngSubmit)="onSubmit(form)">
            <div class="form-body">
                <div class="form-control"
                     formGroupName="app">
                    <div class="g-input g-input-io">
                        <input type="text"
                               class="g-input-input"
                               [placeholder]="'applications_application' | translate"
                               formControlName="title">
                        <label class="g-input-label">
                            {{'applications_application' | translate}}
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: form.get('key').value,
                                    invalid: form.get('key').hasError('required') && !form.get('key').pristine}">
                        <input type="text"
                               class="g-input-input"
                               [placeholder]="'common_key' | translate"
                               formControlName="key">
                        <label class="g-input-label">
                            {{'common_key' | translate}}
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <cms-select formControlName="type"
                                [options]="settingTypes"
                                [title]="'common_type' | translate"
                                [placeholder]="'common_type' | translate"
                                (selectChanged)="onSelectChanged($event)">
                    </cms-select>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: form.get('value').value,
                                    invalid: form.get('value').invalid && !form.get('value').pristine}">
                        <ng-container *ngIf="form.value.type?.id !== 'object' &&
                                                 form.value.type?.id !== 'config'">
                            <input type="text"
                                   class="g-input-input"
                                   [placeholder]="'common_value' | translate"
                                   formControlName="value">
                            <label class="g-input-label">
                                {{'common_value' | translate}}
                            </label>
                        </ng-container>
                        <ng-container *ngIf="form.value.type?.id === 'object' ||
                                                 form.value.type?.id === 'config'">
                            <cms-json-editor formControlName="value">
                            </cms-json-editor>
                            <label class="g-input-validation"
                                   *ngIf="form.get('value').hasError('jsonParseError')">
                                {{'common_validation_parse_error' | translate}}
                            </label>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit"
                            class="form-footer-button g-button g-button-earth"
                            [disabled]="form.invalid || !valueChanged || submitDisabled">
                        <span class="g-button-title">
                            {{ 'common_save' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{'articles_translate_article_popup_title' | translate:popupTitleInterpolationParams}}
        </div>
    </header>
    <div class="g-popup-body">
        <div class="g-row">
            <form class="g-col-lg-6 popup-form" novalidate [formGroup]="article">
                <div class="form-body">
                    <div class="form-control">
                        <div class="g-input g-input-io">
                            <input type="text" class="g-input-input" formControlName="title">
                            <label class="g-input-label">
                                {{fromLanguageTitle}} {{ 'common_title' | translate | lowercase }}
                            </label>
                        </div>
                    </div>
                    <div class="form-control">
                        <div class="g-input g-input-io">
                                <textarea id="summary"
                                          class="g-input-textarea ta-2"
                                          formControlName="summary">
                                </textarea>
                            <label for="summary" class="g-input-label">
                                {{'articles_article_summary' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-control">
                        <cms-tinymce
                            formControlName="content"
                            [instanceConfig]="{readonly: true, height: '424', toolbar: false, resize: false}">
                        </cms-tinymce>
                    </div>
                </div>
            </form>

            <form class="g-col-lg-6 popup-form" novalidate [formGroup]="translatedArticle"
                  (ngSubmit)="onSubmit(translatedArticle)">
                <div class="form-body">
                    <div class="form-control">
                        <div class="g-input g-input-io"
                             [ngClass]="{invalid: (translatedArticle.get('title').hasError('required') || translatedArticle.get('title').hasError('maxlength')) && !translatedArticle.get('title').pristine}">
                            <input type="text" class="g-input-input"
                                   [placeholder]="toLanguageTitle + ' ' + ('common_title' | translate| lowercase)" formControlName="title">
                            <label class="g-input-label">
                                {{toLanguageTitle}} {{ 'common_title' | translate | lowercase }}
                            </label>
                            <label class="g-input-validation">
                                <span *ngIf="translatedArticle.get('title').hasError('required')">
                                    {{ 'common_validation_field_required' | translate }}
                                </span>
                                <span *ngIf="translatedArticle.get('title').hasError('maxlength')">
                                    {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="form-control">
                        <div class="g-input g-input-io"
                             [ngClass]="{invalid: (translatedArticle.get('summary').hasError('maxlength') && !translatedArticle.get('summary').pristine)}">
                                <textarea id="translated-summary"
                                          class="g-input-textarea ta-2"
                                          formControlName="summary">
                                </textarea>
                            <label for="translated-summary" class="g-input-label">
                                {{'articles_article_summary' | translate}}
                            </label>
                            <label class="g-input-validation">
                                    <span *ngIf="translatedArticle.get('summary').hasError('maxlength')">
                                        {{'common_validation_maximum_field_length' | translate:summaryValidationInterpolationParams}}
                                    </span>
                            </label>
                        </div>
                    </div>
                    <div class="form-control">
                        <cms-tinymce
                            formControlName="content"
                            [instanceConfig]="{height: '364', resize: false}">
                        </cms-tinymce>
                    </div>
                </div>
            </form>
        </div>
        <div class="form-footer">
            <div class="form-footer-buttons">
                <button type="submit" class="form-footer-button g-button g-button-earth"
                        [disabled]="translatedArticle.invalid || !categoryTranslated || !fieldsChanged"
                        (click)="$submit.next($event)">
                    <span class="g-button-title">{{ 'common_save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map} from 'lodash';
import {Article} from '../../articles/shared/articles.service';

import {CmsNotifyService} from '../../core/notify.service';
import {CmsModalService} from '../../core/modal.service';
import {UserService} from '../../core/user.service';
import {Resource} from '../../resources/shared/resources.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {LIST_ITEM_TYPE} from '../lists.service';

@Component({
    selector: 'cms-edit-list-item',
    templateUrl: './edit-list-item.component.html'
})
export class CmsEditListItemComponent implements OnInit {
    public form: FormGroup;
    public imageUrl = '';
    public listItemType = LIST_ITEM_TYPE;
    public titleValidationInterpolationParams;
    public resourceSelected: boolean;
    public resourcePickerOptions;
    @Input() data;
    constructor(
        private fb: FormBuilder,
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService,
        private userService: UserService,
        private translateService: CmsTranslateService,
    ) {
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.resourcePickerOptions = {
            mimeType: map(this.userService.data.modules.resources.allowedFileFormats, format => (format.mimeType))
        };
    }

    ngOnInit() {
        this.form = this.fb.group({
            title: [{value: this.data.title, disabled: true}],
            [this.data.listItemType]: [this.data[this.data.listItemType], [Validators.required]],
            active: [this.data.active],
            listItemType: this.data.listItemType
        });

        if (this.data.listItemType === this.listItemType.resource) {
            this.resourceSelected = true;
            this.imageUrl = this.data.resource['thumb-large-url'] || this.data.resource.url || '';
        }
    }

    onArticlesSelect({article}: {article: Article}) {
        this.form.patchValue({
            article: article,
            title: article.title,
        });
        this.resourceSelected = false;
    }

    onResourceSelect(data: {resource: Resource}) {
        this.form.patchValue({
            resource: data.resource,
            title: data.resource.title
        });
        this.imageUrl = data.resource['thumb-large-url'] || data.resource.url || '';
        this.resourceSelected = true;
    }

    openResourcePicker() {
        this.resourceSelected = false;
    }

    editListItem() {
        const listItemType = this.form.get('listItemType').value;

        this.notifyService.notify({
            type: 'edit-list-item',
            data: {
                active: this.form.get('active').value,
                title: this.form.get('title').value,
                [listItemType]: this.form.get(listItemType).value,
                listItemType: listItemType,
                itemIndex: this.data.itemIndex
            }
        });
        this.closePopup();
    }

    closePopup() {
        this.modalService.close('edit-list-item');
    }
}

import {Component, HostBinding, OnInit} from '@angular/core';
import { CmsModalService } from '../../core/modal.service';
import { CmsWidget } from '../shared/pages.service';
import { ConfigurePageWidgetArticleComponent } from '../configure-page-widget-article/configure-page-widget-article.component';
import { CmsNotifyService } from '../../core/notify.service';
import { cloneDeep, map, filter } from 'lodash';

@Component({
    selector: 'cms-add-page-widget',
    templateUrl: './add-page-widget.component.html'
})
export class AddPageWidgetComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    data: {widgets: Array<CmsWidget>, layoutSectionIndex: number};
    filteredWidgets;
    pageWidget: any = {};
    configuredWidgetId: number;

    private widgetComponents = {
        article: ConfigurePageWidgetArticleComponent,
        // Same until form fields for definition are created
        // requires article and category that have base translation
        'article_list': ConfigurePageWidgetArticleComponent
    };

    constructor(private modalService: CmsModalService,
                private notifyService: CmsNotifyService) {
        this.notifyService.change$.subscribe((item: {type: string, data: {widget: any}}) => {
            if (item && item.type === 'newWidgetConfigured') {
                this.pageWidget = item.data.widget;
                this.configuredWidgetId = item.data.widget.widgetId;
            }
        });
    }

    ngOnInit() {
        this.filteredWidgets = map(this.data.widgets, (widget) => {
            return widget;
        });
    }

    ngOnDestroy(){
        this.modalService.close('configure-page-widget');
    }

    configurePageWidget(widget) {
        if (widget.id === this.pageWidget.widgetId) {
            widget = this.pageWidget;
        }

        this.modalService.open({
            id: 'configure-page-widget',
            component: this.widgetComponents[widget.name],
            data: {widget: widget}
        });
    }

    filterWidgets(searchTerm) {
        this.filteredWidgets = filter(this.data.widgets, function (widget: CmsWidget) {
            const searchRegex = new RegExp(searchTerm, 'i');
            return widget.name.match(searchRegex) || widget.title.match(searchRegex);
        });
    }

    save() {
        this.notifyService.notify({
            type: 'addWidget',
            data: {
                widget: this.pageWidget,
                layoutSectionIndex: this.data.layoutSectionIndex
            }
        });
        this.closePopup();
    }

    closePopup() {
        this.modalService.close('add-page-widget');
    }
}

import {Pipe, PipeTransform} from '@angular/core';
import {RESOURCE_FILE_TYPES} from '../../resources/shared/resources.service';

@Pipe({
    name: 'resourceFileType'
})
export class ResourceFileTypePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }

        const imageType = /image.*/;
        const videoType = /video.*/;
        const isImage = value.match(imageType);
        const isVideo = value.match(videoType);

        if (isImage) {
            return RESOURCE_FILE_TYPES.image;
        } else if (isVideo) {
            return RESOURCE_FILE_TYPES.video;
        } else {
            return RESOURCE_FILE_TYPES.file;
        }
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { throttleTime } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CmsModalService } from '../../core/modal.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { TicketBlockTypes, TicketLayoutActions } from '../ticket-layout-creator.service';
var CmsAddTicketBlockComponent = /** @class */ (function () {
    function CmsAddTicketBlockComponent(fb, modalService, notifyService, translateService) {
        this.fb = fb;
        this.modalService = modalService;
        this.notifyService = notifyService;
        this.translateService = translateService;
        this.types = [{
                id: TicketBlockTypes.draft,
                title: this.translateService.translate('ticket_layouts_block')
            }, {
                id: TicketBlockTypes.fragment,
                title: this.translateService.translate('ticket_layouts_fragment')
            }];
        this.ticketBlockTypes = TicketBlockTypes;
        this.$submit = new Subject();
    }
    CmsAddTicketBlockComponent.prototype.ngOnInit = function () {
        this.setSubmitListener();
        this.form = this.fb.group({
            type: ['', [Validators.required]],
            fragment: [''],
            draft: [{ content: '' }]
        });
    };
    CmsAddTicketBlockComponent.prototype.addBlock = function (formValues) {
        this.notifyService.notify({
            type: TicketLayoutActions.addBlock,
            data: {
                block: __assign({}, formValues[formValues.type.id], { type: formValues.type.id }),
            },
        });
        this.closePopup();
    };
    CmsAddTicketBlockComponent.prototype.onTypeChange = function (type) {
        this.setFieldValidation(type.id);
    };
    CmsAddTicketBlockComponent.prototype.setFieldValidation = function (selectedBlockType) {
        if (selectedBlockType === TicketBlockTypes.fragment) {
            this.form.controls['fragment'].setValidators([Validators.required]);
        }
        else {
            this.form.controls['fragment'].clearValidators();
        }
    };
    CmsAddTicketBlockComponent.prototype.closePopup = function () {
        this.modalService.close(TicketLayoutActions.addBlock);
    };
    CmsAddTicketBlockComponent.prototype.setSubmitListener = function () {
        var _this = this;
        this.$submit.pipe(throttleTime(500))
            .subscribe(function () {
            _this.addBlock(_this.form.value);
        });
    };
    return CmsAddTicketBlockComponent;
}());
export { CmsAddTicketBlockComponent };

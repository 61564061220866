
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../../core/site-config.service';
import {Observable} from 'rxjs';
import {CmsObjectHelper} from '../../shared/object-helper.service';
import {Pagination} from '../../../types';
import {Company, Locale, LocalesResponse} from '../../shared/common.service';
import {ISiteConfig} from '../../core/site-config.interface';
import {App} from '../../apps/shared/apps.service';

@Injectable()
export class CmsArticlesService {

    constructor(
        private  http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    _toJson = (res: Response) => {
        const data = res.json();
        return data || {};
    };

    getArticleCategories (params?: Object): Observable<CategoriesResponse> {
        return this.http.get<CategoriesResponse>(
            `${this.config.gravityCmsApi}/cms/article-categories`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getAllArticleCategories(params: Object) {
        const allCategoriesParams = {...params, pageSize: Number.MAX_SAFE_INTEGER};

        return this.getArticleCategories(allCategoriesParams);
    }

    getArticleCategory (id: string, params?: Object): Observable<CategoryResponse> {
        return this.http.get<CategoryResponse>(
            `${this.config.gravityCmsApi}/cms/article-categories/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    createCategory (categoryFormModel: CategoryFormModel): Observable<Object[]> {
        const application = categoryFormModel.application && categoryFormModel.application.id;
        const company = categoryFormModel.company && categoryFormModel.company.id;
        const category: CategoryModel = {
            name: categoryFormModel.name,
            title: categoryFormModel.title,
            active: categoryFormModel.active,
            locale: categoryFormModel.locale.id,
            parent: categoryFormModel.parent && categoryFormModel.parent.id,
        };

        if (application) {
            category.app = application;
        } else {
            category.company = company;
        }

        return this.http.post<Object[]>(
            `${this.config.gravityCmsApi}/cms/article-categories`,
            category
        );
    }

    editArticleCategory (categoryFormModel: EditCategoryFormModel, categoryId: string): Observable<Object[]> {
        const category: CategoryModel = {
            name: categoryFormModel.name,
            title: categoryFormModel.title,
            active: categoryFormModel.active,
            locale: categoryFormModel.locale.id,
            parent: categoryFormModel.parent && categoryFormModel.parent.id,
            company: categoryFormModel.company,
            app: categoryFormModel.app
        };

        return this.http.put<Object[]>(
            `${this.config.gravityCmsApi}/cms/article-categories/${categoryId}`,
            category
        );
    }

    deleteCategory (id: number): Observable<Object[]> {
        return this.http.delete<Object[]>(
            `${this.config.gravityCmsApi}/cms/article-categories/${id}`
        );

    }

    getArticles (params?: Object): Observable<ArticlesResponse> {
        const queryParams = Object.assign({}, {extend: 'status,locale,company,category'}, params);

        return this.http.get<ArticlesResponse>(
            `${this.config.gravityCmsApi}/cms/articles`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    getAllArticles (params?: Object): Observable<ArticlesResponse> {
        const allArticlesParams = {...params, pageSize: Number.MAX_SAFE_INTEGER};

        return this.getArticles(allArticlesParams);
    }

    getArticle(id: string, params?: Object): Observable<ArticleResponse> {
        return this.http.get<ArticleResponse>(
            `${this.config.gravityCmsApi}/cms/articles/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    createArticle (article: Object): Observable<Object[]> {
        return this.http.post<Object[]>(
            `${this.config.gravityCmsApi}/cms/articles`,
            article
        );
    }
    editArticle (article: Object, articleId: string): Observable<Object[]> {
        return this.http.put<Object[]>(
            `${this.config.gravityCmsApi}/cms/articles/${articleId}`,
            article
        );
    }

    getArticleProviders (params?: Object): Observable<ProvidersResponse> {
        return this.http.get<ProvidersResponse>(
            `${this.config.gravityCmsApi}/cms/article-providers`,
            {params: CmsObjectHelper.objToSearchParams(params), }
        );
    }

    getArticleStatuses (params?: Object): Observable<ArticleStatusesResponse> {
        return this.http.get<ArticleStatusesResponse>(
            `${this.config.gravityCmsApi}/cms/article-statuses`,
            {params: CmsObjectHelper.objToSearchParams(params), }
        );
    }

}

export const MODULE_ID = 'articles';

export const CATEGORY_TYPE = {
    company: 'company',
    application: 'application'
};

export interface Category {
    id: number;
    name: string;
    title: string;
    parent:  number;
    company?: Company | number;
    app?: App | number;
    active: boolean;
    locale: Locale | number;
}

export interface CategoryModel {
    name: string;
    title: string;
    active: boolean;
    parent: number;
    locale: number;
    company?: number;
    app?: number
}

export interface CategoryFormModel {
    name: string;
    title: string;
    active: boolean;
    parent: Category;
    locale: Locale;
    application?: App;
    company?: Company;
}

export interface EditCategoryFormModel {
    name: string;
    title: string;
    active: boolean;
    parent: Category;
    locale: Locale;
    company?: number;
    app?: number;
}

export interface CategoryResponse {
    category: Category;
}

export interface CategoriesResponse {
    categories: Array<Category>;
    pagination: Pagination;
}

export interface ArticleStatus {
    id: number;
    name: string;
    title: string;
}

export interface ArticleStatusesResponse {
    articleStatuses: Array<ArticleStatus>;
    pagination: Pagination;
}

export interface Article {
    app: any;
    category: number | Category;
    company: number;
    createdAt?: string;
    id?: number,
    updatedAt?: string;
    locale: number;
    name: string;
    provider: number;
    source?: string;
    status: number | ArticleStatus;
    title: string;
    summary?: string;
    featuredImage: string;
    content: string;
}

export interface ArticleResponse {
    article: Article;
}

export interface ArticlesResponse {
    articles: Array<Article>;
    pagination: Pagination;
}

export interface ArticleProvider {
    id: number;
    name: string;
    title: string;
}

export interface ProvidersResponse {
    providers: Array<ArticleProvider>;
    pagination: Pagination;
}

export interface CategoryType {
    id: string,
    title: string
}

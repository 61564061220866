<div class="g-row mb-1">
  <div class="g-col-xs-12">
    <cms-select [options]="categories"
                [title]="'articles_categories' | translate"
                [(ngModel)]="selectedCategory"
                [placeholder]="'articles_categories' | translate"
                (selectChanged)="onCategorySelect($event)"
    ></cms-select>
  </div>
</div>
<div class="g-row mb-1">
  <div class="g-col-xs-12">
    <cms-select *ngIf="articles?.length"
                [options]="articles"
                [title]="'articles_articles' | translate"
                (selectChanged)="articleSelected($event)"
                [placeholder]="'articles_articles' | translate"
                [(ngModel)]="selectedArticle"
    ></cms-select>
  </div>
</div>
<div class="box-shadow-container no-table-data-box mb-1"
     *ngIf="articles && !articles.length">
  <i class="no-table-data-icon g-icon g-icon-info"></i>
  <span class="no-table-data-message">
    {{ 'common_no_data' | translate }}
  </span>
</div>


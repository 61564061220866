import {Component, OnInit, OnDestroy, HostBinding} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {map, findIndex, sortBy} from 'lodash';
import {FileUploader} from '@nsftx/ng2-file-upload/dist';

import {CmsResources, Resource} from '../shared/resources.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {UserService} from '../../core/user.service';

@Component({
    selector: 'cms-resources-upload',
    templateUrl: 'resources-upload.component.html'
})

export class CmsResourcesUploadComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'g-container-fluid';
    public uploader: FileUploader;
    public containers: Array<Object> = [];
    public fileOverZone = false;
    public userCanEditPublicContainers = false;
    resources;
    titleValidationInterpolationParams;

    constructor(
        private resourcesService: CmsResources,
        private router: Router,
        private notifyService: CmsNotifyService,
        private notificationsService: CmsNotificationsService,
        private translateService: CmsTranslateService,
        private userService: UserService,
        private fb: FormBuilder,
    ) {
        this.uploader = this.resourcesService.uploader;
        this.setUploadAddFail();
        this.setUploadCompletion();
        this.setUploadError();
        this.userCanEditPublicContainers = userService.data.modules['resources']['specialPermissions'].canEditPublicResourceContainers;
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.resources = this.fb.group({
            items: this.fb.array([])
        });

        this.uploader.onAfterAddingFile = file => {
            const length = this.resources.controls.items.controls.push(this.fb.control(file.title));
            this.resources.controls.items.at(length - 1).setValidators([Validators.required, Validators.maxLength(80)]);
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }

    public fileOver(e: any): void {
        this.fileOverZone = e;
    }

    public uploadFiles() {
        this.resourcesService.setAuthToken();
        this.uploader.uploadAll();
    }

    public selectContainer(e: Resource) {
        if (e) {
            this.resourcesService.setUploadUrl(e.id);
        } else {
            this.resourcesService.setUploadUrl('');
        }
    }

    public closePopup() {
        this.resourcesService.uploader.cancelAll();
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    ngOnInit() {
        this.resourcesService.getContainers({
            extend: 'company',
            active: 1,
            pageSize: 1000,
            type: this.userCanEditPublicContainers ? null : 'private'
        })
            .subscribe(
                (data) => {
                    if (!data.containers.length) {
                        return;
                    }
                    const containers = map(data.containers, (c, idx) => {
                        const index = findIndex(data.containers, (el, elIdx) => {
                            return el.title === c.title && idx !== elIdx;
                        });
                        return {
                            id: c.id,
                            name: c.name,
                            title: index !== -1 && c.company ? `${c.title} - ${c.company.title}` : c.title,
                            public: c.public,
                            icon: c.public ? 'g-icon g-icon-globe' : null
                        };
                    });

                    this.containers = sortBy(containers, ['public', 'title']);
                }
            );
    }

    ngOnDestroy() {
        this.uploader.clearQueue();
        this.resourcesService.setUploadUrl(null);
    }

    private setUploadError() {
        this.uploader.onErrorItem = (item, response) => {
            let errorMessage = this.translateService.translate('resources_upload_error');
            if (response) {
                response = JSON.parse(response);
                errorMessage = this.translateService.translate(
                    'resources_file_upload_error',
                    {
                        fileName: item.file.name
                    }
                ) + ` ${response.message}`;
            }

            this.notificationsService.show({
                type: 'error',
                content: errorMessage
            });
        };
    }

    private setUploadCompletion() {
        this.uploader.onCompleteAll = () => {
            const failedUploads = this.uploader.getFailedUploads();
            const nonUploaded = this.uploader.getNotUploadedItems();
            if (!failedUploads.length && !nonUploaded.length) {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('resources_file_upload_success')
                });
                this.closePopup();
            } else if (nonUploaded.length) {
                this.notificationsService.show({
                    type: 'error',
                    content: this.translateService.translate('resources_file_upload_items_cancelled')
                });
            }
            this.notifyService.notify({type: 'resources'});
        };
    }

    private setUploadAddFail() {
        this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
            if (filter.name === 'mimeType') {
                this.notificationsService.show({
                    type: 'error',
                    content: this.translateService.translate(
                        'resources_file_type_not_allowed',
                        {
                            name: item.name,
                            type: item.type
                        }
                    )
                });
            } else if (filter.name === 'fileSize') {
                this.notificationsService.show({
                    type: 'error',
                    content: this.translateService.translate(
                        'resources_file_too_large',
                        {
                            name: item.name,
                            maximumSize: '10 MB'
                        }
                    )
                });
            } else if (filter.name === 'queueLimit') {
                this.notificationsService.show({
                    type: 'error',
                    content: this.translateService.translate(
                        'resources_maximum_file_number_exceeded',
                        {maximum: '10'}
                    )
                });
            }
        };
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { throttleTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CATEGORY_TYPE, CmsArticlesService } from '../shared/articles.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import { UserService } from '../../core/user.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { Subject } from 'rxjs';
import { CmsTranslateService } from '../../shared/translate.service';
import { sortBy } from 'lodash';
import { CmsAppsService } from '../../apps/shared/apps.service';
var CmsNewCategoryComponent = /** @class */ (function () {
    function CmsNewCategoryComponent(fb, appService, articlesService, commonService, router, notifyService, userService, notificationsService, translateService) {
        this.fb = fb;
        this.appService = appService;
        this.articlesService = articlesService;
        this.commonService = commonService;
        this.router = router;
        this.notifyService = notifyService;
        this.userService = userService;
        this.notificationsService = notificationsService;
        this.translateService = translateService;
        this.classes = 'popup-overlay';
        this.categoryType = CATEGORY_TYPE;
        this.categoryTypes = [{
                id: CATEGORY_TYPE.company,
                title: this.translateService.translate('common_company')
            }, {
                id: CATEGORY_TYPE.application,
                title: this.translateService.translate('applications_application')
            }];
        this.$submit = new Subject();
        this.canCreateGlobalCategory = userService.data.modules['articles']['specialPermissions'].canEditGlobalArticles;
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.newCategory = this.fb.group({
            type: ['', [Validators.required]],
            company: '',
            application: '',
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            parent: null,
            locale: ['', [Validators.required]],
            active: false,
        });
        this.companies = sortBy(this.userService.data.user.companies, 'title');
        this.loadData();
        this.setDropdownListeners();
        this.setSubmitListener();
    }
    CmsNewCategoryComponent.prototype.onSubmit = function (_a) {
        var _this = this;
        var value = _a.value;
        this.articlesService.createCategory(value).subscribe(function () {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('articles_category_created')
            });
            _this.notifyService.notify('category');
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsNewCategoryComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    CmsNewCategoryComponent.prototype.setDropdownListeners = function () {
        var _this = this;
        this.newCategory.controls['company'].valueChanges.subscribe(function (company) {
            if (company !== null) {
                _this.loadAllCategories({ company: company && company.id });
            }
        });
        this.newCategory.controls['application'].valueChanges.subscribe(function (application) {
            if (application !== null) {
                _this.loadAllCategories({ app: application && application.id });
            }
        });
        this.newCategory.get('type').valueChanges.subscribe(function (type) {
            _this.newCategory.controls[type.id].setValidators([Validators.required]);
            switch (type.id) {
                case _this.categoryType.application:
                    if (_this.applications &&
                        _this.applications.length === 1 &&
                        !_this.newCategory.get('application').value) {
                        _this.newCategory.patchValue({ application: _this.applications[0] });
                    }
                    _this.newCategory.get('company').clearValidators();
                    _this.newCategory.patchValue({ company: null });
                    break;
                case _this.categoryType.company:
                    if (_this.companies &&
                        _this.companies.length === 1 &&
                        !_this.newCategory.get('company').value) {
                        _this.newCategory.patchValue({ company: _this.companies[0] });
                    }
                    _this.newCategory.get('application').clearValidators();
                    _this.newCategory.patchValue({ application: null });
                    break;
            }
        });
    };
    CmsNewCategoryComponent.prototype.loadData = function () {
        this.loadLocales();
        this.loadApplications();
    };
    CmsNewCategoryComponent.prototype.loadApplications = function () {
        var _this = this;
        this.appService.getAllApps().subscribe(function (applicationsData) {
            var applications = _this.canCreateGlobalCategory ?
                applicationsData.apps :
                applicationsData.apps.filter(function (application) { return (application.company !== null); });
            _this.applications = sortBy(applications, 'name');
        });
    };
    CmsNewCategoryComponent.prototype.loadAllCategories = function (params) {
        var _this = this;
        this.articlesService.getArticleCategories(__assign({}, params, { pageSize: Number.MAX_SAFE_INTEGER })).subscribe(function (categoriesData) {
            _this.categories = sortBy(categoriesData['categories'], 'title');
            _this.clearParent(_this.categories);
        });
    };
    CmsNewCategoryComponent.prototype.loadLocales = function () {
        var _this = this;
        this.commonService.getLocales().subscribe(function (localeData) {
            _this.locales = sortBy(localeData['locales'], 'title');
        });
    };
    CmsNewCategoryComponent.prototype.clearParent = function (categories) {
        var selectedParentCategory = this.newCategory.controls['parent'].value;
        if (selectedParentCategory) {
            var selectedInCategories = categories.some(function (category) {
                return category.id === selectedParentCategory.id;
            });
            if (!selectedInCategories) {
                this.newCategory.patchValue({ parent: null });
            }
        }
        else {
            this.newCategory.patchValue({ parent: null });
        }
    };
    CmsNewCategoryComponent.prototype.setSubmitListener = function () {
        var _this = this;
        this.$submit.pipe(throttleTime(500))
            .subscribe(function () {
            _this.onSubmit(_this.newCategory);
        });
    };
    return CmsNewCategoryComponent;
}());
export { CmsNewCategoryComponent };

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'applications_edit_application' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="app" (ngSubmit)="$submit.next($event)">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (app.get('name').hasError('required') || app.get('name').hasError('maxlength') || app.get('name').hasError('pattern')) && !app.get('name').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_name' | translate" formControlName="name">
                        <label class="g-input-label">{{ 'common_name' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="app.get('name').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="app.get('name').hasError('maxlength') && !app.get('name').hasError('pattern')">
                                {{'common_validation_maximum_field_length' | translate: nameValidationInterpolationParams}}
                            </span>
                            <span *ngIf="app.get('name').hasError('pattern')">
                                {{'common_validation_valid_characters' | translate: nameValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (app.get('title').hasError('required') || app.get('title').hasError('maxlength')) && !app.get('title').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_title' | translate" formControlName="title">
                        <label class="g-input-label">{{ 'common_title' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="app.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="app.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <cms-select
                            formControlName="appTemplate"
                            [options]="appTemplates"
                            [placeholder]="'applications_application_template' | translate"
                            [title]="'applications_application_template' | translate">
                    </cms-select>
                </div>
                <div class="form-control">
                    <cms-select
                            formControlName="company"
                            [options]="companies"
                            [placeholder]="'common_company' | translate"
                            [title]="'common_company' | translate"
                    ></cms-select>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="active">
                        <label class="g-toggle-label">{{ 'common_active' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth"
                            [disabled]="app.invalid || !fieldsChanged">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
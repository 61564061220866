import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { map } from 'lodash';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsModalService } from '../../core/modal.service';
import { UserService } from '../../core/user.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { LIST_ITEM_TYPE } from '../lists.service';
var CmsEditListItemComponent = /** @class */ (function () {
    function CmsEditListItemComponent(fb, modalService, notifyService, userService, translateService) {
        this.fb = fb;
        this.modalService = modalService;
        this.notifyService = notifyService;
        this.userService = userService;
        this.translateService = translateService;
        this.imageUrl = '';
        this.listItemType = LIST_ITEM_TYPE;
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.resourcePickerOptions = {
            mimeType: map(this.userService.data.modules.resources.allowedFileFormats, function (format) { return (format.mimeType); })
        };
    }
    CmsEditListItemComponent.prototype.ngOnInit = function () {
        var _a;
        this.form = this.fb.group((_a = {
                title: [{ value: this.data.title, disabled: true }]
            },
            _a[this.data.listItemType] = [this.data[this.data.listItemType], [Validators.required]],
            _a.active = [this.data.active],
            _a.listItemType = this.data.listItemType,
            _a));
        if (this.data.listItemType === this.listItemType.resource) {
            this.resourceSelected = true;
            this.imageUrl = this.data.resource['thumb-large-url'] || this.data.resource.url || '';
        }
    };
    CmsEditListItemComponent.prototype.onArticlesSelect = function (_a) {
        var article = _a.article;
        this.form.patchValue({
            article: article,
            title: article.title,
        });
        this.resourceSelected = false;
    };
    CmsEditListItemComponent.prototype.onResourceSelect = function (data) {
        this.form.patchValue({
            resource: data.resource,
            title: data.resource.title
        });
        this.imageUrl = data.resource['thumb-large-url'] || data.resource.url || '';
        this.resourceSelected = true;
    };
    CmsEditListItemComponent.prototype.openResourcePicker = function () {
        this.resourceSelected = false;
    };
    CmsEditListItemComponent.prototype.editListItem = function () {
        var _a;
        var listItemType = this.form.get('listItemType').value;
        this.notifyService.notify({
            type: 'edit-list-item',
            data: (_a = {
                    active: this.form.get('active').value,
                    title: this.form.get('title').value
                },
                _a[listItemType] = this.form.get(listItemType).value,
                _a.listItemType = listItemType,
                _a.itemIndex = this.data.itemIndex,
                _a)
        });
        this.closePopup();
    };
    CmsEditListItemComponent.prototype.closePopup = function () {
        this.modalService.close('edit-list-item');
    };
    return CmsEditListItemComponent;
}());
export { CmsEditListItemComponent };

import { Component } from '@angular/core';

import { CmsModalService } from '../../../core/modal.service';
import { CmsNotifyService } from '../../../core/notify.service';

@Component({
    selector: 'cms-app-setting-snapshots-rollback',
    templateUrl: 'app-setting-snapshots-rollback.component.html'
})

export class CmsAppSettingSnapshotsRollbackComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService
    ) {}

    rollback() {
        this.notifyService.notify({
            type: 'settingSnapshotsRollback'
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('cms-app-setting-snapshots-rollback');
    }
}

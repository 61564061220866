var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { CmsObjectHelper } from '../shared/object-helper.service';
var SELF_CLOSING_TAGS = [
    'br', 'hr', 'img', 'area', 'meta', 'col', 'embed', 'base',
    'command', 'input', 'keygen', 'link', 'param', 'source', 'track', 'wbr'
];
export var MODULE_ID = 'ticketLayouts';
var CmsTicketLayoutService = /** @class */ (function () {
    function CmsTicketLayoutService(http, config) {
        this.http = http;
        this.config = config;
        this._toJson = function (res) {
            var data = res.json();
            return data || {};
        };
    }
    CmsTicketLayoutService.prototype.createTicketLayout = function (data) {
        return this.http.post(this.config.gravityCmsApi + "/cms/ticket-layouts", data);
    };
    CmsTicketLayoutService.prototype.createTicketLayoutTemplate = function (data) {
        return this.http.post(this.config.gravityCmsApi + "/cms/ticket-layout-templates", data);
    };
    CmsTicketLayoutService.prototype.editTicketLayoutTemplate = function (id, templateData) {
        return this.http.put(this.config.gravityCmsApi + "/cms/ticket-layout-templates/" + id, templateData);
    };
    CmsTicketLayoutService.prototype.editTicketLayout = function (id, layoutData) {
        return this.http.put(this.config.gravityCmsApi + "/cms/ticket-layouts/" + id, layoutData);
    };
    CmsTicketLayoutService.prototype.escapeSelfClosingTags = function (template) {
        SELF_CLOSING_TAGS.forEach(function (tag) {
            var pattern = "<" + tag + "></" + tag + ">";
            var searchStr = new RegExp(pattern, 'mg');
            template = template.replace(searchStr, "<" + tag + "/>");
        });
        return template;
    };
    CmsTicketLayoutService.prototype.getAppTemplates = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/app-templates?pageSize=100", { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.getTicketLayouts = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-layouts", { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.getTicketLayout = function (id, params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-layouts/" + id, { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.getTicketLayoutTemplates = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-layout-templates", { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.getAllTicketLayoutTemplates = function (params) {
        if (params === void 0) { params = {}; }
        var extendedParams = __assign({}, params, { pageSize: Number.MAX_SAFE_INTEGER });
        return this.getTicketLayoutTemplates(extendedParams);
    };
    CmsTicketLayoutService.prototype.getTicketLayoutTemplate = function (id, params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-layout-templates/" + id, { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.createTicketFragment = function (data) {
        return this.http.post(this.config.gravityCmsApi + "/cms/ticket-fragments", data);
    };
    CmsTicketLayoutService.prototype.getTicketFragments = function (params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-fragments", { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.getAllTicketFragments = function (params) {
        if (params === void 0) { params = {}; }
        var extendedParams = __assign({}, params, { pageSize: Number.MAX_SAFE_INTEGER });
        return this.getTicketFragments(extendedParams);
    };
    CmsTicketLayoutService.prototype.getTicketFragment = function (id, params) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-fragments/" + id, { params: CmsObjectHelper.objToSearchParams(params) });
    };
    CmsTicketLayoutService.prototype.editTicketFragment = function (id, fragmentData) {
        return this.http.put(this.config.gravityCmsApi + "/cms/ticket-fragments/" + id, fragmentData);
    };
    CmsTicketLayoutService.prototype.getTicketFragmentHistory = function (fragmentId, currentPage, pageSize) {
        return this.http.get(this.config.gravityCmsApi + "/cms/ticket-fragments/" + fragmentId + "/history", {
            params: CmsObjectHelper.objToSearchParams({
                currentPage: currentPage,
                pageSize: pageSize
            })
        });
    };
    CmsTicketLayoutService.prototype.rollbackTicketFragment = function (fragmentId, historyId) {
        return this.http.put(this.config.gravityCmsApi + "/cms/ticket-fragments/" + fragmentId + "/history/" + historyId + "/rollback", {});
    };
    return CmsTicketLayoutService;
}());
export { CmsTicketLayoutService };
export var TicketBlockTypes;
(function (TicketBlockTypes) {
    TicketBlockTypes["draft"] = "draft";
    TicketBlockTypes["fragment"] = "fragment";
})(TicketBlockTypes || (TicketBlockTypes = {}));
export var TicketLayoutActions;
(function (TicketLayoutActions) {
    TicketLayoutActions["addBlock"] = "add-ticket-block";
})(TicketLayoutActions || (TicketLayoutActions = {}));

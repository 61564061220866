import {Component, ViewEncapsulation} from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'cms-app',
  template: '<router-outlet></router-outlet>' +
  '<router-outlet name="popup">' +
  '<cms-notifications></cms-notifications>' +
  '<cms-modal></cms-modal>',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    angulartics2GoogleAnalytics.startTracking();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/pipes/translate.pipe";
import * as i2 from "../shared/translate.service";
import * as i3 from "@angular/router";
import * as i4 from "./page-not-found.component";
var styles_CmsPageNotFoundComponent = [];
var RenderType_CmsPageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsPageNotFoundComponent, data: {} });
export { RenderType_CmsPageNotFoundComponent as RenderType_CmsPageNotFoundComponent };
export function View_CmsPageNotFoundComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.CmsTranslatePipe, [i2.CmsTranslateService]), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "upper-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "g-text-xs-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "circle-404"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" 404 "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "p", [["class", "g-text-xs-center mb-3 mt-2 text-404"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 1), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "g-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 5, "div", [["class", "g-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "g-col-xs-6 g-offset-xs-3 g-col-md-4 g-offset-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["class", "g-button"], ["routerLink", "/dashboard"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(14, null, [" ", " "])), i0.ɵppd(15, 1)], function (_ck, _v) { var currVal_1 = "/dashboard"; _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "404_page_not_found")); _ck(_v, 7, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 0), "404_go_to_dashboard")); _ck(_v, 14, 0, currVal_2); }); }
export function View_CmsPageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-page-not-found", [], null, null, null, View_CmsPageNotFoundComponent_0, RenderType_CmsPageNotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i4.CmsPageNotFoundComponent, [], null, null)], null, null); }
var CmsPageNotFoundComponentNgFactory = i0.ɵccf("cms-page-not-found", i4.CmsPageNotFoundComponent, View_CmsPageNotFoundComponent_Host_0, {}, {}, []);
export { CmsPageNotFoundComponentNgFactory as CmsPageNotFoundComponentNgFactory };

import {Component, ViewChild, OnInit, HostBinding} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JsonEditorComponent} from '../../shared/json-editor/json-editor.component';
import {cloneDeep} from 'lodash';
import { CmsApplicationSettingsService, SETTINGS_TYPES } from 'app/application-settings/application-settings.service';

@Component({
    selector: 'cms-app-settings-archive-view',
    templateUrl: 'app-settings-archive-view.component.html',
    providers: [CmsApplicationSettingsService]
})
export class CmsAppSettingsArchiveViewComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    settingTypes = SETTINGS_TYPES;
    originalData: any;
    private valueValidators = [Validators.required];

    @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private appSettingsService: CmsApplicationSettingsService,
        private fb: FormBuilder) {

        this.form = this.fb.group({
            app: this.fb.group(
                {
                    title: [
                        {
                            value: '',
                            disabled: true
                        }
                    ]
                }
            ),
            key: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            value: [
                {
                value: '',
                disabled: true
                }, this.valueValidators],
            type: [{id: ''}, [Validators.required]]
        });

        this.activeRoute.params.subscribe((params: {settingsId: string, key: string}) => {
            this.appSettingsService.getAppSetting({
                settingsId: params.settingsId,
                key: params.key,
                search: {extend: 'app'}
            }).subscribe(res => {
                this.originalData = cloneDeep(res.appSetting);
                res.appSetting.type = this.settingTypes.find(type => {
                    return this.originalData.type === type.id;
                });

                this.form.patchValue(res.appSetting);
            });
        });
    }

    ngOnInit() {}

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'preserve'}
        );
    }
}
<div class="g-popup popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'resources_edit_resource' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <div class="resource-item-preview">
            <div class="image-preview"
                *ngIf="getFileType(selectedResource?.mimeType) === 'image'"
                [style.background-image]="'url(' + getImgUrl(selectedResource) + ')'">
            </div>
            <div class="file-preview"
                *ngIf="getFileType(selectedResource?.mimeType) === 'file'">
                <div class="icon">
                    <i class="g-icon g-icon-full-box"></i>
                </div>
            </div>
            <div class="video-preview"
                *ngIf="getFileType(selectedResource?.mimeType) === 'video'">
                <div class="icon">
                    <i class="g-icon g-icon-play-a"></i>
                </div>
            </div>
            <div class="g-button g-button-jupiter delete"
                (click)="deleteAction(selectedResource)">
                <i class="g-icon g-icon-trash"></i>
            </div>
        </div>
        <form class="edit-resource-form"
              novalidate
              [formGroup]="editResourceForm"
              (ngSubmit)="$submit.next($event);">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io">
                        <input type="text"
                               class="g-input-input"
                            [placeholder]="'common_name' | translate"
                               formControlName="name">
                        <label class="g-input-label">{{ 'common_name' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                        [ngClass]="{invalid: (editResourceForm.get('title').hasError('required') || editResourceForm.get('title').hasError('maxlength')) && !editResourceForm.get('title').pristine}">
                        <input type="text"
                               class="g-input-input"
                               [placeholder]="'common_title' | translate"
                               formControlName="title"
                               required>
                        <label class="g-input-label">{{ 'common_title' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="editResourceForm.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="editResourceForm.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                    <div class="file-details">
                        <p>{{'resources_file_details_name' | translate}}&nbsp;<span>{{selectedResource?.name}}</span></p>
                        <p>{{'resources_file_details_type' | translate}}&nbsp;<span>{{selectedResource?.mimeType}}</span></p>
                        <p>{{'resources_file_details_uploaded_on' | translate}}&nbsp;<span>{{selectedResource?.createdAt | date: 'MM-dd-yyyy HH:mm'}}</span></p>
                        <p>{{'resources_file_details_size' | translate}}&nbsp;<span>{{selectedResource?.size | bytes}}</span></p>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit"
                            class="form-footer-button g-button g-button-earth"
                            [disabled]="editResourceForm.invalid || !fieldsChanged">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

import { Injectable } from '@angular/core';

@Injectable()
export class HeaderMenuService {
    public menuHidden = true;

    toggleMenu () {
        this.menuHidden = !this.menuHidden;
    }

    closeMenu () {
        this.menuHidden = true;
    }
}

<div>
    <div class="g-input g-input-io cms-datetime-picker-input"
         (click)="openDateTimePicker()">
        <input type="text" class="g-input-input"
               readonly
               [value]="dateTimeRange">
        <label class="g-input-label">{{'common_date_and_time_range' | translate }}</label>
        <i class="g-icon g-icon-calendar cms-datetime-picker-input-icon"></i>
    </div>
    <div class="cms-datetime-picker-overlay f-ai-c f-jc-c"
         [ngClass]="{active: open}">
        <div class="cms-datetime-picker d-f f-d-c">
            <div class="cms-datetime-picker-header d-f f-jc-sb">
                <div class="cms-datetime-picker-header-arrow pointer d-f a-i-c f-jc-c" (click)="previousMonth()">
                    <i class="g-icon g-icon-arrow-left"></i>
                </div>
                <div class="cms-datetime-picker-header-title f-1 d-f f-jc-c f-ai-c">
                    {{currentMonthName}} {{currentYear}}
                </div>
                <div class="cms-datetime-picker-header-arrow pointer d-f a-i-c f-jc-c" (click)="nextMonth()">
                    <i class="g-icon g-icon-arrow-right"></i>
                </div>
            </div>
            <div class="cms-datetime-picker-calendar d-f f-d-c">
                <div class="cms-datetime-picker-calendar-header d-f f-jc-sb" >
                    <div *ngFor="let weekday of weekdays"
                         class="cms-datetime-picker-calendar-header-cell d-f f-1 f-ai-c f-jc-c">
                        {{weekday}}
                    </div>
                </div>
                <div class="d-f f-d-c cms-datetime-picker-calendar-grid">
                    <div *ngFor="let week of weeks" class="cms-datetime-picker-calendar-row d-f f-jc-sb f-ac-c">
                        <div *ngFor="let day of week"
                             (click)="selectDay(day.date)"
                             [ngClass]="{selected: day.selected, between: day.between, outside: day.outside}"
                             class="cms-datetime-picker-calendar-cell d-f f-1 f-jc-c f-ai-c pointer">
                            {{day.date.date()}}
                        </div>
                    </div>
                </div>
            </div>
            <form class="cms-datetime-picker-time-inputs d-f f-jc-sb"
                 [formGroup]="timeForm">
                <div class="g-input g-input-io cms-datetime-picker-input">
                    <i class="g-icon g-icon-clock cms-datetime-picker-input-icon"></i>
                    <input type="time" class="g-input-input"
                           formControlName="startTime">
                    <label class="g-input-label">{{ 'common_start_time' | translate }}</label>
                </div>
                <div class="g-input g-input-io cms-datetime-picker-input">
                    <i class="g-icon g-icon-clock cms-datetime-picker-input-icon"></i>
                    <input type="time" class="g-input-input"
                           formControlName="endTime">
                    <label class="g-input-label">{{ 'common_end_time' | translate }}</label>
                </div>
            </form>
            <div class="cms-datetime-picker-footer d-f f-jc-sb">
                <button class="cms-datetime-picker-footer-button g-button g-button-jupiter f-1"
                        (click)="cancel()"
                        type="button">
                    <span class="g-button-title">{{'common_cancel' | translate}}</span>
                </button>
                <button class="cms-datetime-picker-footer-button g-button g-button-earth f-1"
                        [disabled]="!rangeSelected || timeForm.invalid"
                        (click)="save()"
                        type="button">
                    <span class="g-button-title">{{'common_save' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>


import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../../core/site-config.service';
import {Observable} from 'rxjs';
import {CmsObjectHelper} from '../../shared/object-helper.service';
import {ISiteConfig} from '../../core/site-config.interface';

@Injectable()
export class CmsMenusService {

    constructor(
        private  http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    _toJson = (res: Response) => {
        const data = res.json();
        return data || {};
    }

    getMenuTemplates (params?: Object): Observable<MenuTemplateResponse> {
        const queryParams = Object.assign({}, params, {extend: 'locale,appTemplate'});

        return this.http.get<MenuTemplateResponse>(
            `${this.config.gravityCmsApi}/cms/menu-templates`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    getMenus (params?: Object): Observable<MenuResponse> {
        const queryParams = Object.assign({}, params, {extend: 'locale,app,company'});

        return this.http.get<MenuResponse>(
            `${this.config.gravityCmsApi}/cms/menus`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    getMenuTemplate(id: string | number, params?: Object): Observable<any> {
        return this.http.get(
            `${this.config.gravityCmsApi}/cms/menu-templates/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getMenu(id: string, params?: Object): Observable<any> {
        return this.http.get(
            `${this.config.gravityCmsApi}/cms/menus/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    editMenuTemplate (menuTemplate: MenuTemplate, menuTemplateId: number): Observable<{menuTemplate: MenuTemplate}> {
        return this.http.put<{menuTemplate: MenuTemplate}>(
            `${this.config.gravityCmsApi}/cms/menu-templates/${menuTemplateId}`,
            menuTemplate
        );
    }

    editMenu (menu: Menu, menuId: number): Observable<{menu: Menu}> {
        return this.http.put<{menu: Menu}>(
            `${this.config.gravityCmsApi}/cms/menus/${menuId}`,
            menu
        );
    }

    editMenuTemplateTranslation (
        menuTemplate: Object,
        menuTemplateId: number | string,
        localeId: number | string): Observable<{menuTemplate: MenuTemplate}> {

        return this.http.put<{menuTemplate: MenuTemplate}>(
            `${this.config.gravityCmsApi}/cms/menu-templates/${menuTemplateId}/locales/${localeId}`,
            menuTemplate
        );
    }

    editMenuTranslation (menu: Object, menuId: number | string, localeId: number | string): Observable<{menuTemplate: MenuTemplate}> {
        return this.http.put<{menuTemplate: MenuTemplate}>(
            `${this.config.gravityCmsApi}/cms/menus/${menuId}/locales/${localeId}`,
            menu
        );
    }

    createMenuTemplate (menuTemplate: MenuTemplate): Observable<{menuTemplate: MenuTemplate}> {
        return this.http.post<{menuTemplate: MenuTemplate}>(
            `${this.config.gravityCmsApi}/cms/menu-templates`,
            menuTemplate
        );
    }

    createMenu (menu: Menu): Observable<{menu: Menu}> {
        return this.http.post<{menu: Menu}>(
            `${this.config.gravityCmsApi}/cms/menus`,
            menu
        );
    }

    getAppTemplates (params?: Object): Observable<{appTemplates: Array<AppTemplate>}> {
        return this.http.get<{appTemplates: Array<AppTemplate>}>
        (`${this.config.gravityCmsApi}/cms/app-templates?pageSize=${Number.MAX_SAFE_INTEGER}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    getApps (params?: Object): Observable<{appTemplates: Array<AppTemplate>}> {
        return this.http.get<{appTemplates: Array<AppTemplate>}>
        (`${this.config.gravityCmsApi}/cms/apps?pageSize=${Number.MAX_SAFE_INTEGER}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    deleteMenuTranslation (menuId: number | string, localeId: number | string): Observable<Object>  {
        return this.http.delete(
            `${this.config.gravityCmsApi}/cms/menus/${menuId}/locales/${localeId}`
        );
    }

    deleteMenuTemplateTranslation (menuTemplateId: number | string, localeId: number | string): Observable<Object> {
        return this.http.delete(
            `${this.config.gravityCmsApi}/cms/menu-templates/${menuTemplateId}/locales/${localeId}`
        );
    }
}

export interface AppTemplate {
    id: number;
    name: string;
    title: string;
}

export interface MenuTemplate {
    id: number;
    name: string;
    title: string;
    active: boolean;
    appTemplate: number;
    locale: number;
    items: Array<Object>;
}

export interface Menu {
    id: number;
    name: string;
    title: string;
    app: number;
    locale: number;
}

export interface MenuTemplateResponse {
    menuTemplates: Array<MenuTemplate>;
}

export interface MenuResponse {
    menus: Array<Menu>;
}

export const MODULE_ID = 'menus';

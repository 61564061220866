import {Component, Input} from '@angular/core';

@Component({
    selector: 'cms-loader',
    templateUrl: 'loader.component.html',
})
export class CmsLoaderComponent {
    @Input() showLoader = false;
    @Input() loaderOptions: LoaderOptions = {};

    constructor() {}
}

interface LoaderOptions {
    transparent?: boolean;
}

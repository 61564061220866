
import {throttleTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';

import {CmsTranslateService} from '../../shared/translate.service';
import {UserService} from '../../core/user.service';
import {CmsCommonService, Locale, LocalesResponse} from '../../shared/common.service';
import {CmsResources, Resource} from '../shared/resources.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from '../../core/notifications.service';

@Component({
    selector: 'cms-edit-resource-translation',
    templateUrl: 'edit-resource-translation.component.html'
})

export class CmsEditResourceTranslationComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    resource: FormGroup;
    $submit = new Subject<Event>();
    translatedResource: FormGroup;
    fromLanguageTitle: string;
    toLanguageTitle: string;
    titleValidationInterpolationParams: Object;
    popupTitleInterpolationParams;

    constructor(
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private translateService: CmsTranslateService,
        private userService: UserService,
        private commonService: CmsCommonService,
        private resourcesService: CmsResources,
        private notifyService: CmsNotifyService,
        private notificationsService: CmsNotificationsService
    ) {
        this.setSubmitListener();
    }

    ngOnInit() {
        this.resource = this.fb.group({
            title: [{value: '', disabled: true}]
        });

        this.translatedResource = this.fb.group({
            title: ['', [Validators.required, Validators.maxLength(80)]],
        });

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.activeRoute.params.subscribe((params: {
            resourceId: string,
            localeName: string
        }) => {
            // Get translation for current lang
            this.resourcesService.getResource(params.resourceId, {locale: this.userService.data.user.locale}).subscribe(
                (response: {resource: Resource}) => {
                    const resource = response.resource;

                    this.resource.patchValue({
                        title: resource.title,
                    });
                }
            );
            // Get translations for translated language
            this.resourcesService.getResource(params.resourceId, {locale: params.localeName}).subscribe(
                (response: {resource: Resource}) => {
                    const resource = response.resource;

                    this.translatedResource.patchValue({
                        title: resource.title,
                    });
                }
            );

            this.commonService.getLocales().subscribe((localesData: LocalesResponse) => {
                const fromLanguage = localesData.locales.find((locale: Locale) => {
                    return locale.name.toString() === this.userService.data.user.locale;
                });
                const toLanguage = localesData.locales.find((locale: Locale) => {
                    return locale.name.toString() === params.localeName;
                });
                this.fromLanguageTitle = fromLanguage.title;
                this.toLanguageTitle = toLanguage.title;
                this.popupTitleInterpolationParams = {
                    from: fromLanguage.title,
                    to: toLanguage.title
                };
            });
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {'queryParamsHandling': 'preserve'});
    }

    onSubmit({value}) {
        const resourceId = this.activeRoute.snapshot.params['resourceId'];
        const localeId = this.activeRoute.snapshot.params['localeId'];
        this.resourcesService.editResourceTranslation(value, resourceId, localeId).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('resources_resource_translated')
            });
            this.notifyService.notify({type: 'resources'});
            this.closePopup();
        }, (error) => {
            this.notificationsService.showError(error);
        });
    }

    setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.translatedResource);
            });
    }
}

import { Injectable, Inject, InjectionToken, Injector } from '@angular/core';

import {environment} from '../../environments/environment';
import {ISiteConfig} from './site-config.interface';
import {Headers} from '@angular/http';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import { Bootstrap } from './user.service';


export let SITE_CONFIG = new InjectionToken('app.config');

export const SiteConfig: ISiteConfig = {
    gravityCmsApi: '',
    authApi: ''
};

@Injectable()
export class CmsSiteConfigLoader {

    constructor (
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {

    }

    load() {
        return new Promise(resolve => {
            Object.assign(this.config, environment);

            const queryParams = window.location.search;
            const dc = this.getQuery('dc') || this.config.hosts && this.config.hosts[window.location.host];
            const headers = new Headers({ 'Accept': 'application/json' });
            const token = this.getQuery('a');

            // If user came from 7Admin url contains query parameter "a" with token,
            // save it so it can be used
            if (token) {
                localStorage.setItem(
                    'cms.auth_token',
                    'Bearer ' + token
                );
            }
            const authToken = localStorage.getItem('cms.auth_token');

            headers.append('Authorization', authToken);

            if (dc) {
                headers.append('x-nsft-scd-dc', dc);
            }

            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders.set('Accept', 'application/json');
            httpHeaders = httpHeaders.set('Authorization', authToken);
            if (dc) {
                httpHeaders.set('x-nsft-scd-dc', dc);
            }

            this.http.get(environment.gravityCmsApi + '/cms/login', {headers: httpHeaders}).subscribe(
                    (data: Bootstrap) => {
                        this.config.bootstrapData = data;
                        resolve();
                    },
                    err => {
                        resolve();
                    }
                );

        });
    }

    private getQuery(name) {
        const query = window.location.search.substring(1),
            keys = query.split('&'),
            len = keys.length;

        for (let i = 0; i < len; i++) {
            const key = keys[i].split('=');
            if (decodeURIComponent(key[0]) === name) {
                return decodeURIComponent(key[1]);
            }
        }

        return null;
    }
}

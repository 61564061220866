/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tab.component";
import * as i2 from "./tabs.component";
var styles_CmsTabComponent = ["[_nghost-%COMP%] {display: block}"];
var RenderType_CmsTabComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CmsTabComponent, data: {} });
export { RenderType_CmsTabComponent as RenderType_CmsTabComponent };
export function View_CmsTabComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_CmsTabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-tab", [], null, null, null, View_CmsTabComponent_0, RenderType_CmsTabComponent)), i0.ɵdid(1, 49152, null, 0, i1.CmsTabComponent, [i2.CmsTabsComponent], null, null)], null, null); }
var CmsTabComponentNgFactory = i0.ɵccf("cms-tab", i1.CmsTabComponent, View_CmsTabComponent_Host_0, { tabTitle: "tabTitle" }, {}, ["*"]);
export { CmsTabComponentNgFactory as CmsTabComponentNgFactory };

import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'cms-notification',
    templateUrl: 'notification.component.html'
})

export class CmsNotificationComponent implements OnChanges {
    @Input() notification: Object;
    notificationShown = true;
    timer: number;
    message: string;

    constructor() {}

    ngOnChanges(changes) {
        if (changes.notification && changes.notification.currentValue && this.notificationShown) {
            // Set parsed message
            this.message = this.parseMessage(changes.notification.currentValue);

            // Auto-close notification
            this.timer = window.setTimeout(() => {
                this.close();
            }, 4000);
        }
    }

    parseMessage(notification) {
        const content = notification.content;
        const message = content instanceof Object ? (content.httpCode + ' - ' + content.message) : null;
        return message || content;
    }

    close() {
        this.notificationShown = false;
        clearTimeout(this.timer);
    }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../../core/site-config.service';
import {ISiteConfig} from '../../core/site-config.interface';
import {CmsObjectHelper} from '../../shared/object-helper.service';
import {Observable} from 'rxjs';
import {Pagination} from '../../../types';

export interface MarketingSlot {
    active: boolean;
    id: number;
    name: string;
    title: string;
    customData: Object;
    createdAt: string;
    updatedAt: string;
    items?: Array<MarketingSlotItem>;
    app: any;
}

export interface MarketingProvider {
    id: number;
    name: string;
    title: string;
}

export interface MarketingSlotItem {
    id: number;
    provider: MarketingProvider | number;
    reference: string;
    resource?: Object;
    active: boolean;
    customData: Object;
    startDate?: string,
    endDate?: string,
}

export const MODULE_ID = 'marketingSlots';

@Injectable()
export class CmsMarketingService {

    constructor(private http: HttpClient,
                @Inject(SITE_CONFIG) private config: ISiteConfig) {
    }

    getMarketingSlots(params?: Object): Observable<{marketingSlots: Array<MarketingSlot>, pagination: Pagination}> {
        return this.http.get<{marketingSlots: Array<MarketingSlot>, pagination: Pagination}>
        (`${this.config.gravityCmsApi}/cms/marketing/slots`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    createMarketingSlot(newMarketingSlot: MarketingSlot): Observable<{marketingSlot: MarketingSlot}> {
        return this.http.post<{marketingSlot: MarketingSlot}>(`${this.config.gravityCmsApi}/cms/marketing/slots`,
            newMarketingSlot
        );
    }

    getMarketingProviders(): Observable<{marketingProviders: Array<MarketingProvider>, pagination: Pagination}> {
        return this.http.get<{marketingProviders: Array<MarketingProvider>, pagination: Pagination}>
        (`${this.config.gravityCmsApi}/cms/marketing/providers`);
    }

    /**
     *
     * @param {String | Number} id
     * @param {Object} params
     * @param {Object} params.extend
     * @returns {Observable<T>}
     */
    getMarketingSlot(id: string | number, params: Object): Observable<{marketingSlot: MarketingSlot}> {
        return this.http.get<{marketingSlot: MarketingSlot}>(
            `${this.config.gravityCmsApi}/cms/marketing/slots/${id}`
        );
    }

    /**
     *
     * @param {MarketingSlot} marketingSlot
     * @returns {Observable<T>}
     */
    updateMarketingSlot(marketingSlot: MarketingSlot): Observable<{marketingSlot: MarketingSlot}> {
        return this.http.put<{marketingSlot: MarketingSlot}>(
            `${this.config.gravityCmsApi}/cms/marketing/slots/${marketingSlot.id}`,
            marketingSlot
        );
    }

    /**
     *
     * @param {{localeId: number; itemId: number; reference: number}} data
     * @returns {Observable<{marketingSlotItem: MarketingSlotItem}>}
     */
    translateMarketingItemSlot(data: {localeId: number, itemId: number, reference: number}):
    Observable<{marketingSlotItem: MarketingSlotItem}> {

        return this.http.put<{marketingSlotItem: MarketingSlotItem}>(
            `${this.config.gravityCmsApi}/cms/marketing/items/${data.itemId}/translations/${data.localeId}`,
            {
                reference: data.reference
            }
        );
    }

    getMarketingSlotItem(id: number, localeId: number): Observable<{marketingSlotItem: MarketingSlotItem}> {
        return this.http.get<{marketingSlotItem: MarketingSlotItem}>(
            `${this.config.gravityCmsApi}/cms/marketing/items/${id}/translations/${localeId}`
        );
    }
}

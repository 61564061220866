<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">
      {{ 'resource_picker' | translate }}
    </div>
  </header>
  <div class="g-popup-body">
      <cms-resource-picker class="m-2"
                           [options]="data.options"
                           (onResourceSelect)="onResourceSelect($event)">
      </cms-resource-picker>
  </div>
</div>
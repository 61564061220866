import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, ArticlesResponse, CategoriesResponse, Category, CmsArticlesService} from '../../articles/shared/articles.service';
import {CmsNotificationsService} from '../../core/notifications.service';

@Component({
    selector: 'cms-article-picker',
    templateUrl: './cms-article-picker.component.html',
    styles: [':host {display: block}']
})
export class CmsArticlePickerComponent implements OnInit {
    articles: Array<Article>;
    categories: Array<Category>;
    selectedArticle: Article;
    selectedCategory: Category;
    @Input() article: Article;
    @Output() onArticleSelect = new EventEmitter<Object>();

    constructor(
        private articlesService: CmsArticlesService,
        private notificationsService: CmsNotificationsService,
    ) {}

    ngOnInit(): void {
        if (this.article) {
            this.loadArticles(this.article.category, this.article.id);
            this.loadCategories(this.article.category);
        } else {
            this.loadCategories();
        }
    }

    articleSelected(article: Article) {
        this.onArticleSelect.emit({article: article});
    }

    onCategorySelect(category: Category) {
        this.selectedCategory = category;
        this.loadArticles(category.id, this.selectedArticle && this.selectedArticle.id);
    }

    private handleResponseError = (error) => {
        this.notificationsService.showError(error);
    };

    private loadArticles(category, selectedArticleId?: number) {
        this.articlesService.getAllArticles({
            category: category,
            extend: '',
        }).subscribe((articlesData: ArticlesResponse) => {
            this.articles = articlesData.articles;
            this.selectedArticle = this.articles.find(article => article.id === selectedArticleId);
        },
            this.handleResponseError
        );
    }

    private loadCategories(selectedCategoryId?) {
        this.articlesService.getAllArticleCategories({
            extend: 'company,app'
        }).subscribe((categoriesData: CategoriesResponse) => {
            this.categories = categoriesData.categories.map(category => {
                return {
                    ...category,
                    title: `${category.title} - ${category['app'] ? category['app']['title'] : category['company']['title']}`
                }
            });

            if (selectedCategoryId) {
                this.selectedCategory = this.categories.find(category => category.id === selectedCategoryId);
            }
        },
            this.handleResponseError
        );
    }
}

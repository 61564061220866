import {
    AfterViewChecked, AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, OnDestroy,
    Output
} from '@angular/core';
import { CmsIsMobileService } from '../../shared/ismobile.service';

declare var IScroll: any;

@Directive({
    selector: '[cmsIscroll]',
    exportAs: 'cms-iscroll'
})
export class CmsIscrollDirective implements AfterViewInit, AfterViewChecked, OnDestroy {

    iscroll;

    @HostBinding('class.cms-iscroll')
    isDesktop: boolean;

    @Output() onScrollEndTouched = new EventEmitter();

    constructor(private element: ElementRef, private isMobileService: CmsIsMobileService) {
        this.isDesktop = !isMobileService.isMobile().any;
    }

    ngAfterViewInit() {
        if (this.isDesktop) {
            this.iscroll = new IScroll(this.element.nativeElement, {
                mouseWheel: true,
                scrollbars: 'custom',
                interactiveScrollbars: true
            });

            this.iscroll.on('scrollEnd', () => {
                if ( this.iscroll.y === this.iscroll.maxScrollY ) {
                    // do something
                    this.onScrollEndTouched.emit({});
                }
            });
        }
    }

    ngAfterViewChecked() {
        if (this.isDesktop && this.iscroll) {
            this.iscroll.refresh();
        }
    }

    ngOnDestroy() {
        if (this.iscroll) {
            this.iscroll.destroy();
        }
    }

    // When options are changed, iscroll sometimes moves content of
    // CmsSelectComponent's list up and the list seems
    // empty this method is called to return list to correct position
    moveIntoView() {
        if (this.iscroll) {
            this.iscroll.scrollTo(0, 0);
        }
    }
}

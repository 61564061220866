import { catchError, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { FileUploader } from '@nsftx/ng2-file-upload/dist';
import { HttpClient } from '@angular/common/http';
import { SITE_CONFIG } from '../../core/site-config.service';
import { CmsObjectHelper } from '../../shared/object-helper.service';
import { UserService, ResourceSettings } from '../../core/user.service';
import {ISiteConfig} from '../../core/site-config.interface';
import {Pagination} from '../../../types';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface Resource {
    attributes?: any;
    container?: number | Container;
    createdAt: string;
    updatedAt?: string;
    extension: string;
    id: string;
    mimeType: string;
    name: string;
    size: number;
    title: string;
    src?: string;
    url: string;
}

export interface Resources {
    resources: Array<Resource>;
    pagination?: Object;
}

export interface Container {
    active: boolean;
    company?: number | Object;
    createdAt: string;
    currentFiles?: string;
    currentSize?: number;
    id: string;
    maxFiles: number;
    name: string;
    public: boolean;
    title: string;
    updatedAt: string;
    url: string;
}

export const MODULE_ID = 'resources';

export const RESOURCE_FILE_TYPES = {
    image: 'image',
    video: 'video',
    file: 'file'
};

@Injectable()
export class CmsResources {
    public uploader: FileUploader;

    constructor(
        private userService: UserService,
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {
        this.setUploaderOptions();
    }

    private setUploaderOptions() {
        let allowedMimeTypes = [];

        if (this.userService.data.modules.resources) {
            allowedMimeTypes = this.userService.data.modules.resources.allowedFileFormats.map((data: ResourceSettings) => {
                return data.mimeType;
            });
        }
        this.uploader = new FileUploader({
            queueLimit : 10,
            allowedMimeType : allowedMimeTypes,
            maxFileSize : 10000000 // 10 MB limit for one file
        });
    }
    public setUploadUrl (container: any) {
        if (container) {
            this.uploader.setUploadUrl(`${this.config.gravityCmsApi}/cms/resources/${container}`);
        } else {
            this.uploader.setUploadUrl(container);
        }
    }

    public setAuthToken () {
        const token = localStorage.getItem('cms.auth_token');
        this.uploader.setAuthToken(token);
    }

    public createContainer(data: Object) {
        return this.http.post(
            `${this.config.gravityCmsApi}/cms/resource-containers`,
            data,
            {
                responseType: 'text'
            }
        ).pipe(
            map(response => response),
            catchError(responseError => {
                try {
                    responseError.error = JSON.parse(responseError.error)
                } catch (e) {}

                return throwError(responseError)
            })
        );
    }

    public editResourceTranslation(value: object, resourceId: string, localeId: number): Observable<any> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/resources/${resourceId}/locales/${localeId}`,
            value
        );
    }

    public getContainers(params?: Object): Observable<{containers: Array<Container>, pagination: Pagination}> {
        const headers = new HttpHeaders({'X-Nsft-SCD-Merge' : 'true'});

        return this.http.get<{containers: Array<Container>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/resource-containers`,
            {
                headers: headers,
                params: CmsObjectHelper.objToSearchParams(params)
            }
        );
    }

    public getContainer(containerId: string): Observable<any> {
        return this.http.get<any>(`${this.config.gravityCmsApi}/cms/resource-containers/${containerId}`);
    }

    public updateContainer(data: {id: number}) {
        const containerId = data.id;

        return this.http.put(`${this.config.gravityCmsApi}/cms/resource-containers/${containerId}`,
            data
        );
    }

    public getResources(params?: Object): Observable<{resources: Array<Resource>, pagination: Pagination}> {
        let search = {};

        if (params instanceof HttpParams) {
            search = params;
        } else {
            search = CmsObjectHelper.objToSearchParams(params);
        }

        return this.http.get<{resources: Array<Resource>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/resources`,
            {params: search});
    }

    public getResource(resourceId: string, params?: Object): Observable<{resource: Resource}> {
        return this.http.get<{resource: Resource}>(`${this.config.gravityCmsApi}/cms/resources/${resourceId}`,
            {params: CmsObjectHelper.objToSearchParams(params)});
    }

    public updateResource(resourceId, title): Observable<Object> {
        return this.http.put(`${this.config.gravityCmsApi}/cms/resources/${resourceId}`, {
            title : title
        });
    }

    public deleteResource(resource): Observable<Object> {
        return this.http.delete(`${this.config.gravityCmsApi}/cms/resources/${resource.id}`);
    }
}

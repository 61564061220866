<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i class="g-popup-header-close g-icon g-icon-close-a" (click)="closePopup()"></i>
        <div class="g-popup-title">
            {{'articles_translate_category_popup_title' | translate:popupTitleInterpolationParams}}
        </div>
    </header>
    <div class="g-popup-body">
        <div class="g-row">
            <form class="g-col-lg-6 popup-form" novalidate [formGroup]="category">
                <div class="form-body">
                    <div class="form-control">
                        <div class="g-input g-input-io">
                            <input type="text" class="g-input-input" formControlName="title">
                            <label class="g-input-label">
                                {{fromLanguageTitle}} {{'common_title' | translate | lowercase}}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <form class="g-col-lg-6 popup-form" novalidate [formGroup]="translatedCategory">
                <div class="form-body">
                    <div class="form-control">
                        <div class="g-input g-input-io"
                             [ngClass]="{invalid: (translatedCategory.get('title').hasError('required') || translatedCategory.get('title').hasError('maxlength')) && !translatedCategory.get('title').pristine}">
                            <input type="text" class="g-input-input" formControlName="title">
                            <label class="g-input-label">{{toLanguageTitle}} {{'common_title' | translate |
                                lowercase}}</label>
                            <label class="g-input-validation">
                                <span *ngIf="translatedCategory.get('title').hasError('required')">
                                    {{ 'common_validation_field_required' | translate }}
                                </span>
                                <span *ngIf="translatedCategory.get('title').hasError('maxlength')">
                                    {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="form-footer">
            <div class="form-footer-buttons">
                <button class="form-footer-button g-button g-button-earth"
                        type="submit"
                        [disabled]="translatedCategory.invalid || !fieldsChanged"
                        (click)="$submit.next($event)">
                    <span class="g-button-title">{{ 'common_save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="translate pointer"
    [ngClass]="{'language-list-opened': opened}"
    (click)="opened = !opened"
    (cmsClickOutside)="close()"
    [attachOutsideOnClick]="opened">
    <i class="translate-icon g-icon g-icon-globe"></i>
</div>
<div class="language-list" *ngIf="opened" cmsIscroll>
    <div class="language-list-scroller">
        <div class="language pointer"
             [ngClass]="{hidden: row && row.locale && language.id === row.locale.id}"
             *ngFor="let language of languages"
             (click)="onLanguageSelect(language)">
            {{language?.title}}
        </div>
    </div>
</div>
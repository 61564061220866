<div class="popup popup--tabbed g-popup" (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">
      {{ 'menus_edit_item' | translate }}
    </div>
  </header>
  <cms-tabs>
    <cms-tab [tabTitle]="'common_general' | translate">
      <div class="g-popup-body">
        <form class="popup-form"
              novalidate [formGroup]="form">
          <div class="form-body">
            <div class="form-control">
              <cms-select formControlName="provider"
                          [options]="data.providers"
                          [title]="'common_type' | translate"
                          [placeholder]="'common_type' | translate"
                          (selectChanged)="onProviderSelect($event)">
              </cms-select>
            </div>
            <div class="form-control">
              <div class="g-input g-input-io">
                <input type="text"
                       class="g-input-input"
                       formControlName="url"
                       [placeholder]="'common_url' | translate">
                <label class="g-input-label">{{'common_url' | translate}}</label>
                <label class="g-input-validation">
                <span *ngIf="form.get('url').hasError('maxlength')">
                    {{ 'common_validation_field_required' | translate:urlValidationInterpolationParams }}
                </span>
                </label>
              </div>
            </div>
            <div class="form-control">
              <cms-datetime-picker
                      [startDateTime]="form.value.startDate"
                      [endDateTime]="form.value.endDate"
                      (datesSelected)="onDateRangeSelect($event)">
              </cms-datetime-picker>
            </div>
            <div class="form-control">
              <div class="g-toggle g-toggle-io">
                <input class="g-toggle-input"
                       type="checkbox"
                       formControlName="active">
                <label class="g-toggle-label">
                  {{ 'common_active' | translate }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </cms-tab>
    <cms-tab [tabTitle]="'common_content' | translate">
      <div class="g-popup-body">
        <form class="popup-form"
              novalidate
              [formGroup]="form">
          <div class="form-body">
            <div class="form-control">
              <div class="g-input g-input-io"
                   [ngClass]="{invalid: form.get('reference').invalid
                                    && !form.get('reference').pristine}">
                <ng-container *ngIf="form.value.provider?.name === 'google_dfp'">
                  <input type="text"
                         class="g-input-input"
                         [placeholder]="'common_reference' | translate"
                         formControlName="reference">
                  <label class="g-input-label">
                    {{ 'common_reference' | translate }}
                  </label>
                  <label class="g-input-validation">
                <span *ngIf="form.get('reference').hasError('required')">
                    {{ 'common_validation_field_required' | translate }}
                </span>
                  </label>
                </ng-container>
                <ng-container *ngIf="form.value.provider?.name === 'seven'">
                  <div class="form-control-label">{{'common_image' | translate}}</div>
                  <div class="resource-item-preview p-0 mb-1"
                       (click)="openResourcePicker()">
                    <div class="image-preview"
                         *ngIf="form.get('reference').value"
                         [style.background-image]="'url(' + getImgUrl() + ')'">
                    </div>
                    <i class="g-icon g-icon-gallery resource-item-preview-empty"
                       *ngIf="getImgUrl() == ''">
                    </i>
                    <div class="g-button g-button-jupiter delete"
                         *ngIf="form.get('reference').value"
                          cmsStopClickPropagation
                         (click)="removeImage()">
                      <i class="g-icon g-icon-trash"></i>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="form-control" *ngIf="form.value.provider?.name === 'seven'">
              <div class="form-control-label">{{'common_content' | translate}}</div>
              <cms-tinymce formControlName="textContent"
                           [toggleableDarkBody]="true"
                           [instanceConfig]="contentEditorConfig">
              </cms-tinymce>
            </div>
          </div>
        </form>
      </div>
    </cms-tab>
    <cms-tab [tabTitle]="'common_additional_data' | translate">
      <div class="g-popup-body">
        <form class="popup-form"
              novalidate
              [formGroup]="form">
          <div class="form-body">
            <div class="form-control">
              <div class="g-input g-input-io">
                <cms-json-editor formControlName="customData">
                </cms-json-editor>
                <label class="g-input-validation"
                       *ngIf="form.get('customData').hasError('jsonParseError')">
                  {{'common_validation_parse_error' | translate}}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </cms-tab>
  </cms-tabs>
  <div class="popup-footer">
    <div class="popup-footer-buttons">
      <button type="button"
              class="popup-footer-button g-button g-button-earth"
              [disabled]="form.invalid"
              (click)=updateItem()>
            <span class="g-button-title">
              {{ 'common_finish_do' | translate }}
            </span>
      </button>
    </div>
  </div>
</div>

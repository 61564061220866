import {Directive, forwardRef} from '@angular/core';
import { Validator, FormControl, NG_VALIDATORS } from '@angular/forms';

export function validateJson(c: FormControl): { [key: string]: any } {
    if (typeof c.value !== 'object') {
        return {
            jsonParseError: {
                valid: false,
            }
        };
    }

    return null;
}

@Directive({
    selector: '[cmsValidateJson]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => JsonValidatorDirective), multi: true }
    ]
})
export class JsonValidatorDirective implements Validator {
    constructor() {}

    validate(c: FormControl): { [key: string]: any } {
       return validateJson(c);
    }

}

import { NgModule } from '@angular/core';

import { CmsSharedModule } from '../shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { CmsLoginComponent } from './login.component';

@NgModule({
    declarations: [
        CmsLoginComponent
    ],
    imports: [
        CmsSharedModule,
        LoginRoutingModule
    ]
})
export class CmsLoginModule { }

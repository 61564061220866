import { Component } from '@angular/core';

import { CmsNotificationsService } from '../../core/notifications.service';

@Component({
    selector: 'cms-notifications',
    templateUrl: 'notifications.component.html'
})

export class CmsNotificationsComponent {
    private notificationsService;
    public notifications: Array<Object> = [];

    constructor(notificationsService: CmsNotificationsService) {
         notificationsService.notification.subscribe(
            (notification) => {
                this.notifications.push(notification);
            }
        );
    }
}

<div class="pagination-centerer" [ngClass]="{active: paginationCountOptionsShown}">
    <button class="pagination-count-toggle px-1 pointer"
            (click)="paginationCountOptionsShown = !paginationCountOptionsShown">
        <i class="pagination-count-icon g-icon g-icon-files">
        </i>
        <i class="pagination-count-icon pagination-count-open-icon g-icon g-icon-arrow-right">
        </i>
    </button>
    <div class="pagination-count">
        <div *ngFor="let page of pages" class="pagination-count-option-wrapper">
            <button class="pagination-count-option"
                    *ngIf="paginationCountOptionsShown || selectedPageSize === page"
                    [ngClass]="{active: selectedPageSize === page}"
                    (click)="changePageSize(page)"
                    [disabled]="selectedPageSize === page">
                {{page}}
            </button>
        </div>
    </div>
</div>
<div class="pagination-pages" *ngIf="currentPage">
    <button class="pagination-page px-1" [ngClass]="{disabled: currentPage === 1}"
            (click)="paginate$.next('first')" [disabled]="currentPage === 1">
        {{'common_start' | translate}}
    </button>
    <button class="pagination-page px-1" [ngClass]="{disabled: currentPage === 1}"
            (click)="paginate$.next('prev')" [disabled]="currentPage === 1">
        <i class="pagination-icon g-icon g-icon-arrow-left"></i>
    </button>
    <div class="pagination-page px-1 pagination-page-current">
        <p clasS="mb-0">{{currentPage}}</p>
    </div>
    <button class="pagination-page px-1" [ngClass]="{disabled: currentPage === lastPage}"
            (click)="paginate$.next('next')" [disabled]="currentPage === lastPage">
        <i class="pagination-icon g-icon g-icon-arrow-right"></i>
    </button>
    <button class="pagination-page px-1" [ngClass]="{disabled: currentPage === lastPage}"
            (click)="paginate$.next('last')" [disabled]="currentPage === lastPage">
        {{'common_end' | translate}}
    </button>
</div>
<div class="pagination-centerer" [ngClass]="{active: paginationCountOptionsShown}">
</div>
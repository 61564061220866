<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{ 'menus_edit_item' | translate }}
        </div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="menuTemplateItem" (ngSubmit)="editItem()">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (menuTemplateItem.get('title').hasError('required') || menuTemplateItem.get('title').hasError('maxlength')) && !menuTemplateItem.get('title').pristine}">
                        <input type="text" class="g-input-input"
                               [placeholder]="'menus_item_title' | translate"
                               formControlName="title">
                        <label class="g-input-label">{{ 'menus_item_title' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="menuTemplateItem.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="menuTemplateItem.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io">
                        <input type="text"
                               class="g-input-input"
                               [placeholder]="'menus_item_url' | translate"
                               formControlName="url">
                        <label class="g-input-label">{{ 'menus_item_url' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io">
                        <input type="text" class="g-input-input"
                               [placeholder]="'menus_item_subtitle' | translate"
                               formControlName="subtitle">
                        <label class="g-input-label">{{ 'menus_item_subtitle' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io">
                        <input type="text" class="g-input-input"
                               [placeholder]="'menus_item_description' | translate"
                               formControlName="description">
                        <label class="g-input-label">{{ 'menus_item_description' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io">
                        <input type="text" class="g-input-input"
                               [placeholder]="'menus_item_icon' | translate"
                               formControlName="icon">
                        <label class="g-input-label">{{ 'menus_item_icon' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: menuTemplateItem.get('customData').value,
                                     invalid: menuTemplateItem.get('customData').hasError('jsonParseError')
                                    && !menuTemplateItem.get('customData').pristine}">
                        <cms-json-editor formControlName="customData"></cms-json-editor>
                        <label class="g-input-validation"
                               *ngIf="menuTemplateItem.get('customData').hasError('jsonParseError')">
                            {{'common_validation_parse_error' | translate}}
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="active">
                        <label class="g-toggle-label">{{ 'common_active' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="disabled">
                        <label class="g-toggle-label">{{ 'common_disabled' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth" [disabled]="menuTemplateItem.invalid">
                        <span class="g-button-title">{{ 'common_finish_do' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div
    class="popup g-popup"
    (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i
            (click)="closePopup()"
            class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'ticket_layouts_add_section' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form
            class="popup-form"
            novalidate
            [formGroup]="form"
            (ngSubmit)="$submit.next()">
            <div class="form-body">
                <div class="form-control">
                    <cms-select
                        formControlName="type"
                        [options]="types"
                        [placeholder]="'common_type' | translate"
                        [title]="'common_type' | translate"
                        (selectChanged)="onTypeChange($event)"
                    ></cms-select>
                </div>
                <div
                    class="form-control"
                    *ngIf="form.get('type').value.id === ticketBlockTypes.fragment">
                    <cms-select
                        formControlName="fragment"
                        [options]="data.fragments"
                        [placeholder]="'ticket_layouts_fragment' | translate"
                        [title]="'ticket_layouts_fragment' | translate">
                    </cms-select>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button
                        type="submit"
                        class="form-footer-button g-button g-button-earth"
                        [disabled]="form.invalid">
                        <span class="g-button-title">{{ 'common_add' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

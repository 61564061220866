/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tabs.component";
var styles_CmsTabsComponent = ["[_nghost-%COMP%] {display: block}"];
var RenderType_CmsTabsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CmsTabsComponent, data: {} });
export { RenderType_CmsTabsComponent as RenderType_CmsTabsComponent };
function View_CmsTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "g-tab g-tab-text"], ["tabindex", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { active: 0 }), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "g-tab g-tab-text"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.active); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.tabTitle; _ck(_v, 4, 0, currVal_2); }); }
export function View_CmsTabsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "g-tabs g-tabs-juno"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsTabsComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CmsTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-tabs", [], null, null, null, View_CmsTabsComponent_0, RenderType_CmsTabsComponent)), i0.ɵdid(1, 49152, null, 0, i2.CmsTabsComponent, [], null, null)], null, null); }
var CmsTabsComponentNgFactory = i0.ɵccf("cms-tabs", i2.CmsTabsComponent, View_CmsTabsComponent_Host_0, {}, {}, ["*"]);
export { CmsTabsComponentNgFactory as CmsTabsComponentNgFactory };

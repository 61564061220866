
import {throttleTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { CmsAppTemplatesService } from '../shared/app-templates.service';
import {CmsCommonService, NameValidationPattern} from '../../shared/common.service';
import {CmsNotifyService} from '../../core/notify.service';
import {cloneDeep} from 'lodash';
import {Subject} from 'rxjs';

import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';


@Component({
    selector: 'cms-new-app-template',
    templateUrl: 'new-app-template.component.html'
})

export class CmsNewAppTemplateComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    newAppTemplate: FormGroup;
    providers: Array<Object>;
    $submit = new Subject<Event>();
    validationInterpolation: {
        name: { field: string; maximum: string; characters: string };
        nameTemplate: {
            maxLength: { field: string; maximum: string }
            pattern: { }
        };
        title: { field: string; maximum: string }
    };
    nameTemplateInterpolationGuide: string;
    private nameTemplateInterpolationParams = ['companyName', 'companyPlatformId'];

    constructor(
        private fb: FormBuilder,
        private appTemplatesService: CmsAppTemplatesService,
        private commonService: CmsCommonService,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.setSubmitListener();
        this.validationInterpolation = {
            name: {
                field: this.translateService.translate('common_name').toLowerCase(),
                maximum: '80',
                characters: NameValidationPattern.description
            },
            nameTemplate: {
                maxLength: {
                    field: this.translateService.translate('application_templates_name_template').toLowerCase(),
                    maximum: '255'
                },
                pattern: {interpolationParams: this.nameTemplateInterpolationParams.join(', ')}
            },
            title: {
                field: this.translateService.translate('common_title').toLowerCase(),
                maximum: '80'
            }
        };
        this.nameTemplateInterpolationGuide = this.translateService.translate(
            'application_templates_name_template_interpolation_guide',
            {
                interpolationParams: this.nameTemplateInterpolationParams.join(', '),
                interpolationExample: '{' + this.nameTemplateInterpolationParams[0] + '}'
            }
        );
    }

    ngOnInit() {
        const nameTemplatePattern = new RegExp('^([a-zA-Z0-9_-]|({(' + this.nameTemplateInterpolationParams.join('|') + ')}))+$');

        this.newAppTemplate = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            nameTemplate: ['', [Validators.maxLength(255), Validators.pattern(nameTemplatePattern)]]
        });
    }

    onSubmit({value}) {
        this.appTemplatesService.createAppTemplate(cloneDeep(value)).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('applications_application_template_created')
            });
            this.closePopup().then( () => {
                this.notifyService.notify('appTemplate');
            });
        }, (error) => {
            this.notificationsService.showError(error);
        });

    }

    closePopup() {
        return this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.newAppTemplate);
            });
    }
}


import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import { Response } from '@angular/http';

import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../core/site-config.service';
import {CmsObjectHelper} from '../shared/object-helper.service';
import {App} from '../apps/shared/apps.service';
import {Observable} from 'rxjs';
import {Pagination} from '../../types';
import {ISiteConfig} from '../core/site-config.interface';

export interface AppSetting {
    key: string;
    type: string | object;
    value: any;
    app?: App;
    updatedAt: string;
    createdAt: string;
}

export interface AppSettingHistory {
    id: number;
    appSettingId: number;
    key: string;
    type: string;
    archived: boolean;
    createdAt: string;
    user: string;
    value: any;
}

export enum SettingType {
    number,
    string,
    boolean,
    object
}

export const SETTINGS_TYPES = [
    {
        id: SettingType[SettingType.number],
        title: 'Number'
    },
    {
        id: SettingType[SettingType.string],
        title: 'String'
    },
    {
        id: SettingType[SettingType.boolean],
        title: 'Boolean'
    },
    {
        id: SettingType[SettingType.object],
        title: 'Object'
    }
];

export const MODULE_ID = 'settings';

/**
 *
 * @param appSetting
 * @return {AppSetting}
 */
export function parseAppSetting (appSetting: AppSetting) {
    appSetting.value = appSetting.value instanceof Object ?
        JSON.stringify(appSetting.value) : appSetting.value;

    return appSetting;
}

function decodeAppSetting (appSetting: {value: any, type: string}) {
    let value = appSetting.value;

    switch (appSetting.type) {
        case SettingType[SettingType.boolean]:
            if (value === 'true') {
                value = true;
            } else if (value === 'false') {
                value = false;
            }
            break;
        default:
            break;
    }

    appSetting.value = value;

}

@Injectable()
export class CmsApplicationSettingsService {

    constructor(private http: HttpClient,
                @Inject(SITE_CONFIG) private config: ISiteConfig) {}

    /**
     * Get all app settings
     *
     * @param {Object} params
     * @param {string} params.search - Search apps by key, value, app name or app title
     * @param {string} params.extend - Extend data with related resource information (app)
     * @param {number} params.currentPage - Displayed page number
     * @param {number} params.pageSize - Number of items per page
     * @param {number} params.appId - id of app to filter
     * @return {Observable<R>}
     */
    getAppSettings(params?: Object):
    Observable<{appSettings: Array<AppSetting>, pagination: Pagination}> {
        return this.http.get<{appSettings: Array<AppSetting>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/app-settings`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    /**
     * @param {Object} params
     * @param {String} params.key
     * @param {String} params.appId
     * @param {Object} params.search
     * @param {String} params.search.extend - extend app setting with models (app)
     * @return {Observable<Response>}
     */
    getAppSetting(params?: {settingsId: string, key: string, search: {extend: string}}): Observable<{appSetting: AppSetting}> {
        return this.http.get<{appSetting: AppSetting}>(`${this.config.gravityCmsApi}/cms/app-settings/${params.settingsId}`, {
            params: CmsObjectHelper.objToSearchParams(params.search)
        });
    }

    getAppSettingHistory(appSettingId, params: Object):
        Observable<{appSettingHistory: Array<AppSettingHistory>, pagination: Pagination}> {

        const url = `${this.config.gravityCmsApi}/cms/app-settings/${appSettingId}/history`;

        return this.http.get<{appSettingHistory: Array<AppSettingHistory>, pagination: Pagination}>(url, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    rollbackAppSetting(appSettingId, appSettingHistoryId, applyValue) {
        const url = `${this.config.gravityCmsApi}/cms/app-settings/${appSettingId}/history/${appSettingHistoryId}/rollback`;

        return this.http.put<any>(url, applyValue);
    }

    /**
     * @param {Object} params
     * @param {String} params.key
     * @param {String} params.appId
     * @param {String} params.value
     * @param {String} params.type
     * @param {Object} params.search
     * @param {String} params.search.extend - extend app setting with models (app)
     * @return {Observable<Response>}
     */
    updateAppSetting(params) {

        decodeAppSetting(params);

        return this.http.put(`${this.config.gravityCmsApi}/cms/app-settings/${params.settingsId}`,
            {
                value: params.value,
                type: params.type
            },
            {
                params: CmsObjectHelper.objToSearchParams(params.search)
            }
        );
    }

    /**
     * @param {Object} params
     * @param {String} params.key
     * @param {String} params.appId
     * @param {String} params.value
     * @param {String} params.type
     * @param {Object} params.search
     * @param {String} params.search.extend - extend app setting with models (app)
     * @return {Observable<Response>}
     */
    createAppSetting(params) {

        decodeAppSetting(params);

        return this.http.post(`${this.config.gravityCmsApi}/cms/app-settings`,
            {
                key: params.key,
                value: params.value,
                type: params.type,
                app: params.appId
            },
            {
                params: CmsObjectHelper.objToSearchParams(params.search)
            }
        );
    }

    /**
     * @param {Number} applicationSettingId
     * @return {Observable<Response>}
     */
    archiveSetting(applicationSettingId) {
        return this.http.put(`${this.config.gravityCmsApi}/cms/app-settings/${applicationSettingId}/archive`, {});
    }
}

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CmsObjectHelper {

    /**
     *
     * @param obj
     * @return {HttpParams}
     */
    static objToSearchParams(obj: Object): HttpParams {
        let params: HttpParams = new HttpParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params = params.set(key, obj[key]);
            }
        }
        return params;
    }
}

<div class="upper-section">
    <div class="logo-section">
        <div class="logo-img"></div>
    </div>
</div>
<div class="lower-section">
    <form role="form"
          [formGroup]="loginForm"
          novalidate
          (ngSubmit)="onSubmit($event)">
        <div class="login-form">
            <div class="g-input g-input-io"
                [ngClass]="{invalid: loginForm.get('_username').hasError('required')
                                    && loginForm.get('_username').touched}">
                <input type="text"
                       placeholder="Username"
                       autocorrect="off"
                       formControlName="_username"
                       class="g-input-input"/>
                <span class="g-input-label">Username</span>
                <label class="g-input-validation"
                       *ngIf="loginForm.get('_username').hasError('required')
                              && loginForm.get('_username').touched">
                    Filed required
                </label>
            </div>
            <div class="g-input g-input-io password-section"
                 [ngClass]="{invalid: loginForm.get('_password').hasError('required')
                            && loginForm.get('_password').touched }">
                <input type="password"
                       placeholder="Password"
                       formControlName="_password"
                       class="g-input-input"/>
                <span class="g-input-label">Password</span>
                <label class="g-input-validation"
                       *ngIf="loginForm.get('_password').hasError('required')
                              && loginForm.get('_password').touched">
                    Filed required
                </label>
            </div>
            <button type="submit"
                    class="g-button g-button-io w-100">
                <span class="g-button-title">Login</span>
            </button>
        </div>
    </form>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../pipes/translate.pipe";
import * as i4 from "../translate.service";
import * as i5 from "./pagination.component";
import * as i6 from "@angular/router";
var styles_CmsPaginationComponent = [i0.styles];
var RenderType_CmsPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CmsPaginationComponent, data: {} });
export { RenderType_CmsPaginationComponent as RenderType_CmsPaginationComponent };
function View_CmsPaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "pagination-count-option"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePageSize(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "pagination-count-option"; var currVal_2 = _ck(_v, 3, 0, (_co.selectedPageSize === _v.parent.context.$implicit)); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedPageSize === _v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_3); }); }
function View_CmsPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pagination-count-option-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CmsPaginationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.paginationCountOptionsShown || (_co.selectedPageSize === _v.context.$implicit)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CmsPaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "pagination-pages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "pagination-page px-1"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.paginate$.next("first") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { disabled: 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "pagination-page px-1"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.paginate$.next("prev") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { disabled: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "pagination-icon g-icon g-icon-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "pagination-page px-1 pagination-page-current"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["clasS", "mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["class", "pagination-page px-1"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.paginate$.next("next") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { disabled: 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "pagination-icon g-icon g-icon-arrow-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "button", [["class", "pagination-page px-1"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.paginate$.next("last") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(22, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { disabled: 0 }), (_l()(), i1.ɵted(24, null, [" ", " "])), i1.ɵppd(25, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "pagination-page px-1"; var currVal_2 = _ck(_v, 4, 0, (_co.currentPage === 1)); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = "pagination-page px-1"; var currVal_6 = _ck(_v, 10, 0, (_co.currentPage === 1)); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_9 = "pagination-page px-1"; var currVal_10 = _ck(_v, 18, 0, (_co.currentPage === _co.lastPage)); _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_12 = "pagination-page px-1"; var currVal_13 = _ck(_v, 23, 0, (_co.currentPage === _co.lastPage)); _ck(_v, 22, 0, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentPage === 1); _ck(_v, 1, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "common_start")); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.currentPage === 1); _ck(_v, 7, 0, currVal_4); var currVal_7 = _co.currentPage; _ck(_v, 14, 0, currVal_7); var currVal_8 = (_co.currentPage === _co.lastPage); _ck(_v, 15, 0, currVal_8); var currVal_11 = (_co.currentPage === _co.lastPage); _ck(_v, 20, 0, currVal_11); var currVal_14 = i1.ɵunv(_v, 24, 0, _ck(_v, 25, 0, i1.ɵnov(_v.parent, 0), "common_end")); _ck(_v, 24, 0, currVal_14); }); }
export function View_CmsPaginationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CmsTranslatePipe, [i4.CmsTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "pagination-centerer"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { active: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "pagination-count-toggle px-1 pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.paginationCountOptionsShown = !_co.paginationCountOptionsShown) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "pagination-count-icon g-icon g-icon-files"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "pagination-count-icon pagination-count-open-icon g-icon g-icon-arrow-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "pagination-count"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CmsPaginationComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CmsPaginationComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "pagination-centerer"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pagination-centerer"; var currVal_1 = _ck(_v, 4, 0, _co.paginationCountOptionsShown); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.pages; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.currentPage; _ck(_v, 12, 0, currVal_3); var currVal_4 = "pagination-centerer"; var currVal_5 = _ck(_v, 16, 0, _co.paginationCountOptionsShown); _ck(_v, 15, 0, currVal_4, currVal_5); }, null); }
export function View_CmsPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-pagination", [], null, null, null, View_CmsPaginationComponent_0, RenderType_CmsPaginationComponent)), i1.ɵdid(1, 638976, null, 0, i5.CmsPaginationComponent, [i6.Router, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CmsPaginationComponentNgFactory = i1.ɵccf("cms-pagination", i5.CmsPaginationComponent, View_CmsPaginationComponent_Host_0, { pagination: "pagination", pageSizes: "pageSizes", config: "config" }, { paginationChanged: "paginationChanged" }, []);
export { CmsPaginationComponentNgFactory as CmsPaginationComponentNgFactory };

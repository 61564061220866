import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CmsCommonService} from './common.service';
import {CmsObjectHelper} from './object-helper.service';
import {CmsIsMobileService} from './ismobile.service';
import {CmsClickOutsideDirective} from './click-outside/click-outside.directive';
import {CmsHeaderComponent} from './header/header.component';
import {CmsHeaderMenuComponent} from './header/header-menu/header-menu.component';
import {HeaderMenuService} from './header/header-menu/header-menu.service';
import {CmsTableComponent} from './table/table.component';
import {CmsTableCellLanguagesComponent} from './table/table-cell/table-call-languages/table-cell-languages.component';
import {CmsTableFiltersComponent} from './table-filters/table-filters.component';
import {CmsTableFilterComponent} from './table-filters/table-filter/table-filter.component';
import {CmsTableImgPreviewDirective} from './table/table-img-preview/table-img-preview.directive';
import {CmsFilterService} from './filter.service';
import {CmsSearchComponent} from './search/search.component';
import {CmsPaginationComponent} from './pagination/pagination.component';
import {CmsNewItemComponent} from './new-item/new-item.component';
import {CmsSelectComponent} from './select/select.component';
import {CmsNotificationsComponent} from './notifications/notifications.component';
import {CmsNotificationComponent} from './notifications/notification/notification.component';
import {CmsModalComponent} from './modal/modal.component';
import {TinymceComponent} from './tinymce/tinymce.component';
import {TinymceOptions} from './tinymce/tinymce.config.interface';
import {CmsLoaderComponent} from './loader/loader.component';
import {CmsGridComponent} from './grid/grid.component';
import {CmsEscapeKeyDirective} from './escape-key/escape-key.directive';
import {CmsIscrollDirective} from './iscroll/iscroll.directive';
import {CmsTooltipDirective} from './tooltip/tooltip.directive';
import {DragulaModule} from 'ng2-dragula';
import {CmsTranslatePipe} from './pipes/translate.pipe';
import {CmsBytesPipe} from './pipes/bytes.pipe';
import {JsonEditorComponent} from './json-editor/json-editor.component';
import {CmsTimeAgoPipe} from './pipes/time-ago.pipe';
import {CmsRouterLinkActiveDirective} from './router-link-active/cms-router-link-active.directive';
import {ResourcesPlugin} from './tinymce/resources-plugin.service';
import {AceEditorModule} from 'ng2-ace-editor';
import {AceEditorComponent} from './ace-editor/ace-editor.component';
import {JsonValidatorDirective} from './json-editor/json-validator.directive';
import {JsonViewerComponent} from './json-viewer/json-viewer.component';
import {CmsResourcePickerComponent} from './resource-picker/resource-picker.component';
import {ResourceFileTypePipe} from './pipes/resource-file-type.pipe';
import { CmsArticlePickerComponent } from './cms-article-picker/cms-article-picker.component';
import { CmsTabsComponent } from './tabs/tabs.component';
import { CmsTabComponent } from './tabs/tab.component';
import { StopClickPropagationDirective } from './stop-click-propagation/stop-click-propagation.directive';
import { CmsDragulaAutoscrollDirective } from './cms-dragula-autoscroll/cms-dragula-autoscroll.directive';
import { CmsCopyToClipboardComponent } from './cms-copy-to-clipboard/copy-to-clipboard.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DragulaModule.forRoot(),
        AceEditorModule
    ],
    declarations: [
        CmsRouterLinkActiveDirective,
        CmsClickOutsideDirective,
        CmsEscapeKeyDirective,
        CmsHeaderComponent,
        CmsHeaderMenuComponent,
        CmsTableComponent,
        CmsTableCellLanguagesComponent,
        CmsTableImgPreviewDirective,
        CmsTableFiltersComponent,
        CmsTableFilterComponent,
        CmsSearchComponent,
        CmsPaginationComponent,
        CmsNewItemComponent,
        CmsSelectComponent,
        CmsNotificationsComponent,
        CmsNotificationComponent,
        CmsModalComponent,
        TinymceComponent,
        CmsLoaderComponent,
        CmsGridComponent,
        CmsIscrollDirective,
        CmsTooltipDirective,
        CmsTimeAgoPipe,
        CmsTranslatePipe,
        CmsBytesPipe,
        JsonEditorComponent,
        CmsResourcePickerComponent,
        AceEditorComponent,
        JsonValidatorDirective,
        JsonViewerComponent,
        ResourceFileTypePipe,
        CmsArticlePickerComponent,
        CmsTabsComponent,
        CmsTabComponent,
        StopClickPropagationDirective,
        CmsDragulaAutoscrollDirective,
        CmsCopyToClipboardComponent,
        DatetimePickerComponent,
    ],
    exports: [
        CmsRouterLinkActiveDirective,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CmsClickOutsideDirective,
        CmsEscapeKeyDirective,
        CmsHeaderComponent,
        CmsTableComponent,
        CmsTableFiltersComponent,
        CmsTableFilterComponent,
        CmsSearchComponent,
        CmsPaginationComponent,
        CmsNewItemComponent,
        CmsSelectComponent,
        CmsNotificationsComponent,
        CmsNotificationComponent,
        CmsModalComponent,
        TinymceComponent,
        CmsLoaderComponent,
        CmsGridComponent,
        CmsIscrollDirective,
        CmsTooltipDirective,
        CmsTimeAgoPipe,
        CmsTranslatePipe,
        CmsBytesPipe,
        JsonEditorComponent,
        CmsTableCellLanguagesComponent,
        CmsResourcePickerComponent,
        AceEditorComponent,
        JsonValidatorDirective,
        ResourceFileTypePipe,
        CmsArticlePickerComponent,
        CmsTabsComponent,
        CmsTabComponent,
        StopClickPropagationDirective,
        CmsDragulaAutoscrollDirective,
        JsonViewerComponent,
        CmsCopyToClipboardComponent,
        DatetimePickerComponent,
    ],
    providers: [
        CmsCommonService,
        CmsFilterService,
        CmsIsMobileService,
        CmsObjectHelper,
        HeaderMenuService,
        CmsTranslatePipe,
        { provide: 'TINYMCE_CONFIG', useClass: TinymceOptions },
        { provide: 'Window',  useValue: window },
        ResourcesPlugin
    ],
})

export class CmsSharedModule {}

import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { each, isEqual } from 'lodash';
var CmsFilterService = /** @class */ (function () {
    function CmsFilterService(currentRoute) {
        this.currentRoute = currentRoute;
        this.selectedFilterSource = new Subject();
        this.filterChanged$ = this.selectedFilterSource.asObservable();
        this._filters = [];
        this.selectedValues = {};
        this.selectedItems = {};
    }
    CmsFilterService.prototype.setFilters = function (filters) {
        this._filters = filters;
        this.setSelected();
    };
    CmsFilterService.prototype.setSelected = function () {
        this.setSelectedValues();
        this.setSelectedItems();
    };
    CmsFilterService.prototype.setSelectedValues = function () {
        var _this = this;
        if (!this._filters) {
            return false;
        }
        this._filters.forEach(function (filter) {
            if (_this.currentRoute.snapshot.queryParams[filter.id]) {
                switch (filter.selectionValueType) {
                    case 'number':
                        _this.selectedValues[filter.id] = Number(_this.currentRoute.snapshot.queryParams[filter.id]);
                        break;
                    default:
                        _this.selectedValues[filter.id] = _this.currentRoute.snapshot.queryParams[filter.id];
                }
            }
            else {
                _this.selectedValues[filter.id] = null;
            }
        });
    };
    CmsFilterService.prototype.setSelectedItems = function () {
        var _this = this;
        if (!this._filters) {
            return false;
        }
        this._filters.forEach(function (filter) {
            var selectedValueExist = Object.keys(_this.selectedValues).find(function (key) {
                return key === filter.id;
            });
            if (selectedValueExist) {
                _this.selectedItems[filter.id] = filter.items.find(function (item) {
                    return item[filter.selectionKey] === _this.selectedValues[filter.id];
                });
            }
            else {
                _this.selectedItems[filter.id] = null;
            }
        });
    };
    CmsFilterService.prototype.setSelectedValue = function (selectedValue) {
        Object.assign(this.selectedValues, selectedValue);
    };
    CmsFilterService.prototype.clearSingleFilter = function (filter) {
        this.selectedValues[filter.id] = null;
        this.selectedItems[filter.id] = null;
        this.selectedFilterSource.next(this.selectedValues);
    };
    CmsFilterService.prototype.clearAllFilters = function () {
        Object.keys(this.selectedValues).forEach(function (key) {
            this.selectedValues[key] = null;
        }, this);
        Object.keys(this.selectedItems).forEach(function (key) {
            this.selectedItems[key] = null;
        }, this);
        this.selectedFilterSource.next(this.selectedValues);
    };
    CmsFilterService.prototype.selectItem = function (item, filter) {
        var _a;
        if (isEqual(this.selectedItems[filter.id], item)) {
            return;
        }
        var selectedValue = (_a = {},
            _a[filter.id] = item[filter.selectionKey],
            _a);
        this.selectedItems[filter.id] = item;
        this.setSelectedValue(selectedValue);
        if (filter.childFilters) {
            this.resetChildFilters(filter.childFilters);
            this.selectedFilterSource.next(this.selectedValues);
        }
        else {
            this.selectedFilterSource.next(selectedValue);
        }
    };
    CmsFilterService.prototype.resetChildFilters = function (childFilters) {
        var _this = this;
        each(childFilters, function (filterKey) {
            _this.selectedValues[filterKey] = null;
            _this.selectedItems[filterKey] = null;
        });
    };
    return CmsFilterService;
}());
export { CmsFilterService };


import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {CmsResources, Container, Resource} from '../../resources/shared/resources.service';
import {Pagination} from '../../../types';
import {Subject} from 'rxjs';
import { HttpParams } from '@angular/common/http';

export interface CmsResourcePickerOptions {
    mimeType: Array<string>;
}

@Component({
    selector: 'cms-resource-picker',
    templateUrl: './resource-picker.component.html',
    styleUrls: ['./resource-picker.component.scss']
})
export class CmsResourcePickerComponent implements OnInit {
    search: string;
    searchChanged: Subject<string> = new Subject<string>();
    selectedContainer: Container;
    resourceData: {
        resources: Array<Resource>,
        pagination: Pagination
    };
    containers: Array<Object>;
    @Input() options: CmsResourcePickerOptions = {
        mimeType: null
    };
    @Output() onResourceSelect = new EventEmitter<Object>();

    constructor(private resourceService: CmsResources) {
        this.searchChanged.pipe(
            debounceTime(300), // wait 300ms after the last event before emitting last event
            distinctUntilChanged(), ) // only emit if value is different from previous value
            .subscribe(model => {
                this.search = model;
                this.getData(this.selectedContainer, model);
            });
    }

    ngOnInit() {
        this.resourceService.getContainers({pageSize: 10000}).subscribe(res => {
            this.containers = res.containers;
        });

        this.getData();
    }

    onContainerSelect(data: Container) {
        this.selectedContainer = data;
        this.getData(data, this.search);
    }

    onScrollEndTouched() {
        if (this.resourceData.pagination.currentPage
            < this.resourceData.pagination.totalPages) {

            this.resourceService.getResources(this.getParams(
                this.selectedContainer,
                this.search,
                this.resourceData.pagination.currentPage + 1
            )).subscribe(res => {
                this.resourceData.pagination = res.pagination;
                this.resourceData.resources = this.resourceData.resources.concat(res.resources);
            });
        }
    }

    onResourceClick(resource: Resource) {
        this.onResourceSelect.emit({resource: resource});
    }

    changed(text: string) {
        this.searchChanged.next(text);
    }

    private getParams(data?: Container , search?: string, page?: string | number) {
        let params: HttpParams = new HttpParams();
        params = params.append('container', data && data.id)
                       .append('pageSize', '100')
                       .append('search', search)
                       .append('currentPage', page && page.toString())
        this.options.mimeType.forEach(type => {
            params = params.append('mimeType', type);
        });

        return params;
    }

    private getData(data?: Container, search?: string, page?: string) {
        this.resourceService.getResources(this.getParams(
            data,
            search,
            page
        )).subscribe(res => {
            this.resourceData = res;
        });
    }
}

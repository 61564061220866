import {Component, HostBinding, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CmsModalService } from '../../core/modal.service';
import { CmsNotifyService } from '../../core/notify.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';

@Component({
    selector: 'cms-configure-page-widget-article',
    templateUrl: './configure-page-widget-article.component.html'
})
export class ConfigurePageWidgetArticleComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    widget: FormGroup;
    data;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;

    constructor(private fb: FormBuilder,
                private modalService: CmsModalService,
                private notifyService: CmsNotifyService) {
        this.widget = this.fb.group({
            definition: [{}, [validateJson]]
        });
    }

    ngOnInit() {
        this.widget.patchValue({definition: this.data.widget.definition});
    }

    save(widget) {
        const configuringExistingWidget = typeof this.data.widgetIndex === 'number';
        this.notifyService.notify({
            type: configuringExistingWidget ? 'widgetConfigured' : 'newWidgetConfigured',
            data: {
                widget: {
                    widgetId: this.data.widget.id || this.data.widget.widgetId,
                    name: this.data.widget.name,
                    title: this.data.widget.title,
                    definition: widget.definition
                },
                layoutSectionIndex: this.data.layoutSectionIndex,
                widgetIndex: this.data.widgetIndex
            }
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('configure-page-widget');
    }
}

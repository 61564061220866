<div class="g-row mb-1">
  <div class="g-col-xs-6">
    <cms-select [options]="containers"
                [title]="'resources_container' | translate"
                [placeholder]="'resources_container' | translate"
                [nullable]="true"
                (selectChanged)="onContainerSelect($event)">
    </cms-select>
  </div>
  <div class="g-col-xs-6">
    <div class="form-control">
      <div class="g-input g-input-io">
        <input type="text" class="g-input-input"
               [ngModel]='search'
               (ngModelChange)='changed($event)'
               [placeholder]="'common_search' | translate">
        <label class="g-input-label">
          {{ 'common_search' | translate }}
        </label>
      </div>
    </div>
  </div>
</div>
<div class="is-scrollable"
     *ngIf="resourceData && resourceData.resources"
     cmsIscroll
     (onScrollEndTouched)="onScrollEndTouched()">
  <div class="g-row">
    <div class="g-col-xs-4 resource mb-1"
         *ngFor="let resource of resourceData.resources"
         (click)="onResourceClick(resource)">
      <div class="resource-img"
           [ngStyle]="{'background-image': 'url(&quot;' + resource.url + '&quot;)'}">
      </div>
      <p class="resource-title mb-0">
          {{resource.title}}
      </p>
    </div>
  </div>
</div>
<div class="box-shadow-container no-table-data-box mt-0"
     *ngIf="resourceData && resourceData.resources && !resourceData.resources.length">
  <i class="no-table-data-icon g-icon g-icon-info"></i>
  <span class="no-table-data-message">
        {{ 'common_no_data' | translate }}
      </span>
</div>


import { Subject, forkJoin } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsArticlesService } from '../shared/articles.service';
import { CmsCommonService } from '../../shared/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { clone, isEqual } from 'lodash';
import { CmsTranslateService } from '../../shared/translate.service';
var CmsEditArticleTranslationComponent = /** @class */ (function () {
    function CmsEditArticleTranslationComponent(fb, articlesService, commonService, activeRoute, router, notificationsService, notifyService, translateService) {
        this.fb = fb;
        this.articlesService = articlesService;
        this.commonService = commonService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.notificationsService = notificationsService;
        this.notifyService = notifyService;
        this.translateService = translateService;
        this.classes = 'popup-overlay';
        this.$submit = new Subject();
        this.categoryTranslated = true;
        this.fieldsChanged = false;
        this.setSubmitListener();
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.summaryValidationInterpolationParams = {
            field: this.translateService.translate('articles_article_summary').toLowerCase(),
            maximum: '400'
        };
    }
    CmsEditArticleTranslationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.article = this.fb.group({
            title: [{ value: '', disabled: true }, [Validators.required]],
            summary: [{ value: '', disabled: true }],
            content: [{ value: '', disabled: true }]
        });
        this.translatedArticle = this.fb.group({
            name: ['', [Validators.required]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            category: ['', [Validators.required]],
            provider: ['', [Validators.required]],
            locale: ['', [Validators.required]],
            status: ['', [Validators.required]],
            summary: ['', [Validators.maxLength(400)]],
            content: [''],
            featuredImage: ''
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.commonService.getLocales(),
                _this.articlesService.getArticle(params['articleId'], { locale: params.localeId }),
            ]).subscribe(function (_a) {
                var localesData = _a[0], articleData = _a[1];
                _this.article.patchValue({
                    title: articleData.article.title,
                    summary: articleData.article.summary || '',
                    content: articleData.article.content || ''
                });
                _this.articlesService.getArticleCategory(articleData.article.category.toString(), { locale: params.translationLocaleId })
                    .subscribe(function () {
                    _this.articlesService.getArticle(params['articleId'], { locale: params.translationLocaleId }).subscribe(function (articleData1) {
                        _this.translatedArticle.patchValue({
                            name: articleData1['article']['name'],
                            title: articleData1['article']['title'],
                            category: articleData1['article']['category'],
                            locale: params.translationLocaleId,
                            provider: articleData1['article']['provider'],
                            summary: articleData1['article']['summary'] || '',
                            content: articleData1['article']['content'] || '',
                            status: articleData1['article']['status'],
                            featuredImage: articleData1['article']['featuredImage']
                        });
                        _this.initialValues = clone(_this.translatedArticle.value);
                    }, function () {
                        _this.translatedArticle.patchValue({
                            name: articleData.article.name,
                            category: articleData.article.category,
                            locale: params.translationLocaleId,
                            provider: articleData.article.provider,
                            status: articleData.article.status,
                            featuredImage: articleData.article.featuredImage
                        });
                        _this.initialValues = clone(_this.translatedArticle.value);
                    });
                }, function () {
                    _this.categoryTranslated = false;
                    _this.notificationsService.show({
                        type: 'error',
                        content: _this.translateService.translate('articles_category_translation_required_message')
                    });
                });
                var fromLanguage = localesData.locales.find(function (locale) {
                    return locale.id.toString() === _this.activeRoute.snapshot.params['localeId'];
                });
                _this.fromLanguageTitle = fromLanguage.title;
                var toLanguage = localesData.locales.find(function (locale) {
                    return locale.id.toString() === _this.activeRoute.snapshot.params['translationLocaleId'];
                });
                _this.toLanguageTitle = toLanguage.title;
                _this.popupTitleInterpolationParams = {
                    from: fromLanguage.title,
                    to: toLanguage.title
                };
            });
        });
        this.translatedArticle.valueChanges.pipe(debounceTime(200))
            .subscribe(function (article) {
            _this.fieldsChanged = !isEqual(article, _this.initialValues);
        });
    };
    CmsEditArticleTranslationComponent.prototype.onSubmit = function (_a) {
        var _this = this;
        var value = _a.value;
        var articleId = this.activeRoute.snapshot.params['articleId'];
        this.articlesService.editArticle(value, articleId).subscribe(function () {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('articles_article_translated')
            });
            _this.notifyService.notify('article');
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsEditArticleTranslationComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    CmsEditArticleTranslationComponent.prototype.setSubmitListener = function () {
        var _this = this;
        this.$submit.pipe(throttleTime(500))
            .subscribe(function () {
            _this.onSubmit(_this.translatedArticle);
        });
    };
    return CmsEditArticleTranslationComponent;
}());
export { CmsEditArticleTranslationComponent };

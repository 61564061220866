import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SITE_CONFIG} from '../../core/site-config.service';
import { CmsObjectHelper } from 'app/shared/object-helper.service';
import {Pagination} from '../../../types';
import {ISiteConfig} from '../../core/site-config.interface';

export interface AppTemplate {
    id: number;
    name: string;
    title: string;
    nameTemplate?: string;
}

export interface AppTemplatesResponse {
    appTemplates: Array<AppTemplate>;
    pagination: Pagination;
}

@Injectable()
export class CmsAppTemplatesService {

    constructor(
        private http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    /**
     * Get list of application templates
     *
     * @param {Object} params
     * @param {number} params.currentPage - Displayed page number
     * @param {number} params.pageSize - Number of items per page
     * @return {Observable<R>}
     */
    getAppTemplates (params?: Object): Observable<{appTemplates: Array<AppTemplate>, pagination: Pagination}> {
        return this.http.get<{appTemplates: Array<AppTemplate>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/app-templates`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    getAppTemplate (id: number): Observable<{appTemplate: AppTemplate}> {
        return this.http.get<{appTemplate: AppTemplate}>(
            `${this.config.gravityCmsApi}/cms/app-templates/${id}`
        );
    }

    createAppTemplate (appTemplate: AppTemplate): Observable<{appTemplate: AppTemplate}> {
        return this.http.post<{appTemplate: AppTemplate}>(
            `${this.config.gravityCmsApi}/cms/app-templates`,
            appTemplate
        );
    }

    editAppTemplate (appTemplate: AppTemplate, appId: number): Observable<{app: AppTemplate}> {
        return this.http.put<{app: AppTemplate}>(
            `${this.config.gravityCmsApi}/cms/app-templates/${appId}`,
            appTemplate
        );
    }
}

import {AfterViewInit, Directive, ElementRef, HostListener, Input, ViewChild} from '@angular/core';

@Directive({
    selector: '[cmsTooltip]',
    exportAs: 'cms-tooltip'
})

// TODO: make it structural direcitve that don't use direct dom manipulation
export class CmsTooltipDirective implements AfterViewInit {
    @Input() tooltipContent: string;
    @ViewChild('t', {static: false})

    el: HTMLElement;
    tooltip: HTMLElement;

    constructor(
        elementRef: ElementRef
    ) {
        this.el = elementRef.nativeElement;
    }

    ngAfterViewInit() {
        this.tooltip = this.createTooltip();
    }

    @HostListener('mouseover', ['$event'])
    private open () {
        this.tooltip.style.display = 'block';
    }

    @HostListener('mouseout', ['$event'])
    private close () {
        this.tooltip.style.display = 'none';
    }

    private createTooltip () {
        const tooltipElement: HTMLElement = document.createElement('cms-tooltip');
            tooltipElement.style.display = 'none';
            tooltipElement.style.bottom = this.el.offsetHeight.toString();
            tooltipElement.innerHTML = `<div>
                <div class='tooltip-contents'>${this.tooltipContent}</div>
                <div class='tooltip-down-arrow'></div>
            </div>`;

        this.el.appendChild(tooltipElement);

        return tooltipElement;
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/notifications/notifications.component.ngfactory";
import * as i4 from "./shared/notifications/notifications.component";
import * as i5 from "./core/notifications.service";
import * as i6 from "./shared/modal/modal.component.ngfactory";
import * as i7 from "./shared/modal/modal.component";
import * as i8 from "./core/modal.service";
import * as i9 from "./app.component";
import * as i10 from "angulartics2/ga";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 5, "router-outlet", [["name", "popup"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "popup"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "cms-notifications", [], null, null, null, i3.View_CmsNotificationsComponent_0, i3.RenderType_CmsNotificationsComponent)), i1.ɵdid(5, 49152, null, 0, i4.CmsNotificationsComponent, [i5.CmsNotificationsService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "cms-modal", [], [[8, "className", 0]], null, null, i6.View_CmsModalComponent_0, i6.RenderType_CmsModalComponent)), i1.ɵdid(7, 49152, null, 0, i7.CmsModalComponent, [i8.CmsModalService, i1.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).modalOpen; _ck(_v, 6, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cms-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [i10.Angulartics2GoogleAnalytics], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("cms-app", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./notification/notification.component.ngfactory";
import * as i2 from "./notification/notification.component";
import * as i3 from "@angular/common";
import * as i4 from "./notifications.component";
import * as i5 from "../../core/notifications.service";
var styles_CmsNotificationsComponent = [];
var RenderType_CmsNotificationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsNotificationsComponent, data: {} });
export { RenderType_CmsNotificationsComponent as RenderType_CmsNotificationsComponent };
function View_CmsNotificationsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-notification", [], null, null, null, i1.View_CmsNotificationComponent_0, i1.RenderType_CmsNotificationComponent)), i0.ɵdid(1, 573440, null, 0, i2.CmsNotificationComponent, [], { notification: [0, "notification"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CmsNotificationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsNotificationsComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CmsNotificationsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsNotificationsComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CmsNotificationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-notifications", [], null, null, null, View_CmsNotificationsComponent_0, RenderType_CmsNotificationsComponent)), i0.ɵdid(1, 49152, null, 0, i4.CmsNotificationsComponent, [i5.CmsNotificationsService], null, null)], null, null); }
var CmsNotificationsComponentNgFactory = i0.ɵccf("cms-notifications", i4.CmsNotificationsComponent, View_CmsNotificationsComponent_Host_0, {}, {}, []);
export { CmsNotificationsComponentNgFactory as CmsNotificationsComponentNgFactory };

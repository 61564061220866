var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { CmsArticlesService } from '../../articles/shared/articles.service';
import { CmsNotificationsService } from '../../core/notifications.service';
var CmsArticlePickerComponent = /** @class */ (function () {
    function CmsArticlePickerComponent(articlesService, notificationsService) {
        var _this = this;
        this.articlesService = articlesService;
        this.notificationsService = notificationsService;
        this.onArticleSelect = new EventEmitter();
        this.handleResponseError = function (error) {
            _this.notificationsService.showError(error);
        };
    }
    CmsArticlePickerComponent.prototype.ngOnInit = function () {
        if (this.article) {
            this.loadArticles(this.article.category, this.article.id);
            this.loadCategories(this.article.category);
        }
        else {
            this.loadCategories();
        }
    };
    CmsArticlePickerComponent.prototype.articleSelected = function (article) {
        this.onArticleSelect.emit({ article: article });
    };
    CmsArticlePickerComponent.prototype.onCategorySelect = function (category) {
        this.selectedCategory = category;
        this.loadArticles(category.id, this.selectedArticle && this.selectedArticle.id);
    };
    CmsArticlePickerComponent.prototype.loadArticles = function (category, selectedArticleId) {
        var _this = this;
        this.articlesService.getAllArticles({
            category: category,
            extend: '',
        }).subscribe(function (articlesData) {
            _this.articles = articlesData.articles;
            _this.selectedArticle = _this.articles.find(function (article) { return article.id === selectedArticleId; });
        }, this.handleResponseError);
    };
    CmsArticlePickerComponent.prototype.loadCategories = function (selectedCategoryId) {
        var _this = this;
        this.articlesService.getAllArticleCategories({
            extend: 'company,app'
        }).subscribe(function (categoriesData) {
            _this.categories = categoriesData.categories.map(function (category) {
                return __assign({}, category, { title: category.title + " - " + (category['app'] ? category['app']['title'] : category['company']['title']) });
            });
            if (selectedCategoryId) {
                _this.selectedCategory = _this.categories.find(function (category) { return category.id === selectedCategoryId; });
            }
        }, this.handleResponseError);
    };
    return CmsArticlePickerComponent;
}());
export { CmsArticlePickerComponent };

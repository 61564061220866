import {AfterViewInit, Renderer2, OnChanges, SimpleChanges, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[cmsImgPreview]',
    exportAs: 'cms-img-preview'
})

export class CmsTableImgPreviewDirective implements AfterViewInit, OnChanges {
    @Input() imgUrl: string;
    @Input() cmsImgCentered: boolean;

    el: HTMLElement;
    preview: HTMLElement;
    renderer: Renderer2;

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        this.el = elementRef.nativeElement;
        this.renderer = renderer;
    }

    ngAfterViewInit() {
        this.preview = this.createPreview();
        this.attachClasses(this.cmsImgCentered);
    }

    ngOnChanges (changes: SimpleChanges) {
        this.attachClasses(this.cmsImgCentered);
    }

    @HostListener('mouseenter', ['$event'])
    private open () {
        this.preview.style.display = 'block';
    }

    @HostListener('mouseleave', ['$event'])
    private close () {
        this.preview.style.display = 'none';
    }

    private attachClasses(classActive: boolean) {
        if (!this.preview) {
            return false;
        }

        if (classActive) {
            this.renderer.addClass(this.preview, 'centered');
        } else {
            this.renderer.removeClass(this.preview, 'centered');
        }
    }

    private createPreview () {
        const isSvg = this.imgUrl.match(/^.*\.svg$/);
        const svgImgStyle = 'style="width: 20em"'
        const imgStyle = isSvg ? svgImgStyle : '';
        const previewElement: HTMLElement = document.createElement('cms-img-preview');

        previewElement.style.display = 'none';
        previewElement.innerHTML = `<img src=${this.imgUrl} ${imgStyle}>`;

        this.el.appendChild(previewElement);

        return previewElement;
    }
}

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">{{'pages_widgets_configure_widget' | translate}}</div>
  </header>
  <div class="g-popup-body">
    <form class="popup-form" novalidate [formGroup]="widget">
      <div class="form-body">
        <div class="g-row">
          <div class="g-col-sm-12 g-col-md-12">
            <div class="form-control">
              <div class="g-input g-input-io"
                   [ngClass]="{filled: widget.get('definition').value,
                               invalid: widget.get('definition').hasError('jsonParseError') && !widget.get('definition').pristine}">
                <cms-json-editor formControlName="definition"></cms-json-editor>
                <label class="g-input-validation"
                       *ngIf="widget.get('definition').hasError('jsonParseError')">
                  {{'common_validation_parse_error' | translate}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="form-footer-buttons">
          <button type="submit"
                  class="form-footer-button g-button g-button-earth"
                  (click)="save(widget.value)"
                  [disabled]="widget.invalid">
            <span class="g-button-title">{{'common_save' | translate}}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

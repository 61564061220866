import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Moment} from 'moment-mini';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

declare var moment: any;

@Component({
    selector: 'cms-datetime-picker',
    templateUrl: 'datetime-picker.component.html',
    styleUrls: ['datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnInit {
    @Input() startDateTime: Date;
    @Input() endDateTime: Date;
    @Output() datesSelected = new EventEmitter<{ startDateTime: string, endDateTime: string }>();
    open: boolean = false;
    weeks: Array<Array<{ date: Moment, selected: boolean, between: boolean, outside: boolean }>>;
    weekdays: Array<string>;
    currentYear: number;
    currentMonthName: string;
    timeForm: FormGroup;
    rangeSelected: boolean;
    dateTimeRange: string = '';
    currentMonth: Moment;
    private currentSelectionType: 'startDate' | 'endDate' = 'startDate';
    private startDate;
    private endDate;


    private static generateWeekDays(currentMonth) {
        const firstDayOfWeek = moment.localeData().firstDayOfWeek();
        const weekdays = [];

        for (let i = 0; i < 7; i += 1) {
            weekdays.push(currentMonth.clone().day((i + firstDayOfWeek) % 7).format('ddd'));
        }

        return weekdays;
    }

    constructor(
        private fb: FormBuilder
    ) {
        this.currentMonth = moment();
        this.currentMonthName = this.currentMonth.format('MMMM')
        this.currentYear = this.currentMonth.year();
        this.weekdays = DatetimePickerComponent.generateWeekDays(this.currentMonth);
        this.timeForm = this.fb.group({
            startTime: [null, [Validators.required]],
            endTime: [null, [Validators.required]],
        });
    }

    ngOnInit() {
        if (this.startDateTime && this.endDateTime) {
            const startDateTime: Moment = moment(this.startDateTime);
            const endDateTime: Moment = moment(this.endDateTime);

            this.rangeSelected = true;
            this.dateTimeRange = `${startDateTime.format('lll')} - ${endDateTime.format('lll')}`;
            this.startDate = startDateTime;
            this.endDate = endDateTime;
            this.timeForm.patchValue({
                startTime: startDateTime.format('HH:mm'),
                endTime: endDateTime.format('HH:mm')
            });
        }
        this.weeks = this.generateMonthWeeks();
    }

    openDateTimePicker() {
        this.open = true;
    }

    previousMonth() {
        this.currentMonth.add(-1, 'month');
        this.currentMonthName = this.currentMonth.format('MMMM')
        this.currentYear = this.currentMonth.year();
        this.weeks = this.generateMonthWeeks();
    }

    nextMonth() {
        this.currentMonth.add(1, 'month');
        this.currentMonthName = this.currentMonth.format('MMMM')
        this.currentYear = this.currentMonth.year();
        this.weeks = this.generateMonthWeeks()
    }

    selectDay(day: Moment) {
        this[this.currentSelectionType] = day;
        this.rangeSelected = this.currentSelectionType === 'endDate';

        if (this.currentSelectionType === 'startDate') {
            this.endDate = null;
            this.currentSelectionType = 'endDate'
        } else if (this.currentSelectionType === 'endDate') {
            this.currentSelectionType = 'startDate';

            if (this.endDate.isBefore(this.startDate)) {
                const t = this.startDate;

                this.startDate = this.endDate;
                this.endDate = t;
            }
        }
        this.weeks = this.generateMonthWeeks();
    }

    cancel() {
        this.open = false;
    }

    save() {
        const [startTimeHours, startTimeMinutes] = this.timeForm.value.startTime.split(':');
        const [endTimeHours, endTimeMinutes] = this.timeForm.value.endTime.split(':');
        const startDateTime = this.startDate.clone().hour(startTimeHours).minute(startTimeMinutes);
        const endDateTime = this.endDate.clone().hour(endTimeHours).minute(endTimeMinutes);

        this.dateTimeRange = `${startDateTime.format('lll')} - ${endDateTime.format('lll')}`;

        this.datesSelected.emit({
            startDateTime: startDateTime.utc().toISOString(),
            endDateTime: endDateTime.utc().toISOString()
        })

        this.open = false;
    }

    private generateMonthWeeks() {
        const month = this.currentMonth;
        const firstDayOfWeek = moment.localeData().firstDayOfWeek();

        const firstOfMonth = month.clone().startOf('month');
        const lastOfMonth = month.clone().endOf('month');
        const prevDays = (firstOfMonth.day() + 7 - firstDayOfWeek) % 7;
        const nextDays = (firstDayOfWeek + 6 - lastOfMonth.day()) % 7;
        const firstDay = firstOfMonth.clone().subtract(prevDays, 'day');
        const lastDay = lastOfMonth.clone().add(nextDays, 'day');
        const totalDays = lastDay.diff(firstDay, 'days') + 1;
        const currentDay = firstDay.clone();
        const weeksInMonth = [];

        for (let i = 0; i < totalDays; i += 1) {
            const day = currentDay.clone();

            if (i % 7 === 0) {
                weeksInMonth.push([]);
            }

            weeksInMonth[weeksInMonth.length - 1].push({
                date: day,
                selected: day.isSame(this.startDate, 'day') || day.isSame(this.endDate, 'day'),
                between: this.startDate && this.endDate && day.isBetween(this.startDate, this.endDate, 'day'),
                outside: this.currentMonth.month() !== day.month()
            });

            currentDay.add(1, 'day');
        }

        return weeksInMonth;
    }
}

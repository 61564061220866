import { Subject, forkJoin } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsArticlesService } from '../shared/articles.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import { clone, cloneDeep, isEqual, sortBy } from 'lodash';
import { CmsNotificationsService } from 'app/core/notifications.service';
import { CmsTranslateService } from '../../shared/translate.service';
var CmsEditArticleComponent = /** @class */ (function () {
    function CmsEditArticleComponent(fb, articlesService, commonService, activeRoute, router, notificationsService, notifyService, translateService) {
        var _this = this;
        this.fb = fb;
        this.articlesService = articlesService;
        this.commonService = commonService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.notificationsService = notificationsService;
        this.notifyService = notifyService;
        this.translateService = translateService;
        this.classes = 'popup-overlay';
        this.$submit = new Subject();
        this.fieldsChanged = false;
        this.showErrorNotification = function (error) {
            _this.notificationsService.showError(error);
        };
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '120',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.summaryValidationInterpolationParams = {
            field: this.translateService.translate('articles_article_summary').toLowerCase(),
            maximum: '400'
        };
    }
    CmsEditArticleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.article = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(120), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            status: ['', [Validators.required]],
            category: ['', [Validators.required]],
            provider: ['', [Validators.required]],
            locale: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            company: '',
            summary: ['', [Validators.maxLength(400)]],
            content: [''],
            featuredImage: ''
        });
        this.article.valueChanges.pipe(debounceTime(200))
            .subscribe(function (article) {
            _this.fieldsChanged = !isEqual(article, _this.initialValues);
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.commonService.getLocales(),
                _this.articlesService.getArticleProviders(),
                _this.articlesService.getArticle(params['id'], { locale: params['localeId'] }),
                _this.articlesService.getArticleStatuses()
            ]).subscribe(function (_a) {
                var localesData = _a[0], providersData = _a[1], articleData = _a[2], statusesData = _a[3];
                var categoryParams = {
                    locale: articleData.article.locale,
                    pageSize: 1000
                };
                if (articleData.article.app) {
                    categoryParams.app = articleData.article.app;
                }
                else if (articleData.article.company) {
                    categoryParams.company = articleData.article.company;
                }
                _this.articlesService.getArticleCategories(categoryParams).subscribe(function (categoriesData) {
                    _this.categories = sortBy(categoriesData['categories'], 'title');
                    var selectedCategory = _this.categories.find(function (category) {
                        return category.id === articleData['article']['category'];
                    });
                    _this.article.patchValue({
                        category: selectedCategory
                    });
                    _this.initialValues = clone(_this.article.value);
                });
                _this.locales = localesData['locales'];
                _this.providers = providersData['providers'];
                _this.statuses = statusesData.articleStatuses;
                var selectedLocale = _this.locales.find(function (locale) {
                    return locale.id === articleData['article']['locale'];
                });
                var selectedProvider = _this.providers.find(function (provider) {
                    return provider.id === articleData['article']['provider'];
                });
                var selectedStatus = _this.statuses.find(function (status) {
                    return status.id === articleData.article.status;
                });
                _this.article.patchValue({
                    name: articleData['article']['name'],
                    title: articleData['article']['title'],
                    status: selectedStatus,
                    locale: selectedLocale,
                    provider: selectedProvider,
                    summary: articleData['article']['summary'] || '',
                    content: articleData['article']['content'] || '',
                    featuredImage: articleData['article']['featuredImage']
                });
                _this.initialValues = clone(_this.article.value);
            }, _this.showErrorNotification);
        });
    };
    CmsEditArticleComponent.prototype.onSubmit = function () {
        var _this = this;
        // getRawValue is used when there are disabled fields in form
        var value = this.article.getRawValue();
        var article = cloneDeep(value);
        article.locale = value.locale.id;
        article.provider = value.provider.id;
        article.category = value.category.id;
        article.status = value.status.id;
        this.articlesService.editArticle(article, this.activeRoute.snapshot.params['id']).subscribe(function () {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('articles_article_edited')
            });
            _this.notifyService.notify('article');
            _this.closePopup();
        }, this.showErrorNotification);
    };
    CmsEditArticleComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    CmsEditArticleComponent.prototype.setSubmitListener = function () {
        var _this = this;
        this.$submit.pipe(throttleTime(500))
            .subscribe(function () {
            _this.onSubmit();
        });
    };
    return CmsEditArticleComponent;
}());
export { CmsEditArticleComponent };

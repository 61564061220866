import { Component, OnInit } from '@angular/core';
import {CmsModalService} from 'app/core/modal.service';
import {Resource} from '../../../resources/shared/resources.service';
import {CmsNotifyService} from '../../../core/notify.service';
import {CmsResourcePickerOptions} from '../resource-picker.component';

@Component({
  selector: 'cms-resource-picker-modal',
  templateUrl: './resource-picker-modal.component.html',
  styleUrls: ['./resource-picker-modal.component.scss']
})
export class CmsResourcePickerModalComponent implements OnInit {
  data: {
    options: CmsResourcePickerOptions
  };
  constructor(
      private modalService: CmsModalService,
      private notifyService: CmsNotifyService
  ) { }

  ngOnInit() {
  }

  /**
   *
   * @param {Object} data
   * @param {Resource} data.resource
   */
  onResourceSelect(data: {resource: Resource}) {
    this.notifyService.notify({
      type: 'resource-picker-modal-pick',
      data: data.resource
    });

    this.closePopup();
  }

  closePopup() {
    this.modalService.close('resource-picker-modal');
  }

}

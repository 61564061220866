import { EventEmitter, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CmsIscrollDirective } from '../iscroll/iscroll.directive';
var CmsSelectComponent = /** @class */ (function () {
    function CmsSelectComponent() {
        var _this = this;
        this.selected = null;
        this.selectOpened = false;
        this.displayKey = 'title';
        this.uniqueKey = 'id';
        this.options = [];
        this.placeholder = 'Select option';
        this.title = '';
        this.disabled = false;
        this.nullable = false;
        this.selectChanged = new EventEmitter();
        this._searchTerm = '';
        this.startingItems = [];
        this.propagateChange = function (data) {
            _this.selectChanged.emit(data);
            _this.propagateFormControlChange(data);
        };
    }
    CmsSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes['options']
            && changes['options'].currentValue !== changes['options'].previousValue) {
            // When options are changed, iscroll sometimes moves content of CmsSelectComponent's list up and the list
            // seems empty this method is called to return list to correct position
            this.cmsIscroll.moveIntoView();
            this.startingItems = this.options.concat();
        }
    };
    CmsSelectComponent.prototype.open = function () {
        if (this.disabled) {
            return;
        }
        this.selectOpened = true;
    };
    CmsSelectComponent.prototype.close = function () {
        if (this.disabled) {
            return;
        }
        this.selectOpened = false;
    };
    CmsSelectComponent.prototype.onSelectOption = function (option, event) {
        event.stopPropagation();
        this.setActiveOption(option);
        this.resetSearch();
        this.close();
    };
    CmsSelectComponent.prototype.setActiveOption = function (optionSelected) {
        this.selected = optionSelected;
        this.propagateChange(this.selected);
    };
    CmsSelectComponent.prototype.clearSelectionIfNeeded = function (event) {
        if ((this.selected && this.selected[this.displayKey]) && this._searchTerm !== this.selected[this.displayKey]) {
            this.clearSelection(event);
        }
    };
    CmsSelectComponent.prototype.clearSelection = function (event) {
        event.stopPropagation();
        this.selected = null;
        this.propagateChange(this.selected);
        this.resetSearch();
    };
    CmsSelectComponent.prototype.propagateFormControlChange = function (data) { };
    CmsSelectComponent.prototype.registerOnChange = function (fn) {
        this.propagateFormControlChange = fn;
    };
    CmsSelectComponent.prototype.registerOnTouched = function () { };
    CmsSelectComponent.prototype.writeValue = function (value) {
        if (value) {
            this.selected = value;
        }
        else {
            this.selected = null;
        }
    };
    CmsSelectComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    CmsSelectComponent.prototype.filterItems = function () {
        var _this = this;
        var term = this._searchTerm;
        if (!this._searchTerm) {
            this.clearSelection(event);
        }
        if (!this.selectOpened) {
            this.selectOpened = true;
        }
        if (!this.selected) {
            this.options = this.startingItems.filter(function (item) {
                return item[_this.displayKey].toLowerCase().indexOf(term.toLowerCase()) >= 0;
            });
        }
    };
    CmsSelectComponent.prototype.resetSearch = function () {
        this.searchTerm = '';
        this.options = this.startingItems;
    };
    Object.defineProperty(CmsSelectComponent.prototype, "searchTerm", {
        get: function () {
            return this._searchTerm ?
                this._searchTerm :
                this.selected ?
                    this.selected[this.displayKey] :
                    '';
        },
        set: function (value) {
            this._searchTerm = value;
        },
        enumerable: true,
        configurable: true
    });
    return CmsSelectComponent;
}());
export { CmsSelectComponent };

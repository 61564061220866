import { PipeTransform } from '@angular/core';
import { CmsTranslateService } from '../translate.service';
var CmsTranslatePipe = /** @class */ (function () {
    function CmsTranslatePipe(_translateService) {
        this._translateService = _translateService;
    }
    CmsTranslatePipe.prototype.transform = function (value) {
        var interpolationParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            interpolationParams[_i - 1] = arguments[_i];
        }
        return this._translateService.translate(value, interpolationParams[0]);
    };
    return CmsTranslatePipe;
}());
export { CmsTranslatePipe };

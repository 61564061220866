<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'settings_confirm_rollback_action' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <div class="content">
            {{'fragment_confirm_rollback' | translate}}
        </div>
        <div class="form-footer">
            <div class="form-footer-buttons">
                <button class="form-footer-button g-button g-button-earth" type="submit" (click)="closePopup()">
                    <span class="g-button-title">{{'common_cancel' | translate}}</span>
                </button>
                <button class="form-footer-button g-button g-button-earth" type="submit" (click)="rollback()">
                    <span class="g-button-title">{{'common_confirm' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
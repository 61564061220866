import {Component, Input} from '@angular/core';
import {CmsTabsComponent} from './tabs.component';

@Component({
  selector: 'cms-tab',
  template:
    `<div [hidden]="!active">
      <ng-content></ng-content>
    </div>`,
  styles: [':host {display: block}'],
})

export class CmsTabComponent {
  @Input() tabTitle: string;
  active;

  constructor(tabs: CmsTabsComponent) {
    tabs.addTab(this);
  }
}

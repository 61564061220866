import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsModalService } from '../../core/modal.service';
import { UserService } from '../../core/user.service';
import { map } from 'lodash';
import { RESOURCE_FILE_TYPES } from '../../resources/shared/resources.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { LIST_ITEM_TYPE } from '../lists.service';
var CmsAddListItemComponent = /** @class */ (function () {
    function CmsAddListItemComponent(fb, modalService, notifyService, userService, translateService) {
        this.fb = fb;
        this.modalService = modalService;
        this.notifyService = notifyService;
        this.userService = userService;
        this.translateService = translateService;
        this.imageUrl = '';
        this.listItemType = LIST_ITEM_TYPE;
        this.resourceFileTypes = RESOURCE_FILE_TYPES;
        this.resourceSelected = false;
        this.listItemTypes = [{
                id: LIST_ITEM_TYPE.article,
                title: this.translateService.translate('articles_article')
            }, {
                id: LIST_ITEM_TYPE.resource,
                title: this.translateService.translate('resources_resource')
            }];
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };
        this.resourcePickerOptions = {
            mimeType: map(this.userService.data.modules.resources.allowedFileFormats, function (format) { return (format.mimeType); })
        };
    }
    CmsAddListItemComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            listItemType: ['', [Validators.required]],
            title: { value: '', disabled: true },
            resource: [''],
            article: [''],
            active: [{ value: true, disabled: true }]
        });
        this.createFormValueChangesSubscriptions();
    };
    CmsAddListItemComponent.prototype.openResourcePicker = function () {
        this.resourceSelected = false;
    };
    CmsAddListItemComponent.prototype.onArticlesSelect = function (_a) {
        var article = _a.article;
        this.form.patchValue({
            article: article,
            title: article.title,
        });
        this.form.get('active').enable();
        this.resourceSelected = false;
    };
    CmsAddListItemComponent.prototype.onResourceSelect = function (_a) {
        var resource = _a.resource;
        this.form.patchValue({
            resource: resource,
            title: resource.title
        });
        this.form.get('active').enable();
        this.imageUrl = resource['thumb-large-url'] || resource.url || '';
        this.resourceSelected = true;
    };
    CmsAddListItemComponent.prototype.addListItem = function () {
        var _a;
        var listItemType = this.form.get('listItemType').value.id;
        this.notifyService.notify({
            type: 'add-list-item',
            data: (_a = {
                    active: this.form.get('active').value
                },
                _a[listItemType] = this.form.get(listItemType).value,
                _a.title = this.form.get('title').value,
                _a.listItemType = listItemType,
                _a),
        });
        this.closePopup();
    };
    CmsAddListItemComponent.prototype.closePopup = function () {
        this.modalService.close('add-list-item');
    };
    CmsAddListItemComponent.prototype.createFormValueChangesSubscriptions = function () {
        var _this = this;
        this.form.controls['listItemType'].valueChanges.subscribe(function (selectedListItemType) {
            if (selectedListItemType) {
                _this.listItemTypes.forEach(function (_a) {
                    var listItemType = _a.id;
                    if (selectedListItemType.id !== listItemType) {
                        _this.form.controls[listItemType].clearValidators();
                    }
                    else {
                        _this.form.controls[listItemType].setValidators([Validators.required]);
                    }
                    _this.form.controls[listItemType].updateValueAndValidity();
                });
            }
        });
    };
    return CmsAddListItemComponent;
}());
export { CmsAddListItemComponent };

import {Pipe, PipeTransform} from '@angular/core';
import {CmsTranslateService} from '../translate.service';

@Pipe({ name: 'translate' })
export class CmsTranslatePipe implements PipeTransform {
    constructor(private _translateService: CmsTranslateService) {}

    transform(value: string, ...interpolationParams: any[]) {
        return this._translateService.translate(value, interpolationParams[0]);
    }
}

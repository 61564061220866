import { Pipe, PipeTransform  } from '@angular/core';

export type ByteUnit = 'B' | 'KB' | 'MB' | 'GB' | 'TB';

@Pipe({
    name: 'bytes'
})
export class CmsBytesPipe implements PipeTransform {

    private static formats: { [key: string]: { max: number, prev?: ByteUnit }} = {
        'B': { max: 1024 },
        'KB': { max: Math.pow(1024, 2), prev: 'B' },
        'MB': { max: Math.pow(1024, 3), prev: 'KB' },
        'GB': { max: Math.pow(1024, 4), prev: 'MB' },
        'TB': { max: Number.MAX_SAFE_INTEGER, prev: 'GB' }
    };

    private static isNumberFinite (value: any): value is number {
        return CmsBytesPipe.isNumber(value) && isFinite(value);
    }

    private static isNumber (value: any): value is number {
        return typeof value === 'number';
    }

    private static isPositive (value: number): boolean {
        return value >= 0;
    }

    private static isInteger (value: number): boolean {
        return (value % 1) === 0;
    }

    private static toDecimal (value: number, decimal: number): number {
        return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
    }

    transform (input: any, decimal: number = 0, from: ByteUnit = 'B'): any {
        if (!(CmsBytesPipe.isNumberFinite(input) &&
                CmsBytesPipe.isNumberFinite(decimal) &&
                CmsBytesPipe.isInteger(decimal) &&
                CmsBytesPipe.isPositive(decimal))) {
            return input;
        }

        let bytes = input;
        let unit = from;
        while (unit !== 'B') {
            bytes *= 1024;
            unit = CmsBytesPipe.formats[unit].prev;
        }

        for (const key in CmsBytesPipe.formats) {

            if (CmsBytesPipe.formats.hasOwnProperty(key)) {
                const format = CmsBytesPipe.formats[key];
                if (bytes < format.max) {

                    const prev = CmsBytesPipe.formats[format.prev];

                    const result = prev ?
                        CmsBytesPipe.toDecimal(bytes / prev.max, decimal) :
                        CmsBytesPipe.toDecimal(bytes, decimal);

                    return `${result} ${key}`;
                }
            }

        }
    }
}

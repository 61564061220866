<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">{{'pages_widgets_add_widget' | translate}}</div>
  </header>
  <div class="g-popup-body">
    <div class="add-page-widget-body">
      <div class="g-search">
        <input type="text" class="g-search-input" [placeholder]="'common_search' | translate" (keyup)="filterWidgets($event.target.value)">
        <i class="g-search-icon g-icon g-icon-search"></i>
      </div>
      <div class="widgets">
        <div class="widget"
             *ngFor="let widget of filteredWidgets"
             (click)="configurePageWidget(widget)"
             [ngClass]="{active: configuredWidgetId === widget.id}"
        >
          <i class="widget-icon g-icon" [ngClass]="widget.name"></i>
          <span class="widget-name">{{widget.title}}</span>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <div class="popup-footer-buttons">
        <button type="submit"
                class="popup-footer-button g-button g-button-earth"
                (click)="save()"
                [disabled]="!configuredWidgetId">
          <span class="g-button-title">{{'common_save' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
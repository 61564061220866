<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'articles_edit_article' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="article" (ngSubmit)="$submit.next($event)">
            <div class="form-body">
                <div class="g-row">
                    <div class="g-col-sm-12 g-col-md-4">
                        <div class="form-control">
                            <div class="g-input g-input-io"
                                 [ngClass]="{invalid: (article.get('name').hasError('required') || article.get('name').hasError('maxlength') || article.get('name').hasError('pattern')) && !article.get('name').pristine}">
                                <input type="text" class="g-input-input" [placeholder]="'common_name' | translate" formControlName="name">
                                <label class="g-input-label">{{ 'common_name' | translate }}</label>
                                <label class="g-input-validation">
                                    <span *ngIf="article.get('name').hasError('required')">
                                        {{ 'common_validation_field_required' | translate }}
                                    </span>
                                    <span *ngIf="article.get('name').hasError('maxlength') && !article.get('name').hasError('pattern')">
                                        {{'common_validation_maximum_field_length' | translate:nameValidationInterpolationParams}}
                                    </span>
                                    <span *ngIf="article.get('name').hasError('pattern')">
                                        {{'common_validation_valid_characters' | translate:nameValidationInterpolationParams}}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="form-control">
                            <div class="g-input g-input-io"
                                 [ngClass]="{invalid: (article.get('title').hasError('required') || article.get('title').hasError('maxlength')) && !article.get('title').pristine}">
                                <input type="text" class="g-input-input" [placeholder]="'common_title' | translate" formControlName="title">
                                <label class="g-input-label">{{ 'common_title' | translate }}</label>
                                <label class="g-input-validation">
                                    <span *ngIf="article.get('title').hasError('required')">
                                        {{ 'common_validation_field_required' | translate }}
                                    </span>
                                    <span *ngIf="article.get('title').hasError('maxlength')">
                                        {{'common_validation_maximum_field_length' | translate:titleValidationInterpolationParams}}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="form-control">
                            <cms-select formControlName="locale"
                                        [options]="locales"
                                        [placeholder]="'common_language' | translate"
                                        [title]="'common_language' | translate"
                            ></cms-select>
                        </div>
                        <div class="form-control">
                            <cms-select formControlName="category"
                                        [options]="categories"
                                        [placeholder]="'articles_category' | translate"
                                        [title]="'articles_category' | translate"
                            ></cms-select>
                        </div>
                        <div class="form-control">
                            <cms-select formControlName="status"
                                        [options]="statuses"
                                        [placeholder]="'common_status' | translate"
                                        [title]="'common_status' | translate"
                            ></cms-select>
                        </div>
                        <div class="form-control">
                            <cms-select
                                    formControlName="provider"
                                    [options]="providers"
                                    [placeholder]="'Provider'"
                                    [title]="'Provider'"

                            ></cms-select>
                        </div>
                        <div class="form-control">
                            <div class="g-input g-input-io"
                                 [ngClass]="{invalid: article.get('featuredImage').hasError('required') && !article.get('featuredImage').pristine}">
                                <input type="url" class="g-input-input" placeholder="Featured image URL" formControlName="featuredImage">
                                <label class="g-input-label">
                                    {{'articles_featured_image_url' | translate}}
                                </label>
                                <label class="g-input-validation">
                                    {{ 'common_validation_field_required' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="g-col-sm-12 g-col-md-8">
                        <div class="form-control">
                            <div class="g-input g-input-io"
                                 [ngClass]="{invalid: (article.get('summary').hasError('maxlength') && !article.get('summary').pristine)}">
                                <textarea id="summary"
                                          class="g-input-textarea ta-2"
                                          formControlName="summary">
                                </textarea>
                                <label for="summary" class="g-input-label">
                                    {{'articles_article_summary' | translate}}
                                </label>
                                <label class="g-input-validation">
                                    <span *ngIf="article.get('summary').hasError('maxlength')">
                                        {{'common_validation_maximum_field_length' | translate:summaryValidationInterpolationParams}}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="form-control">
                            <cms-tinymce formControlName="content"
                                         [instanceConfig]="{height: '304'}">
                            </cms-tinymce>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth"
                            [disabled]="article.invalid || !fieldsChanged">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
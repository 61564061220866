import { Inject, Injectable } from '@angular/core';

declare var isMobile: any;

@Injectable()
export class CmsIsMobileService {
    private _isMobile;
    private _isTouchDevice: boolean;

    constructor(@Inject('Window') private window: Window) {
        this._isMobile = isMobile;
        this._isTouchDevice = 'ontouchstart' in window;
    }

    isMobile() {
        return this._isMobile;
    }

    isTouchDevice() {
        return this._isTouchDevice;
    }
}

import { Component } from '@angular/core';
import { CmsModalService } from 'app/core/modal.service';
import { CmsNotifyService } from 'app/core/notify.service';

@Component({
    selector: 'cms-fragment-rollback-dialog',
    templateUrl: 'fragment-rollback-dialog.component.html'
})

export class CmsFragmentRollbackDialogComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService
    ) {}

    rollback() {
        this.notifyService.notify({
            type: 'fragmentSnapshotsRollback'
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('cms-fragment-rollback-dialog');
    }
}
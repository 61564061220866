import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class CmsNotifyService {

    private _notify = new Subject<String | Object>();

    change$ = this._notify.asObservable();

    notify(itemType: String | Object) {
        this._notify.next(itemType);
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./json-editor.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./json-editor.component";
var styles_JsonEditorComponent = [i0.styles];
var RenderType_JsonEditorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_JsonEditorComponent, data: {} });
export { RenderType_JsonEditorComponent as RenderType_JsonEditorComponent };
export function View_JsonEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "pointer json-editor-maximize-icon g-icon g-icon-maximize"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_JsonEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "cms-json-editor", [], [[2, "full-screen-editor", null]], null, null, View_JsonEditorComponent_0, RenderType_JsonEditorComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.JsonEditorComponent]), i1.ɵdid(2, 4374528, null, 0, i3.JsonEditorComponent, [i1.Renderer2, i1.ElementRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).fullScreen; _ck(_v, 0, 0, currVal_0); }); }
var JsonEditorComponentNgFactory = i1.ɵccf("cms-json-editor, json-editor[formControlName]", i3.JsonEditorComponent, View_JsonEditorComponent_Host_0, { disabled: "disabled" }, {}, []);
export { JsonEditorComponentNgFactory as JsonEditorComponentNgFactory };

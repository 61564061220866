import {Component, OnInit} from '@angular/core';
import {CmsModalService} from '../../core/modal.service';
import {CmsAudit, CmsAuditsService} from '../shared/audits.service';
import {Pagination} from '../../../types';
import {CmsTranslateService} from '../../shared/translate.service';

interface Data {
    audits: Array<CmsAudit>;
    pagination: Pagination;
}

@Component({
    selector: 'cms-audits',
    templateUrl: 'cms-audits.component.html',
    styleUrls: ['./audit.component.scss'],
    providers: [CmsAuditsService]
})
export class CmsAuditsComponent implements OnInit {

    auditsData: Data;
    columns: Array<Object>;

    paginationConfig = {
        disableNavigation: true
    };

    constructor(
        private modalService: CmsModalService,
        private cmsAuditsService: CmsAuditsService,
        private translateService: CmsTranslateService
    ) {
        this.columns = [
            {
                id: 'user',
                useObjectKey: 'userDisplayIdentificator',
                name: this.translateService.translate('common_user'),
                classes: 'font-weight-bold'
            },
            {id: 'createdAt', name: this.translateService.translate('common_created'), type: 'date'},
            {id: 'action', name: this.translateService.translate('common_action')}
        ];
    }

    ngOnInit() {
        this.getData();
    }

    paginationChanged(e) {
        this.getData(e.currentPage, e.pageSize);
    }

    getData(currentPage?: number, pageSize?: number) {
        this.cmsAuditsService.getAudits({
            entity: this['data']['entity'],
            identifier: this['data']['id'],
            currentPage: currentPage,
            pageSize: pageSize
        }).subscribe(res => {
            res.audits = res.audits.map(audit => {
                if (audit.user.firstName) {
                    audit.user.userDisplayIdentificator = audit.user.firstName + ' ' + audit.user.lastName;
                } else {
                    const id = audit.user;
                    audit.user = {
                        companyPlatformId: '',
                        id: ''
                    };
                    audit.user.userDisplayIdentificator = id;
                }
                return audit;
            });
            this.auditsData = res;
        });
    }

    closePopup() {
        this.modalService.close('show-audits');
    }
}

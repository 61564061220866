import { NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { UserService } from './user.service';
import { LoggedInGuard } from './logged-in.guard';
import {CmsSiteConfigLoader} from './site-config.service';
import { CmsSharedModule } from '../shared/shared.module';
import { CmsNotifyService } from './notify.service';
import { CmsNotificationsService } from './notifications.service';
import { CmsModalService } from './modal.service';
import { CmsLoaderService } from '../shared/loader/loader.service';
import {ModuleAccessGuard} from './module-access.guard';

@NgModule({
    imports: [
        CmsSharedModule
    ],
    providers: [
        UserService,
        LoggedInGuard,
        ModuleAccessGuard,
        CmsNotificationsService,
        CmsModalService,
        CmsSiteConfigLoader,
        CmsLoaderService,
        CmsNotifyService
    ]
})

export class CmsCoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CmsCoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'common_upload' | translate}}</div>
    </header>
    <div class="container">
        <cms-loader [showLoader]="uploader.progress > 0 && uploader.isUploading"></cms-loader>
        <div class="g-popup-body">
            <cms-select
                (selectChanged)="selectContainer($event)"
                [options]="containers"
                [placeholder]="'resources_container' | translate"
                [title]="'resources_container' | translate">
            </cms-select>
            <div class="file-upload-section"
                *ngIf="uploader?.queue?.length === 0">
                <div class="drop-zone"
                    #fileDrop
                    fileDrop
                    [ngClass]="{'file-over-zone': fileOverZone}"
                    (fileOver)="fileOver($event)"
                    (click)="fileSelect.click()"
                    [uploader]="uploader">
                    <div class="placeholder">
                        <p class="text-row-1 g-text-xs-center">
                            {{'resources_resource_upload_drop_zone_title' | translate}}
                        </p>
                        <p class="text-row-2 g-text-xs-center">
                            {{'resources_resource_upload_drop_zone_subtitle' | translate}}
                        </p>
                    </div>
                    <input type="file"
                        class="hidden-field"
                        #fileSelect
                        fileSelect
                        [uploader]="uploader"
                        multiple>
                </div>
            </div>
            <div class="file-overview-section"
                 *ngIf="uploader?.queue?.length > 0"
                 [formGroup]="resources">
                <div formArrayName="items">
                    <div *ngFor="let item of uploader.queue; index as i"
                         class="img-section">
                        <div class="img-box">
                            <div class="img-preview g-text-xs-center"
                                 *ngIf="item.fileDetails.fileType === 'image'"
                                 imgPreview
                                 [image]="item?._file">
                            </div>
                            <div class="file-preview"
                                 *ngIf="item.fileDetails.fileType === 'file'">
                                <div class="icon">
                                    <i class="g-icon g-icon-full-box"></i>
                                </div>
                            </div>
                            <div class="video-preview"
                                 *ngIf="item.fileDetails.fileType === 'video'">
                                <div class="icon">
                                    <i class="g-icon g-icon-play-a"></i>
                                </div>
                            </div>
                        </div>
                        <div class="g-input g-input-io file-title"
                             [ngClass]="{invalid: (
                                resources.hasError('required', 'items.' + i) ||
                                resources.hasError('maxlength', 'items.' + i)) &&
                                !resources.get(['items', i]).pristine}">
                            <input type="text"
                                   class="g-input-input"
                                   [placeholder]="'common_title' | translate"
                                   [formControlName]="i"
                                   [(ngModel)]="item.file.title"/>
                            <label class="g-input-label">{{ 'common_title' | translate }}</label>
                            <label class="g-input-validation">
                                <span *ngIf="resources.get(['items', i]).hasError('required')">
                                    {{ 'common_validation_field_required' | translate }}
                                </span>
                                <span *ngIf="resources.hasError('maxlength', 'items.' + i)">
                                    {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="buttons">
                <button class="button g-button g-button-earth"
                    (click)="uploadFiles()"
                    [disabled]="uploader?.queue?.length === 0 || uploader?.isUploading || !uploader?.options?.url">
                    <span class="g-button-title">{{ 'common_save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/table/table.component.ngfactory";
import * as i3 from "../../shared/table/table.component";
import * as i4 from "../../shared/ismobile.service";
import * as i5 from "../../shared/pagination/pagination.component.ngfactory";
import * as i6 from "../../shared/pagination/pagination.component";
import * as i7 from "@angular/router";
import * as i8 from "../../shared/pipes/translate.pipe";
import * as i9 from "../../shared/translate.service";
import * as i10 from "../../shared/escape-key/escape-key.directive";
import * as i11 from "@angular/common";
import * as i12 from "../shared/audits.service";
import * as i13 from "@angular/common/http";
import * as i14 from "../../core/site-config.service";
import * as i15 from "./audit.component";
import * as i16 from "../../core/modal.service";
var styles_CmsAuditsComponent = [i0.styles];
var RenderType_CmsAuditsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CmsAuditsComponent, data: {} });
export { RenderType_CmsAuditsComponent as RenderType_CmsAuditsComponent };
function View_CmsAuditsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "m-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cms-table", [], null, [["window", "resize"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).responsify($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).responsify($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CmsTableComponent_0, i2.RenderType_CmsTableComponent)), i1.ɵdid(2, 8437760, null, 0, i3.CmsTableComponent, [i1.ElementRef, i4.CmsIsMobileService], { data: [0, "data"], columns: [1, "columns"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "cms-pagination", [], null, [[null, "paginationChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paginationChanged" === en)) {
        var pd_0 = (_co.paginationChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CmsPaginationComponent_0, i5.RenderType_CmsPaginationComponent)), i1.ɵdid(4, 638976, null, 0, i6.CmsPaginationComponent, [i7.Router, i7.ActivatedRoute], { pagination: [0, "pagination"], config: [1, "config"] }, { paginationChanged: "paginationChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.auditsData.audits; var currVal_1 = _co.columns; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.auditsData.pagination; var currVal_3 = _co.paginationConfig; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_CmsAuditsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "box-shadow-container no-table-data-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "no-table-data-icon g-icon g-icon-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "no-table-data-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "common_no_data")); _ck(_v, 3, 0, currVal_0); }); }
export function View_CmsAuditsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.CmsTranslatePipe, [i9.CmsTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "popup g-popup"]], null, [[null, "cmsEscapeKey"], ["window", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleEscapeKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("cmsEscapeKey" === en)) {
        var pd_1 = (_co.closePopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i10.CmsEscapeKeyDirective, [], null, { cmsEscapeKey: "cmsEscapeKey" }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "header", [["class", "g-popup-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "g-popup-header-close g-icon g-icon-close-a"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "g-popup-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "g-popup-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CmsAuditsComponent_1)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CmsAuditsComponent_2)), i1.ɵdid(12, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.auditsData && _co.auditsData.audits) && _co.auditsData.audits.length); _ck(_v, 10, 0, currVal_1); var currVal_2 = ((_co.auditsData && _co.auditsData.audits) && !_co.auditsData.audits.length); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "audits_audits")); _ck(_v, 6, 0, currVal_0); }); }
export function View_CmsAuditsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "cms-audits", [], null, null, null, View_CmsAuditsComponent_0, RenderType_CmsAuditsComponent)), i1.ɵprd(512, null, i12.CmsAuditsService, i12.CmsAuditsService, [i13.HttpClient, i14.SITE_CONFIG]), i1.ɵdid(2, 114688, null, 0, i15.CmsAuditsComponent, [i16.CmsModalService, i12.CmsAuditsService, i9.CmsTranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CmsAuditsComponentNgFactory = i1.ɵccf("cms-audits", i15.CmsAuditsComponent, View_CmsAuditsComponent_Host_0, {}, {}, []);
export { CmsAuditsComponentNgFactory as CmsAuditsComponentNgFactory };

import {Inject, Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../core/site-config.service';
import {Observable} from 'rxjs';
import {CmsObjectHelper} from '../shared/object-helper.service';
import {ISiteConfig} from '../core/site-config.interface';
import {App} from '../apps/shared/apps.service';
import {Pagination} from '../../types';
import {Company} from 'app/shared/common.service';

export const MODULE_ID = 'lists';

export const LIST_ITEM_TYPE = {
    article: 'article',
    resource: 'resource'
};

export interface CmsListsResponse {
    lists: Array<CmsList>;
    pagination?: Pagination;
}

export interface CmsListResponse {
    list: CmsList;
}

export interface CmsList {
    active: boolean;
    app: number | App;
    company: number | Company;
    createdAt: string;
    id: number;
    items: Array<CmsListItem>;
    name: string;
    title: string;
    updatedAt: string;
}

export interface CmsListItem {
    active: boolean;
    icon: string;
    id: number;
    priority: number;
    resource: CmsListItemResource;
    title: string;
}

export interface CmsListItemResource {
    id: number;
    mimeType: string;
    name: string;
    url: string;
}


@Injectable()
export class CmsListsService {
    constructor(
        private  http: HttpClient,
        @Inject(SITE_CONFIG) private config: ISiteConfig
    ) {}

    getList(id: string, params: object = {}): Observable<CmsListResponse> {
        const queryParams = {...params, extend: 'app'};

        return this.http.get<CmsListResponse>(
            `${this.config.gravityCmsApi}/cms/lists/${id}`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    getLists(params?: object): Observable<CmsListsResponse> {
        const queryParams = {...params, extend: 'company,app'};

        return this.http.get<CmsListsResponse>(
            `${this.config.gravityCmsApi}/cms/lists`,
            {params: CmsObjectHelper.objToSearchParams(queryParams)}
        );
    }

    createList(data: object): Observable<any> {
        return this.http.post(
            `${this.config.gravityCmsApi}/cms/lists`,
            data
        );
    }

    updateList(listId: string, data: object): Observable<any> {
        return this.http.put(
            `${this.config.gravityCmsApi}/cms/lists/${listId}`,
            data
        );
    }

    createOrUpdateListTranslation(listId, localeId, data) {
        return this.http.put(
            `${this.config.gravityCmsApi}/cms/lists/${listId}/translations/${localeId}`,
            data
        );
    }
}

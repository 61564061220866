<div *ngIf="notification && notificationShown"
     (cmsClickOutside)="close()"
     [ngClass]="{'g-alert-earth': notification.type === 'action',
                 'g-alert-mars': notification.type === 'error',
                 'g-alert-uranus': notification.type === 'confirmation'}"
     class="g-alert">
    <i (click)="close()"
       class="g-alert-icon g-icon g-icon-close-a">
    </i>
    <div class="g-alert-content">
        {{message}}
    </div>
</div>
<div>
    <div class="g-tabs g-tabs-juno">
        <div *ngFor="let tab of tabs"
             [ngClass]="{active: tab.active}"
             (click)="selectTab(tab)"
             tabindex="0"
             class="g-tab g-tab-text">{{tab.tabTitle}}</div>
    </div>
    <ng-content></ng-content>
</div>

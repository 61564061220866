<div class="popup g-popup"
     (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">
      {{ ('marketing_slots_translate_marketing_item_slot' | translate) + ' ' + (localeTo && localeTo.title)}}
    </div>
  </header>
  <div class="g-popup-body">
    <form class="popup-form"
          novalidate
          [formGroup]="form"
          (ngSubmit)="translateItem()">
      <div class="form-body">
        <div class="g-row">
          <div class="g-col-xs-12 mb-1">
            <div class="form-control">
              <div class="g-input g-input-io">
                <div class="resource-item-preview p-0 mb-1">
                  <div class="image-preview"
                       [style.background-image]="trustedUrl">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="g-col-xs-12 mb-1">
            <div class="form-control">
              <div class="g-input g-input-io"
                   [ngClass]="{invalid: form.get('reference').invalid
                                        && !form.get('reference').pristine}">
                <div class="resource-item-preview p-0 mb-1"
                     (click)="openResourcePicker()">
                  <div class="image-preview"
                       *ngIf="form.get('reference').value"
                       [style.background-image]="'url(' + getImgUrl(form.get('reference').value) + ')'">
                  </div>
                  <i class="g-icon g-icon-gallery resource-item-preview-empty"
                     *ngIf="!form.get('reference').value">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="form-footer-buttons">
          <button type="button"
                  class="form-footer-button g-button g-button-earth"
                  [disabled]="form.invalid"
                  (click)=translateItem()>
            <span class="g-button-title">
              {{ 'common_finish_do' | translate }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
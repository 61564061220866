import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CmsModalService} from '../../core/modal.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsMenusService} from '../shared/menus.service';

@Component({
    selector: 'cms-edit-template-item-in-menu.component',
    providers: [CmsMenusService],
    templateUrl: 'edit-template-item-in-menu.component.html'
})

export class CmsEditTemplateItemInMenuComponent implements OnInit {
    menuTemplateItem: FormGroup;
    data: any;

    constructor(private fb: FormBuilder,
                private modalService: CmsModalService,
                private notifyService: CmsNotifyService) {
    }

    ngOnInit() {
        this.menuTemplateItem = this.fb.group({
            itemActive: this.data['active']
        });
    }

    editItem() {
        const data = this.data;

        this.notifyService.notify({
            type: 'editTemplateItem',
            data: {
                index: this.data.index,
                active: this.menuTemplateItem.value.itemActive
            }
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('edit-template-item-in-menu');
    }
}

<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'articles_new_category' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="newCategory" (ngSubmit)="$submit.next($event)">
            <div class="form-body">
                <div class="form-control">
                    <cms-select formControlName="type"
                                [options]="categoryTypes"
                                [placeholder]="'common_type' | translate"
                                [title]="'common_type' | translate"
                    ></cms-select>
                </div>
                <div *ngIf="newCategory.get('type').value?.id === categoryType.company && companies?.length > 1" class="form-control">
                    <cms-select formControlName="company"
                                [options]="companies"
                                [placeholder]="'common_company' | translate"
                                [title]="'common_company' | translate"
                    ></cms-select>
                </div>
                <div *ngIf="newCategory.get('type').value?.id === categoryType.application" class="form-control">
                    <cms-select formControlName="application"
                                [options]="applications"
                                [placeholder]="'applications_application' | translate"
                                [title]="'applications_application' | translate"
                    ></cms-select>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (newCategory.get('name').hasError('required') || newCategory.get('name').hasError('maxlength') || newCategory.get('name').hasError('pattern')) && !newCategory.get('name').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_name' | translate" formControlName="name">
                        <label class="g-input-label">{{ 'common_name' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="newCategory.get('name').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="newCategory.get('name').hasError('maxlength') && !newCategory.get('name').hasError('pattern')">
                                {{'common_validation_maximum_field_length' | translate: nameValidationInterpolationParams}}
                            </span>
                            <span *ngIf="newCategory.get('name').hasError('pattern')">
                                {{'common_validation_valid_characters' | translate: nameValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: (newCategory.get('title').hasError('required') || newCategory.get('title').hasError('maxlength')) && !newCategory.get('title').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_title' | translate" formControlName="title">
                        <label class="g-input-label">{{ 'common_title' | translate }}</label>
                        <label class="g-input-validation">
                            <span *ngIf="newCategory.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="newCategory.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <cms-select
                            formControlName="locale"
                            [options]="locales"
                            [placeholder]="'common_language' | translate"
                            [title]="'common_language' | translate"
                    ></cms-select>
                </div>
                <div class="form-control">
                    <cms-select
                            formControlName="parent"
                            [options]="categories"
                            [nullable]="true"
                            [placeholder]="'articles_parent_category' | translate"
                            [title]="'articles_parent_category' | translate"
                    ></cms-select>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="active">
                        <label class="g-toggle-label">{{ 'common_active' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth"
                            [disabled]="newCategory.invalid">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

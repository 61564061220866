import { Component } from '@angular/core';
import { CmsModalService } from 'app/core/modal.service';
import { CmsNotifyService } from 'app/core/notify.service';

@Component({
    selector: 'cms-delete-ticket-block-prompt',
    templateUrl: 'cms-delete-ticket-block-prompt.component.html'
})

export class CmsDeleteTicketBlockPromptComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService
    ) { }

    confirmDelete() {
        this.notifyService.notify({
            type: 'deleteTicketBlock',
            index: this.data
        });

        this.closePopup();
    }

    closePopup() {
        this.modalService.close('cms-delete-ticket-block-prompt');
    }
}

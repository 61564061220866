import { AfterViewInit, Component, forwardRef, Inject, Input, NgZone, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResourcesPlugin } from './resources-plugin.service';
import { TinymceOptions } from './tinymce.config.interface';

declare var tinymce: any;

const noop = () => {
};

@Component({
    selector: 'cms-tinymce',
    templateUrl: 'tinymce.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TinymceComponent),
            multi: true
        }
    ]
})
export class TinymceComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input() instanceConfig: TinymceOptions;
    @Input() toggleableDarkBody: boolean = false;
    public elementId: string = 'tiny-' + Math.random().toString(36).substring(2);
    public editor: any;

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    private innerValue: string;
    private darkBody: boolean = false;

    private readonly options: any;
    constructor(
        private resourcePlugin: ResourcesPlugin,
        private zone: NgZone,
        @Inject('TINYMCE_CONFIG') private config: TinymceOptions
    ) {
        this.options = Object.assign(new TinymceOptions(), this.config);
        this.options.generic_plugin_data = this.resourcePlugin;
        this.options.selector = '#' + this.elementId;
        this.options.setup = editor => {
            this.editor = editor;
            editor.on('change keyup', () => {
                this.value = editor.getContent();
            });
            if (typeof this.config.setup === 'function') {
                this.config.setup(editor);
            }
        };
        this.options.init_instance_callback = editor => {

            if (editor && this.value) {
                editor.setContent(this.value);
            }

            if (typeof this.config.init_instance_callback === 'function') {
                this.config.init_instance_callback(editor);
            }
        };
        if (this.config.auto_focus) {
            this.options.auto_focus = this.elementId;
        }
    }


    ngAfterViewInit() {
        Object.assign(this.options, this.instanceConfig);
        if (this.options.baseURL) {
            (<any>tinymce).baseURL = this.options.baseURL;
        }
        (<any>tinymce).init(this.options);
    }

    ngOnDestroy() {
        (<any>tinymce).remove(this.editor);
    }

    toggleDarkBody() {
        this.darkBody = !this.darkBody;
        this.setTinyMceBodyColor()
    }

    private setTinyMceBodyColor() {
        this.editor.getBody().style.backgroundColor = this.darkBody ? '#607d8b' : '#fff';
    }

    // get accessor
    get value(): any {
        return this.innerValue;
    };

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.zone.run(() => {
                this.onChangeCallback(v);
            });

        }
    }
    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            if (this.editor) {
                this.editor.setContent(value);
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}

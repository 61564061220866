import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class CmsNotificationsService {
    public notification: EventEmitter<Object> = new EventEmitter();

    /**
     * Show notification
     *
     * @param {{type: string; content: string}} msg
     */
    public show(msg: {type: string, content: string}) {
        this.notification.emit(msg);
    }

     /**
     * Show error notification
     *
     * @param {any} error
     */
    public showError(error: any) {
        this.notification.emit({
            type: 'error',
            content: (error.error && error.error.message) || error.message || error
        });
    }
}


import {throttleTime} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CmsModalService } from '../../core/modal.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { TicketBlockTypes, TicketFragments, TicketLayoutActions } from '../ticket-layout-creator.service';

@Component({
    selector: 'cms-add-ticket-block',
    templateUrl: './add-ticket-block.component.html',
})
export class CmsAddTicketBlockComponent implements OnInit {
    @Input() data: {
        fragments: TicketFragments
    };
    public types = [{
        id: TicketBlockTypes.draft,
        title: this.translateService.translate('ticket_layouts_block')
    }, {
        id: TicketBlockTypes.fragment,
        title: this.translateService.translate('ticket_layouts_fragment')
    }];
    public ticketBlockTypes = TicketBlockTypes;
    public $submit = new Subject<Event>();
    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private modalService: CmsModalService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService,
    ) {
    }

    ngOnInit() {
        this.setSubmitListener();
        this.form = this.fb.group({
            type: ['', [Validators.required]],
            fragment: [''],
            draft: [{content: ''}]
        })
    }

    public addBlock(formValues) {
        this.notifyService.notify({
            type: TicketLayoutActions.addBlock,
            data: {
                block: {
                    ...formValues[formValues.type.id],
                    type: formValues.type.id
                },
            },
        });

        this.closePopup();
    }

    public onTypeChange(type) {
        this.setFieldValidation(type.id);
    }

    private setFieldValidation(selectedBlockType) {
        if (selectedBlockType === TicketBlockTypes.fragment) {
            this.form.controls['fragment'].setValidators([Validators.required])
        } else {
            this.form.controls['fragment'].clearValidators();
        }
    }

    public closePopup() {
        this.modalService.close(TicketLayoutActions.addBlock);
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.addBlock(this.form.value);
            });
    }
}

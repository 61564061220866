<div class="upper-section">
</div>
<div class="g-text-xs-center">
    <span class="circle-404">
        404
    </span>
</div>
<p class="g-text-xs-center mb-3 mt-2 text-404">
    <small>{{'404_page_not_found' | translate}}</small>
</p>
<div class="g-container">
    <div class="g-row">
        <div class="g-col-xs-6 g-offset-xs-3 g-col-md-4 g-offset-md-4">
            <button class="g-button"
                    routerLink="/dashboard">
                {{'404_go_to_dashboard' | translate}}
            </button>
        </div>
    </div>
</div>

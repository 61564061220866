import {Component, Input, OnInit} from '@angular/core';
import {CmsModalService} from '../../core/modal.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {cloneDeep} from 'lodash';
import {Resource} from '../../resources/shared/resources.service';
import {CmsResourcePickerModalComponent} from '../../shared/resource-picker/reference-picker-modal/resource-picker-modal.component';
import {requireSelfOrValidator} from '../../shared/validators/requireSelfOr.validator';

@Component({
  selector: 'cms-add-marketing-slot-item',
  templateUrl: './add-marketing-slot-item.component.html',
})
export class CmsAddMarketingSlotItemComponent implements OnInit {
  @Input() data;
  contentEditorConfig = {
    toolbar: 'bold | alignleft aligncenter alignright alignjustify | customhr | forecolor | width | styleselect | code',
    plugins: [
      'colorpicker',
      'textcolor',
      'hr',
      'width',
      'customhr',
      'code'
    ],
    textcolor_cols: '1',
    textcolor_rows: '0',
  };
  form: FormGroup;
  urlValidationInterpolationParams: { field: string; maximum: string };
  constructor(
      private fb: FormBuilder,
      private modalService: CmsModalService,
      private notifyService: CmsNotifyService,
      private translateService: CmsTranslateService,
  ) { }

  ngOnInit() {
    const defaultProviderName = 'seven';
    const defaultProvider = this.data.find(provider => provider.name === defaultProviderName);
    const defaultProviderFormValue = defaultProvider || '';

    this.form = this.fb.group({
      provider: [defaultProviderFormValue, [Validators.required]],
      reference: [null],
      url: [null, [Validators.maxLength(255)]],
      textContent: [''],
      active: [true, []],
      customData: [null, [validateJson]],
      startDate: [null],
      endDate: [null],
    });

    this.setFormFieldValidatorsForSevenProvider();

    this.urlValidationInterpolationParams = {
      field: this.translateService.translate('common_url').toLowerCase(),
      maximum: '255',
    };

    this.notifyService.change$.subscribe((item: {type: string, data: Resource}) => {
      if (item.type === 'resource-picker-modal-pick') {
        this.form.get('reference').patchValue(item.data);
      }
    });
  }

  ngOnDestroy() {
    this.modalService.close('resource-picker-modal');
  }

  addItem() {
    this.notifyService.notify({
      type: 'add-marketing-slot-item',
      data: {
        item: this.form.value
      }
    });

    this.closePopup();
  }

  onProviderSelect(provider) {
    if (!provider) {
      return;
    }
    this.setRequiredFieldsForProvider(provider);
    this.resetFormValuesForProvider(provider);
    this.form.updateValueAndValidity();
  }

  setRequiredFieldsForProvider(provider) {
    if (provider.name === 'google_dfp') {
      this.form.controls['reference'].setValidators([Validators.required]);
      this.form.controls['textContent'].clearValidators();
    } else if (provider.name === 'seven') {
      this.setFormFieldValidatorsForSevenProvider();
    }
  }

  resetFormValuesForProvider(provider) {
    this.form.get('reference').reset();

    if (provider.name === 'google_dfp') {
      // Value of tiny-mce can't be undefined or null or it will throw error
      this.form.get('textContent').reset('');
    }
  }


  setFormFieldValidatorsForSevenProvider() {
    this.form.controls['reference'].setValidators(requireSelfOrValidator(this.form.controls['textContent']));
    this.form.controls['textContent'].setValidators(requireSelfOrValidator(this.form.controls['reference']));
  }

  openResourcePicker() {
    this.modalService.open({
      id: 'resource-picker-modal',
      component: CmsResourcePickerModalComponent,
      data: {
        options: {
          mimeType: ['image/png', 'image/jpeg']
        }
      }
    });
  }

  removeImage() {
    this.form.controls['reference'].reset();
    this.form.controls['reference'].updateValueAndValidity();
  }

  public getImgUrl(resource: Resource) {
    if (!resource) {
      return '';
    }
    return resource['thumb-large-url'] || resource.url;
  }

  closePopup() {
    this.modalService.close('add-marketing-slot-item');
  }

  onDateRangeSelect(dates: { startDateTime: string, endDateTime: string }) {
    this.form.patchValue({
      startDate: dates.startDateTime,
      endDate: dates.endDateTime
    })
  }
}

import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({selector: '[cmsEscapeKey]'})
export class CmsEscapeKeyDirective {
    @Output() cmsEscapeKey = new EventEmitter<Event>();

    @HostListener('window:keydown.escape', ['$event'])
    handleEscapeKey(event: Event) {
        this.cmsEscapeKey.emit(event);
    }
}

import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
  return value == null || (typeof value === 'string' && value === '');
}

function isInvalid(control, secondControl) {
  return isEmptyInputValue(control.value) && isEmptyInputValue(secondControl.value)
}

export function requireSelfOrValidator(secondControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = isInvalid(control, secondControl);
    const validatorValue = invalid ? {requireSelfOr: true} : null;

    secondControl.setErrors(validatorValue);
    return validatorValue;
  }
}
